import React from 'react'
import {
  Grid,
  TextField
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const FixedFields = ({type, inquiry, register, errors, setValue, control, priceIndices, classes, fieldsEnabled}) => {
  const { t } = useTranslation()
    return (
      <Grid item xs={6}>
        <TextField
          fullWidth
          id='price'
          margin='dense'
          defaultValue={inquiry && inquiry.price}
          name='price'
          label={t('price')}
          type='number'
          step='0.05'
          variant='outlined'
          inputRef={register({required: true})}
          error={errors.price}
          helperText={
            errors.price && t(errors.price.type)
          }
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    )
}

export default FixedFields
