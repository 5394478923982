
import React, { useEffect, useContext, useReducer } from 'react'
import { Grid, Card, CardContent, CardActions, Button, Typography, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, useFormContext, useWatch } from 'react-hook-form'
import { useMutation, useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import gql from 'graphql-tag'

import { FETCH_PRICE_INDICES } from 'constants/queries'
import { QueryResult, ErrorObject } from 'components'
import { StoreContext } from 'App'
import { FormHandler } from 'lib/Business'
import { ProductPriceModel } from 'models'
import { Field,
 Select,
 ErrorBoundary,
 LoadingBar
} from 'components'

const PriceIndexValueFields = ({state, priceIndex, dispatch}) => {
    const { t } = useTranslation()
    return (
      <>
        <Grid item md={6}>
            <Typography>{priceIndex.name}</Typography>
        </Grid>
        <Grid item md={6}>
            
            <TextField
                fullWidth
                type='number'
                label={`Value ${t(priceIndex.unitType)}`}
                value={state.priceIndices[priceIndex.id]?.value}
                onChange={e => 
                    dispatch({type: 'change_field_value', payload: {
                        id: priceIndex.id,
                        value: e.target.value,
                        field: 'value'
                    }})
                }
            />
            
        </Grid>
      </>
    )

}

const BULK_UPDATE_PRICE_INDEX_VALUES = gql`
  mutation BULK_UPDATE_PRICE_INDEX_VALUES($input: [PriceIndexValueInput!]!) {
    bulkUpdatePriceIndexValues(input: $input) {
        priceIndices {
            id
            name
            unitType
            currentValue
            currentPriceIndexValueInstance {
                id
                value
                date
            }
        }
  }
}
`

const FETCH_PRICE_INDICES_WITH_LATEST_VALUE = gql`
query fetchPriceIndicesWithValue {
    priceIndices {
        id 
        name
        unitType
        currentValue
        currentPriceIndexValueInstance {
            id
            value
            date
        }
    }
}

`

const reducer = (state, action) => {
    switch(action.type) {
        case 'change_field_value': {
            let priceIndex = state.priceIndices[action.payload.id]
            priceIndex[action.payload.field] = action.payload.value
            return {
                priceIndices: {...state.priceIndices, [action.payload.id] : priceIndex}
            }
        }
        case 'set_price_indices': {
            const newState = {
                priceIndices: action.payload.reduce((acc, obj) => { acc[obj.id] = {...obj, value: obj.currentValue}; return acc;}, {})
            }
            return  newState
        }
        default:
            throw {type: 'PriceIndexValueForm Reducer', message: 'Invalid action dispatched'}
    }
}

const createInput = (original, modified) => {
    let results = []
    original.map(priceIndex => {
        if(priceIndex.currentValue != modified[priceIndex.id].value) {
            results.push({
                priceIndexId: priceIndex.id,
                value: Number(modified[priceIndex.id].value),
                id: priceIndex.currentPriceIndexValueInstance?.id
            })
        }
    })
    return results

}

export const PriceIndexValueForm = ({priceType, onCompleted, onClose}) => {
    const [state, dispatch] = useReducer(reducer, {priceIndices: {}})
    const context = useContext(StoreContext);
    const data = useQuery(FETCH_PRICE_INDICES_WITH_LATEST_VALUE, {
        onCompleted: data => {
            dispatch({
                type: 'set_price_indices',
                payload: data.priceIndices
            })
        }
    })
    const [mutate, mutationStatus] = useMutation(BULK_UPDATE_PRICE_INDEX_VALUES, {
        onCompleted: () => {
            context.state.showAlert({
                severity: 'success',
                message: 'Index value updated'
            })
            onCompleted();
            onClose();
        }

    });

    const createOnSubmit = data => e => {
        e.preventDefault();
        const input = createInput(data.priceIndices, state.priceIndices)
        mutate({variables: {input}})
    }

    return (
        <ErrorBoundary>
            <QueryResult {...data}>
                {data => (
                    <form autoComplete='off' noValidate onSubmit={createOnSubmit(data)}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3}>
                                    {data.priceIndices.map(d => (
                                        <PriceIndexValueFields priceIndex={d} state={state} dispatch={dispatch}/>
                                    ))
                                    }
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button type='submit' variant='contained' color='primary'>
                                    Save
                                </Button>
                            </CardActions>
                            {mutationStatus.loading && <LoadingBar/>}
                        </Card>
                    </form>
                )}
            </QueryResult>
        </ErrorBoundary>
    )
}

