import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useForm, FormProvider } from 'react-hook-form'
import { Button, makeStyles } from '@material-ui/core'
import moment from 'moment'

import { FETCH_PRODUCT_DASHBOARD_DATA } from 'constants/queries'
import { QueryResult } from 'components'
import { Filters as FilterInputs,
  Dashboard,
  ProductPriceInputs,
  PDFView } from './components'
import {FilterData, ProcessData} from './business'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}))


const ProductDashboard = () => {
  const classes = useStyles()
  const data = useQuery(FETCH_PRODUCT_DASHBOARD_DATA)
  const methods = useForm();
  const filterState = methods.watch()
  const [showPDF, setShowPDF] = useState(false)


  return (
    <QueryResult {...data}>
      {(data) => (
        <>
          <div className={classes.root}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowPDF(!showPDF)}
            >Create PDF</Button>
          </div>
          {showPDF 
            ? (
              <PDFView {...FilterData(filterState, ProcessData(data))}/>
            )
            : ( 
              <>
                <ProductPriceInputs data={data}/>
                <FormProvider {...methods}>
                  <FilterInputs {...data}/>
                  <Dashboard {...FilterData(filterState, ProcessData(data))}/>
                </FormProvider>
              </>
            )
          }
        </>
      )}
    </QueryResult>
  )
}

export default ProductDashboard
