import { UNIT_TYPES } from './Costs/UnitConverter';
import { ValueClass } from 'business/ValueClass'
import { UnitConverter } from 'business'

const DEFAULT_UNIT = UNIT_TYPES.GALLONS;

class ProductPricePathPair {
    constructor({productPrice, paths, seasonality}) {
        this.productPrice = productPrice;
        this.product = productPrice.product
        this.path = paths[0]
        this.suboptimalPaths = paths.slice(1)
        this.seasonality =  seasonality
        // this.finalCost = new ValueClass({value: this.path.totalCost.value + UnitConverter.convert({
        //     value: productPrice.value,
        //     from: productPrice.unitType,
        //     to: DEFAULT_UNIT}).value, unit: DEFAULT_UNIT})

        // this.finalCost = new ValueClass({
        //     value: UnitConverter.convertValueClass({value: this.path.totalCost, to: DEFAULT_UNIT}).value 
        //                 + UnitConverter.convertValueClass({value: productPrice.value, to: DEFAULT_UNIT}).value,
        //     unitType: DEFAULT_UNIT
        // })

        this.finalCost = UnitConverter.convertValueClass({value: productPrice.value, to: DEFAULT_UNIT}).plus(this.path.totalCost)
        this.unitType = DEFAULT_UNIT;
        this.isOutdated = this.productPrice.isOutdated || this.path.isOutdated
    }
}

export default ProductPricePathPair
