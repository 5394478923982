import gql from 'graphql-tag';
import * as Fragments from './fragments.js';

export const FETCH_MAP_DATA = gql`
    query fetchMapData {
        routes {
          ...routeFields
          freightRate
          isFreightRateUpdated
          unitType
          productId
          latestFreightRate {
            id
            rate
            startDate
            endDate
          }
          freightRates {
            ...freightRateFields
          }
          borderCrossPoint {
            ...borderCrossPointFields
          }
          carrier {
            ...carrierFields
            isRouteRateUpdated
            fsc
          }
          origin: originRegion {
            ...regionFields
          }
          destination: destinationRegion {
            ...regionFields
          }
        }
        products {
            id
            name
        }

        clients {
            id
            name
            clientLocations {
                id
                region {
                    id
                    name
                    location
                }
                product {
                    id
                    name
                }
                client {
                    id
                    name
                }
            }
        }
        regions {
            id
            name
            location
        }
        terminals {
            id
            name
            region {
                id
                name
            }
        }
        latestProductPrices {
            ...productPriceFields
            product {
                id
                name
            }
            region {
                id
                name
            }
        }
    }
      ${Fragments.ProductPriceFragments.detailedFields}
      ${Fragments.BorderCrossPointFragments.fields}
      ${Fragments.RouteFragments.fields}
      ${Fragments.FreightRateFragments.fields}
      ${Fragments.CarrierFragments.fields}
      ${Fragments.RegionFragments.fields}
`

export const FETCH_PRODUCT_PRICE_DETAIL = gql`
    query fetchProductPriceDetail($query: ProductPriceQuery!){
        productPrices(query: $query) {
            id
            ...productPriceFields
            historic {
                ...productPriceFields
            }
            provider {
                id
                name
            }
            competitors {
                ...productPriceFields
                historic {
                    ...productPriceFields
                }
                provider {
                    id
                    name
                }
            }
        }
    }
      ${Fragments.ProductPriceFragments.fields}
`

export const CREATE_PRODUCT_PRICE = gql`
  mutation createProductPrice($input: ProductPriceInput!) {
    productPrice {
      create(input: $input) {
          ...productPriceFields
            provider {
                id
                name
            }
            region {
                id
                name
            }
            product {
                id
                name
            }
            historic {
                ...productPriceFields
            }
      }
    }
  }
  ${Fragments.ProductPriceFragments.fields}
`


export const UPDATE_PRODUCT_PRICE = gql`
  mutation updateProductPrice($id: ID!, $input: ProductPriceInput!) {
    productPrice(id: $id) {
      update(input: $input) {
          ...productPriceFields
            provider {
                id
                name
            }
            region {
                id
                name
            }
            product {
                id
                name
            }
            historic {
                ...productPriceFields
            }
      }
    }
  }
  ${Fragments.ProductPriceFragments.fields}
`

export const FETCH_PRODUCT_DASHBOARD_DATA = gql`
    query fetchProductDashboardData {
        regions {
            id
            name
        }
        products {
            id
            name
            priceIndices {
                ...priceIndexFields
            }
        }
        providers {
            id
            name
        }
        productPrices: latestProductPrices {
            ...productPriceFields
            provider {
                id
                name
            }
            region {
                id
                name
            }
            product {
                id
                name
            }
            historic {
                ...productPriceFields
            }
        }
    }
    ${Fragments.ProductPriceFragments.fields}
    ${Fragments.PriceIndexFragments.fields}
`;

export const FETCH_ROUTE_MANAGER_DATA_v2 = gql`
query FetchRouteManagerData {
    routeManagerData {
        regions {
            ...regionFields
        }
      products {
      ...productFields
      }
      carriers {
        ...carrierFields
        routeRates {
          ...routeRateFields
        }
        routes {
          ...routeFields
          freightRates {
            ...freightRateFields
            document {
              link
            }
          }
          borderCrossPoint {
            ...borderCrossPointFields
          }
          carrier {
            ...carrierFields
          }
          origin: originRegion {
            ...regionFields
          }
          destination: destinationRegion {
            ...regionFields
          }
        }
      }
    }
}
  ${Fragments.BorderCrossPointFragments.fields}
  ${Fragments.ProductFragments.fields}
  ${Fragments.RouteFragments.fields}
  ${Fragments.RouteRateFragments.fields}
  ${Fragments.FreightRateFragments.fields}
  ${Fragments.CarrierFragments.fields}
  ${Fragments.RegionFragments.fields}

`


export const FETCH_ROUTE_RATES = gql`
query FetchRouteRates($query: routeRateRateQuery) {
  routeRates(query: $query) {
      ...routeRateFields
  }
}
  ${Fragments.RouteRateFragments.fields}
`
export const CREATE_ROUTE_RATE = gql`
  mutation createRouteRate($input: RouteRateInput!) {
    routeRate {
      create(input: $input) {
      ...routeRateFields
      }
    }
  }
  ${Fragments.RouteRateFragments.fields}
`

export const UPDATE_ROUTE_RATE = gql`
  mutation createRouteRate($input: RouteRateInput!, $id: ID!) {
    routeRate(id: $id) {
      update(input: $input) {
        ...routeRateFields
      }
    }
  }
  ${Fragments.RouteRateFragments.fields}
`


export const FETCH_FREIGHT_RATES = gql`
query FetchFreightRates($query: freightRateRateQuery) {
  freightRates(query: $query) {
      ...freightRateFields
  }
}
  ${Fragments.FreightRateFragments.fields}
`
export const CREATE_FREIGHT_RATE = gql`
  mutation CreateFreightRate($input: FreightRateInput!) {
      createFreightRate(input: $input) {
        ...freightRateFields
      }
  }
  ${Fragments.FreightRateFragments.fields}
`

export const UPDATE_FREIGHT_RATE = gql`
  mutation createFreightRate($input: FreightRateInput!, $id: ID!) {
    freightRate(id: $id) {
      update(input: $input) {
        ...freightRateFields
      }
    }
  }
  ${Fragments.FreightRateFragments.fields}
`

export const FETCH_ROUTES = gql`
  query FetchRoutes($query: RouteQuery) {
    routes(query: $query) {
      ...routeFields
    }
  }
  ${Fragments.RouteFragments.fields}
`

export const CREATE_ROUTE = gql`
  mutation createRoute($input: RouteInput!) {
    route {
      create(input: $input) {
        ...routeFields
      }
    }
  }
  ${Fragments.RouteFragments.fields}
`

export const UPDATE_ROUTE = gql`
  mutation createRoute($input: RouteInput!, $id: ID!) {
    route(id: $id) {
      update(input: $input) {
        ...routeFields
      }
    }
  }
  ${Fragments.RouteFragments.fields}
`



export const FETCH_REGIONS = gql`
query FetchRegions($query: RegionQuery) {
  regions(query: $query) {
    name
  }
}
`
export const CREATE_REGION = gql`
  mutation createRegion($input: RegionInput!) {
    region {
      create(input: $input) {
        id
        name
      }
    }
  }
`

export const UPDATE_REGION = gql`
  mutation createRegion($input: RegionInput!, $id: ID!) {
    region(id: $id) {
      update(input: $input) {
        id
        name
      }
    }
  }
`




export const FETCH_CARRIERS = gql`
query FetchCarriers($query: CarrierQuery) {
  carriers(query: $query) {
    name
    type
  }
}
`
export const CREATE_CARRIER = gql`
  mutation createCarrier($input: CarrierInput!) {
    carrier {
      create(input: $input) {
        id
        carrierType
        isRouteRateUpdated
        name
      }
    }
  }
`

export const UPDATE_CARRIER = gql`
  mutation createCarrier($input: CarrierInput!, $id: ID!) {
    carrier(id: $id) {
      update(input: $input) {
        id
        carrierType
        isRouteRateUpdated
        name
      }
    }
  }
`

export const DELETE_CARRIER = gql`
  mutation deleteCarrier($id: ID!) {
    carrier(id: $id) {
      destroy
    }
  }
`


export const DELETE_ROUTE = gql`
  mutation deleteRoute($id: ID!) {
    route(id: $id) {
      destroy
    }
  }
`


export const DELETE_ROUTE_RATE = gql`
  mutation deleteRouteRate($id: ID!) {
    routeRate(id: $id) {
      destroy
    }
  }
`



export const DELETE_FREIGHT_RATE = gql`
  mutation deleteFreightRate($id: ID!) {
    freightRate(id: $id) {
      destroy
    }
  }
`
export const FETCH_USERS = gql`
  query fetchUsers($query: UserQuery) {
    users(query: $query) {
      ...userFields
    }
  }
  ${Fragments.UserFragments.fields}
`;

export const FETCH_USER_FULL = gql`
  query fetchUsers($query: UserQuery!) {
    users(query: $query) {
      ...userFields
      providerDetail {
        ...providerDetailFields
      }
    }
  }
  ${Fragments.UserFragments.fields}
  ${Fragments.ProviderDetailFragments.fields}
`;

export const CREATE_USER = gql`
  mutation createUser($input: UserInput!) {
    user {
      create(input: $input) {
        ...userFields
      }
    }
  }
  ${Fragments.UserFragments.fields}
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $input: UserInput!) {
    user(id: $id) {
      update(input: $input) {
        ...userFields
      }
    }
  }
  ${Fragments.UserFragments.fields}
`;

export const FETCH_CURRENT_USER = gql`
  query fetchCurrentUser {
    currentUser {
      ...userFields
    }
  }
  ${Fragments.UserFragments.fields}
`;

export const FETCH_REQUIREMENTS = gql`
  query fetchRequirements($query: RequirementQuery) {
    requirements(query: $query) {
      ...requirementFields
      lots {
        ...lotFields
      }
    }
  }
  ${Fragments.RequirementFragments.fields}
  ${Fragments.LotFragments.fields}
`;

export const FETCH_REQUIREMENT = gql`
  query fetchRequirement($query: RequirementQuery!) {
    requirements(query: $query) {
      ...requirementFields
      lots {
        ...lotFields
      }
    }
  }
  ${Fragments.RequirementFragments.fields}
  ${Fragments.LotFragments.fields}
`;

export const FETCH_LOT = gql`
  query fetchLots($query: LotQuery!) {
    lots(query: $query) {
      ...lotFields
    }
  }
  ${Fragments.LotFragments.fields}
`;

export const FETCH_LOTS = gql`
  query fetchLots($query: LotQuery) {
    lots(query: $query) {
      ...lotFields
    }
  }
  ${Fragments.LotFragments.fields}
`;


export const FETCH_PRODUCT_CATEGORIES = gql`
  query fetchProductCategories($query: ProductCategoryQuery) {
    productCategories(query: $query) {
      ...productCategoryFields
    }
  }
  ${Fragments.ProductCategoryFragments.fields}
`;

export const FETCH_PRODUCTS = gql`
  query fetchProducts($query: ProductQuery) {
    products(query: $query) {
      ...productFields
    }
  }
  ${Fragments.ProductFragments.fields}
`

export const FETCH_PRODUCTS_WITH_REQUIREMENTS = gql`
  query fetchProductsWithRequirements {
    products {
      id,
      name
      requirements {
        id
        status
      }
    }
  }
`

export const CREATE_REQUIREMENT = gql`
  mutation createMutation($input: RequirementInput!) {
    requirement {
      create(input: $input) {
        ...requirementFields
      }
    }
  }
  ${Fragments.RequirementFragments.fields}
`;

export const UPDATE_REQUIREMENT = gql`
  mutation updateRequirement($input: RequirementInput!, $id: ID!) {
    requirement(id: $id) {
      update(input: $input) {
        ...requirementFields
      }
    }
  }
  ${Fragments.RequirementFragments.fields}
`;

export const CREATE_LOT = gql`
  mutation createLots($input: LotInput!) {
    lot {
      create(input: $input) {
        ...lotFields
      }
    }
  }
  ${Fragments.LotFragments.fields}
`;

export const UPDATE_LOT = gql`
  mutation updateLots($input: LotInput!, $id: ID!) {
    lot(id: $id) {
      update(input: $input) {
        ...lotFields
      }
    }
  }
  ${Fragments.LotFragments.fields}
`;

export const LOG_IN = gql`
  mutation LogIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      authToken
      email
      firstName
      lastName
      id
      credentials
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID) {
    user(id: $id) {
      destroy
    }
  }
`;

export const DELETE_REQUIREMENT = gql`
  mutation DeleteRequirement($id: ID) {
    requirement(id: $id) {
      destroy
    }
  }
`;

export const DELETE_LOT = gql`
  mutation DeleteLot($id: ID!) {
    lot(id: $id) {
      destroy
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($id: ID!) {
    deleteFile(id: $id) {
      result
    }
  }
`;
export const UPDATE_PROVIDER_DETAIL = gql`
  mutation updateProviderDetail($id: ID!, $input: ProviderDetailInput!) {
    providerDetail(id: $id) {
      update(input: $input) {
        ...providerDetailFields
      }
    }
  }
  ${Fragments.ProviderDetailFragments.fields}
`;

export const FETCH_CONTRACTS = gql`
  query FetchContracts($query: ContractQuery) {
    contracts(query: $query) {
      ...contractFields
    }
  }
  ${Fragments.ContractFragments.fields}
`

export const FETCH_CONTRACTS_FULL = gql`
  query FetchContracts($query: ContractQuery) {
    contracts(query: $query) {
      ...contractFields
      product {
      ...productFields
        productUnits {
        ...productUnitFields
        }
      }
    }
  }
  ${Fragments.ContractFragments.fields}
  ${Fragments.ProductFragments.fields}
  ${Fragments.ProductUnitFragments.fields}
`


export const UPDATE_CONTRACT = gql`
  mutation UpdateContract($id: ID!, $input: ContractInput!) {
    contract(id: $id) {
      update(input: $input) {
        ...contractFields
      }
    }
  }
  ${Fragments.ContractFragments.fields}
`

export const CREATE_CONTRACT = gql`
  mutation CreateContract($input: ContractInput!) {
    contract {
      create(input: $input) {
        ...contractFields
      }
    }
  }
  ${Fragments.ContractFragments.fields}
`

export const FETCH_INQUIRIES = gql`
  query FetchInquiries($query: InquiryQuery) {
    inquiries(query: $query) {
      ...inquiryFields
      priceIndex {
        ...priceIndexFields
      }
      productUnit {
        ...productUnitFields
      }
      contracts {
        ...contractFields
      }
    }
  }
  ${Fragments.InquiryFragments.fields}
  ${Fragments.ContractFragments.fields}
  ${Fragments.PriceIndexFragments.fields}
  ${Fragments.ProductUnitFragments.fields}
`

export const UPDATE_INQUIRY = gql`
  mutation UpdateInquiry($id: ID!, $input: InquiryInput!) {
    inquiry(id: $id) {
      update(input: $input) {
        ...inquiryFields
      }
    }
  }
  ${Fragments.InquiryFragments.fields}
`

export const CREATE_INQUIRY = gql`
  mutation CreateInquiry($input: InquiryInput!) {
    inquiry {
      create(input: $input) {
        ...inquiryFields
      }
    }
  }
  ${Fragments.InquiryFragments.fields}
`

export const FETCH_PRICE_INDICES = gql`
  query FetchPriceIndices($query: PriceIndexQuery) {
    priceIndices(query: $query) {
      ...priceIndexFields
    }
  }
  ${Fragments.PriceIndexFragments.fields}
`

export const DELETE_INQUIRY = gql`
  mutation DeleteContract($id: ID) {
    inquiry(id: $id) {
      destroy
    }
  }
`

export const FETCH_CONTRACT_CREATION_DATA = gql`
  query FetchContractCreationData {
    productCategories {
      ...productCategoryFields
    }
    destinations {
      id
      city
    }
  }
  ${Fragments.ProductCategoryFragments.fields}
`

export const FETCH_INQUIRY_CREATION_DATA = gql`
  query FetchInquiryCreationData {
    productCategories {
      ...productCategoryFields
    }
    destinations {
      city
      id
    }
  }
  ${Fragments.ProductCategoryFragments.fields}
`

export const FETCH_MOVEMENTS = gql`
  query FetchMovements($query: MovementQuery) {
    movements(query: $query) {
      ...movementFields
      contract {
        canonicName
      }
    }
  }
  ${Fragments.MovementFragments.fields}
`



export const CREATE_MOVEMENT = gql`
  mutation CreateMovement($input: MovementInput!) {
    movement {
      create(input: $input) {
        ...movementFields
      }
    }
  }
  ${Fragments.MovementFragments.fields}
`


export const UPDATE_MOVEMENT = gql`
  mutation UpdateMovement($id: ID!, $input: MutationInput!) {
    movement(id: $id) {
      update(input: $input) {
        ...movementFields
      }
    }
  }
  ${Fragments.MovementFragments.fields}
`


export const FETCH_ROUTE_MANAGER_DATA = gql`
  query FetchRouteManagerData {
    carriers {
      ...carrierFields
    }

    routes {
      ...routeFields
    }

    freightRates {
      ...freightRateFields
    }

    routeRates {
      ...routeRateFields
    }

    regions {
      ...regionFields
    }
    
    products {
      ...productFields
    }
  }

  ${Fragments.CarrierFragments.fields}
  ${Fragments.RouteFragments.fields}
  ${Fragments.FreightRateFragments.fields}
  ${Fragments.RouteRateFragments.fields}
  ${Fragments.RegionFragments.fields}
  ${Fragments.ProductFragments.fields}
`;


// export const FETCH_CONTRACTS_FOR_CALENDAR = gql`
//   query FetchContractsForCalendar {
//     contracts {
//       id
//       clientId: userId
//       title: quotationName
//       start: startDate

//     }
//   }
// `
