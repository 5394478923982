import React, { useState } from 'react'
import clsx from 'clsx'
import { Typography, makeStyles, colors, Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    row: {
        border: `1px solid ${colors.grey[400]}`,
        margin: '0.5em',
        width: '100% !important',
        borderRadius: '5px',
    },
    hidden: {
        display: 'none',
    },
    child: {
        width: '66%',
        marginLeft: '34%',
    }
}))

const Rows = ({headCells, data, isChild, onClick = () => {}}) => {
    const classes = useStyles();

    return(
        <div className={clsx(isChild && classes.child)}>
            {data.map(rd => <Row onClick={onClick} headCells={headCells} data={rd}/>)}
        </div>
    )
};

export const Row = ({headCells, data, onClick}) => {
    const classes = useStyles()
    const [childrenVisible, setChildrenVisible] = useState(false)
    const keys = headCells.map(c => c.id)
    const Elements = keys.map((k, idx) => (
        <Grid item sm={headCells[idx].cols || Math.floor(12/headCells.length)}>
            { headCells[idx].display(data) }
        </Grid>
    ))

    return (
        <>
            <div onClick={() => {setChildrenVisible(!childrenVisible); onClick(data.id)}}>
                <Grid container spacing={3} className={classes.row}>
                    {Elements}
                </Grid>
            </div>
            { (data.children?.data?.length > 0) && (
                <div className={clsx(!childrenVisible && classes.hidden)}>
                    <Rows {...data.children} onClick={onClick} isChild/>
                </div>
            ) }
        </>
    )
}

export default Rows
