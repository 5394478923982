import React from 'react'
import {
  Grid,
  TextField
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const ContractSpotFields = ({type, contract, register, errors, fieldsDisabled}) => {
    const { t } = useTranslation()
return (
  <React.Fragment>
    <Grid item md={6} xs={12}>
      <TextField
        fullWidth
        disabled={fieldsDisabled}
        id='spot-month'
        margin='dense'
        name='spotMonth'
        label={t('spot_month')}
        type='number'
        variant='outlined'
        defaultValue={contract ? contract.spotMonth : 0}
        inputRef={register({required: true})}
        error={errors.month}
        helperText={
          errors.spotMonth && t(errors.spotMonth.type)
        }
        InputLabelProps={{
          shrink: true
        }}
      />
    </Grid>
    <Grid item md={6} xs={12}>
      <TextField
        fullWidth
        disabled={fieldsDisabled}
        id='spot-units'
        margin='dense'
        name='spotUnits'
        label={t('spot_units')}
        type='number'
        variant='outlined'
        defaultValue={contract ? contract.spotUnits : 0}
        inputRef={register({required: true})}
        error={errors.spotUnits}
        helperText={
          errors.spotUnits && t(errors.spotMonth.type)
        }
        InputLabelProps={{
          shrink: true
        }}
      />
    </Grid>
  </React.Fragment>

)
}

export default ContractSpotFields;
