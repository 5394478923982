import { ValueClass } from 'business/ValueClass'

export const UNIT_TYPES = {
    KILOGRAMS_PER_M3: 'kg_m3',
    KILOGRAMS: 'kg_usd',
    POUNDS: 'lb_usd',
    M3: 'm3_usd',
    RAILCAR: 'railcar_usd',
    TANKTRUCK: 'tanktruck_usd',
    GALLONS: 'gal_usd',
    BARRELS: 'bbl_usd',
    LITERS: 'ltr_usd',
}

const UNIT_MATRIX = {
    [UNIT_TYPES.RAILCAR]: {
        [UNIT_TYPES.RAILCAR]: 1,
        [UNIT_TYPES.BARRELS]: 650,
        [UNIT_TYPES.GALLONS]: 650*42,
        [UNIT_TYPES.LITERS]: 650*42*3.785,
    },
    [UNIT_TYPES.GALLONS]: {
        [UNIT_TYPES.LITERS]: 3.785,
        [UNIT_TYPES.RAILCAR]: 1/42/650,
        [UNIT_TYPES.BARRELS]: 1/42,
        [UNIT_TYPES.GALLONS]: 1,
    },
    [UNIT_TYPES.BARRELS]: {
        [UNIT_TYPES.RAILCAR]: 1/650,
        [UNIT_TYPES.BARRELS]: 1,
        [UNIT_TYPES.GALLONS]: 42,
    }
}

export const UnitConverter = {
    convert({value, from, to}) {
        if(!value | !from | !to) throw {location: 'UnitConverter', message: 'Arguments are invalid', arguments: {value, from, to}}
        if(from == to) return value;
        return new ValueClass({value: value / UNIT_MATRIX[from][to], unitType: to})
    },
    convertValueClass({value, to}) {
        if(!value || !to) throw {location: 'UnitConverter', message: 'Arguments are invalid', arguments: {value, to}}
        if(value.unit == to) return value
        return new ValueClass({value: value.value / UNIT_MATRIX[value.unitType][to], unitType: to})

    }
}
