import { ProductPriceModel } from 'models'
export const FilterData = (filterState, data) => {
    const filter = (attribute, value) => {
        const name = attribute[0]; 
        const accessor = attribute[1];

        if(filterState[name] && filterState[name].length > 0) 
            return filterState[name].map(e => e.value.id).includes(value[accessor]);
        return true;
    }
    return {
        ...data,
        productPrices: data.productPrices?.filter(pp => [
            ['products', 'productId'],
            ['regions', 'regionId'],
            ['providers', 'providerId']
        ].every( attribute => filter(attribute, pp) ))
    }
}

const markIfOutdated = (prices) => {
    return prices.map(p => ({...p, isOutdated: ProductPriceModel.isOutdated(p)}))
}

const markIfBest = (prices) => {
    const memo = {}
    const registerIfBest = (price) => {
        if(!ProductPriceModel.isOutdated(price)) {
            const key = `${price.product.id}-${price.region.id}`
            if(memo[key]) {
                if(memo[key].spotPrice > price.spotPrice) {
                    memo[key] = {
                        id: price.id,
                        price: price.spotPrice
                    }
                }
            } else {
                memo[key] = { id: price.id, price: price.spotPrice }
            }
        }
    }

    for(let price of prices) {
        registerIfBest(price)
    }

    for(let key of Object.keys(memo)) {
        prices.find(p => memo[key].id == p.id).isBest = true;
    }

    return prices
}

const setMarks = (unmarkedPrices) => {
    let markedPrices = markIfBest(unmarkedPrices)
    markedPrices = markIfOutdated(markedPrices)
    return markedPrices;
}

export const ProcessData = data => {
    return {
        ...data,
        productPrices: setMarks(data.productPrices)
    }
}

