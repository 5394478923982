import React, { useContext, Fragment, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, TextField, Typography, Button, makeStyles } from '@material-ui/core'
import moment from 'moment'
import clsx from 'clsx'
import gsap from 'gsap'


import { ManagerContext } from './CarrierManager.jsx'
import { Field, ErrorBoundary, Select } from 'components'
import { RouteRateModel } from 'models'
import { FormHandler } from 'lib/Business'

const useStyles = makeStyles(theme => ({
  discreteCard: {
    transition: 'all 0.3s',
    '&.active': {
      border: '1px solid gray',
      padding: '5px',

    }
  },
  discreteButton: {
    display: 'none',
    // '&.active': {
    //     display: 'block !important'
    // }
  }

}))

const PrevFSCItem = ({ routeRate }) => (
  <div className={'fsc__card-item'}>
    <Grid
      container
      spacing={2}
      className={'fsc__grid'}
    >
      <Grid
        item
        md={3}
      >
        {routeRate.fscType === 'mile_based' && (
          <Typography>
            {Number(routeRate.fsc).toFixed(2)} USD
          </Typography>)
        }


        {routeRate.fscType === 'tarif_percentage' && (
          <Typography>
            {(Number(routeRate.fsc) * 100).toFixed(0)}%
          </Typography>)
        }
      </Grid>

      <Grid
        item
        md={3}
      />

      <Grid
        item
        md={3}
        className="fsc__date-cols"
      >
        <Typography>{moment(routeRate.startDate).format('D/M/YYYY')}</Typography>
      </Grid>

      <Grid
        item
        md={3}
        className="fsc__date-cols"
      >
        <Typography>{moment(routeRate.endDate).format('D/M/YYYY')}</Typography>
      </Grid>

    </Grid>
  </div>
)

const PreviousFSC = (props) => {
  const context = useContext(ManagerContext)

  if (!context.selectedCarrier) return (
    <div />
  )

  return (
    <Fragment>
      {context.selectedCarrier.routeRates.sort((a, b) => new Date(b.endDate) - new Date(a.endDate)).slice(0, props.count).map(fsc => <PrevFSCItem routeRate={fsc} />)}
    </Fragment>
  )
}

const FSCFields = () => {
  const { t } = useTranslation()


  return (
    <Fragment>
      <Grid
        item
        md={3}
      >
        <Field
          field={TextField}
          fullWidth
          label={t('fsc')}
          name="routeRate.fsc"
          type="number"
          valueAsNumber
        />
      </Grid>

      <Grid item md={3}>
        <Field
          field={Select}
          name='routeRate.fscType'
          fullWidth={true}
          type='select'
          options={
            [{ value: 'mile_based', label: 'Mile Based' },
            { value: 'tarif_percentage', label: 'Tarif Percentage' }
            ]
          }
        />
      </Grid>


      <Grid
        item
        md={3}
      >
        <Field
          field={TextField}
          fullWidth
          label={t('startDate')}
          name="routeRate.startDate"
          type="date"
        />
      </Grid>

      <Grid
        item
        md={3}
      >
        <Field
          field={TextField}
          fullWidth
          label={t('endDate')}
          name="routeRate.endDate"
          type="date"
        />
      </Grid>
    </Fragment>
  )

}

const FSCForm = () => {
  const RouteRateFormHandler = new FormHandler({ model: RouteRateModel })
  const methods = useForm()
  const classes = useStyles();
  const context = useContext(ManagerContext)
  const { t } = useTranslation()
  const card = useRef()

  const isDirty = methods.formState.isDirty

  useEffect(() => { methods.register('routeRate.carrier') }, [])
  useEffect(() => {
    methods.setValue('routeRate.carrier', context.selectedCarrier)
  }, [context.selectedCarrier])


  useEffect(() => {
    if (!isDirty) return
    const t1 = gsap.timeline()
    t1.to('.discreteCard', { padding: '5px', duration: 0.02 })
    t1.to('.discreteButton', { display: 'block', duration: 0.02 }, '<')
    t1.fromTo('.discreteButton', { yPercent: '-10', opacity: 0, duration: 0.01 },
      { yPercent: 0, opacity: 1 }, '<+0.1')
  }, [isDirty])

  const [mutate] = useMutation(RouteRateFormHandler.getQuery())
  const onSubmit = RouteRateFormHandler.getOnSubmit(mutate, {
    onCompleted: () => {
      context.state.showAlert({
        severity: 'success',
        message: 'FSC Updated'
      });
    }
  })

  return (
    <div className={clsx(classes.discreteCard, 'discreteCard')}>
      <FormProvider {...{ ...context, ...methods }}>
        <form
          autoComplete="off"
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Grid
            container
            spacing={2}
          >
            <FSCFields />
            <Grid
              item
              md={3}
              className={clsx(classes.discreteButton, 'discreteButton')}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  )
}

const FSCWidget = () => {
  return (
    <ErrorBoundary>
      <div>
        <FSCForm />
        <PreviousFSC count={2} />
      </div>
    </ErrorBoundary>
  )

}
export default FSCWidget
