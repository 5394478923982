import React, {useContext} from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { TextField, Card, Grid, CardActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { RoutesContext } from '../RouteManager.js'
import { Select } from 'components';
import { UPDATE_ROUTE_RATE, CREATE_ROUTE_RATE } from 'constants/queries';

const customStyles = {
  input: {
    border: '1px solid transparent'
  },
  label: {
    color: 'blue'
  }
};

export const routeRateDirtyFields = [
  'routeRate.fsc',
  'routeRate.startDate',
  'routeRate.endDate',
  'routeRate.carrier'
];

export const getRouteRateValues = data => ({
  fsc: Number(data.routeRate.fsc),
  startDate: data.routeRate.startDate,
  endDate: data.routeRate.endDate,
  carrierId: data.routeRate.carrier.id
});

export const RouteRateFields = () => {
  const { t } = useTranslation();

  const methods = useFormContext();
  const { errors, register, routeRate, carrierOptions, watch, classes } = methods;
  const watchAll = watch();
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Select
          name="routeRate.carrier"
          subject={routeRate}
          control={methods.control}
          options={carrierOptions}
          sv={methods.setValue}
          label={t('carrier')}
          required={true}
          errors={errors}
          getDefaultMatcher={(option, subject) =>
            option.id == subject?.carrier?.id
          }
        />
      </Grid>
      <Grid xs={6} item>
        <TextField
          fullWidth
          required
          defaultValue={routeRate?.fsc}
          label={t('fsc')}
          margin="dense"
          name="routeRate.fsc"
          type="number"
          className={"rm__text"} variant="outlined"
          inputRef={register({ required: true })}
          error={errors.routeRate?.fsc}
          helperText={errors.routeRate?.fsc && t(errors.routeRate?.fsc)}
          InputProps={{className: classes.Input}}
        />
      </Grid>

      <Grid item xs={6}> </Grid>

      <Grid xs={6} item>
        <TextField
          fullWidth
          required
          InputProps={{className: classes.Input}}
          defaultValue={routeRate?.startDate}
          label={t('startDate')}
          margin="dense"
          name="routeRate.startDate"
          type="date"
          className={"rm__text"} variant="outlined"
          inputRef={register({ required: true })}
          error={errors.routeRate?.startDate}
          helperText={
            errors.routeRate?.startDate && t(errors.routeRate?.startDate)
          }
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid xs={6} item>
        <TextField
          fullWidth
          required
          defaultValue={routeRate?.endDate}
          label={t('endDate')}
          margin="dense"
          name="routeRate.endDate"
          type="date"
          className={"rm__text"} variant="outlined"
          inputRef={register({ required: true })}
          error={errors.routeRate?.endDate}
          helperText={errors.routeRate?.endDate && t(errors.routeRate?.endDate)}
          inputProps={{
            className: classes.Input,
            min: watchAll['routeRate.startDate']
              ? watchAll['routeRate.startDate']
              : moment().format('YYYY-MM-DD')
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </React.Fragment>
  );
};

const RouteRateForm = props => {
  const { classes, routeRate, regions, carriers, onCompleted, onError } = props;
  const methods = useForm();
  const context = useContext(RoutesContext)
  const isUpdate = !!routeRate
  const query = isUpdate ? UPDATE_ROUTE_RATE : CREATE_ROUTE_RATE
  const [mutate, mutationStatus] = useMutation(
    query,
    {
      onCompleted,
      onError,
      update: isUpdate ? undefined : context.handleUpdate('routeRates', r => r.data.routeRate.create)
    }
  );

  const onSubmit = data => {
    let payload = { variables: {} };
    if (routeRate) payload.variables.id = routeRate.id;

    const routeRateAttributes = getRouteRateValues(data);
    payload.variables.input = {
      ...routeRateAttributes
    };

    mutate(payload);
  };

  return (
    <FormProvider
      {...methods}
      classes={classes}
      fieldsEnabled={true}
      routeRate={routeRate}
      carrierOptions={carriers.map(e => ({ label: e.name, value: e }))}>
      <form
        className={classes.formRoot}
        autoComplete="off"
        noValidate
        onSubmit={methods.handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <RouteRateFields />
        <Grid item xs={4}>
          <Button type="submit" className={"rm__text"} variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
      </form>
    </FormProvider>
  );
};

export default RouteRateForm;
