import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles } from '@material-ui/core'

import { Field, Select } from 'components'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    }
}))

const Filters = ({regions, providers, products}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item sm={12} md={4}>
                <Field
                    field={Select}
                    multiple
                    name='products'
                    label={t('products')}
                    options={ products.map(p => ({value: p, label: p.name})) }
                />
            </Grid>
            <Grid item sm={12} md={4}>
                <Field
                    field={Select}
                    multiple
                    name='regions'
                    label={t('regions')}
                    options={ regions.map(p => ({value: p, label: p.name})) }
                />
            </Grid>
            <Grid item sm={12} md={4}>
                <Field
                    field={Select}
                    multiple
                    name='providers'
                    label={t('providers')}
                    options={ providers.map(p => ({value: p, label: p.name})) }
                />
            </Grid>
        </Grid>
    )
}

export default Filters
