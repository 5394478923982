import React, { Fragment, useContext } from 'react';
import { Typography, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { routeCanonicName } from 'lib/helpers'
import {RoutesContext} from '../RouteManager.js'



const RouteCard = props => {
  const { t } = useTranslation()
  const context = useContext(RoutesContext)


  return (
<Fragment>
    <Typography className={"rm__text"} variant='body2'>{props.route.routeCode}</Typography>
    <Typography className={"rm__text"} variant='body2'>{`${props.route.originRegion.name} - ${props.route.destinationRegion.name}`}</Typography>
    <Typography className={"rm__text"} variant='body2'>Miles: {props.route.miles}</Typography>
</Fragment>
  )
}

export default RouteCard
