import React, { useState, useContext, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { StoreContext } from 'App';
import { CREATE_USER } from 'constants/queries';
import { UserInitial } from 'views/UserView/data';
import Helpers from 'lib/helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  }
}));

const UserCreationForm = props => {
  const classes = useStyles();
  const context = useContext(StoreContext);
  const password = useRef({});

  const { t } = useTranslation();
  const { className, user, ...rest } = props;
  const { register, errors, handleSubmit, watch } = useForm();
  password.current = watch('password', '');
  const [createUser, mutationStatus] = useMutation(CREATE_USER, {
    onCompleted: () =>
      context.state.showAlert({
        severity: 'success',
        message: `${t('user')} ${t('created')}`
      })
  });

  const credentials = [
    {
      value: 'supplier',
      label: t('supplier')
    },
    {
      value: 'buyer',
      label: t('buyer')
    },
    {
      value: 'admin',
      label: t('administrator')
    }
  ];

  const onSubmit = data => createUser({ variables: { input: { ...data } } });

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          subheader={t('information_editable')}
          title={t('profile')}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Porfavor especifica el primer nombre"
                label={t('firs_name')}
                margin="dense"
                name="firstName"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.firstName}
                helperText={errors.firstName && t(errors.firstName.type)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('last_name')}
                margin="dense"
                name="lastName"
                required
                variant="outlined"
                inputRef={register({ required: true })}
                error={errors.lastName}
                helperText={errors.lastName && t(errors.lastName.type)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('email')}
                margin="dense"
                name="email"
                required
                variant="outlined"
                inputRef={register({
                  required: true,
                  pattern: Helpers.emailRegex
                })}
                error={errors.email}
                helperText={errors.email && t(errors.email.type)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                variant="outlined"
                error={errors.credentials}
                className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t('user_credentials')}
                </InputLabel>
                <Select
                  native
                  name="credentials"
                  inputRef={register({ required: true })}
                  label="Credenciales"
                  inputProps={{
                    name: 'credentials',
                    id: 'user-credentials-select'
                  }}>
                  {credentials.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
                <FormHelperText>
                  {errors.credentials && t(errors.credentials.type)}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('password')}
                margin="dense"
                name="password"
                required
                variant="outlined"
                type="password"
                inputRef={register({ required: true })}
                error={errors.password}
                helperText={errors.password && t(errors.password.type)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('confirmation')}
                margin="dense"
                name="passwordConfirmation"
                required
                variant="outlined"
                type="password"
                inputRef={register({
                  required: true,
                  validate: value =>
                    value === password.current || 'password_mismatch'
                })}
                error={errors.passwordConfirmation}
                helperText={
                  errors.passwordConfirmation &&
                  t(errors.passwordConfirmation.type)
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="contained" type="submit">
            {t('create_user')}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default UserCreationForm;
