export const sortByDateFn = (a, b) =>
  new Date(b.createdAt) - new Date(a.createdAt);

export const routeCanonicName = (route) => `#${route.routeCode}: ${route.originRegion.name} - ${route.destinationRegion.name}`

export const safeStringify = (obj, indent = 2) => {
  let cache = [];
  const retVal = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === "object" && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent
  );
  cache = null;
  return retVal;
};

export const numberWithCommas = x => (
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
)

export default {
  translateStatus: words => {
    if (!words) return;
    const capitalize = word => word.charAt(0).toUpperCase() + word.substring(1);
    return words
      .match(/[A-Za-z][a-z]*/g)
      .map(capitalize)
      .join(' ');
  },
  createKeyHandler: options => e => {
    if (!options.targets.some(i => i === e.target)) return;
    e.preventDefault();
    options[e.which] && options[e.which](e);
  },
  sortByDate: array => array.splice().sort(sortByDateFn),
  localeDateString: date => {
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    return date.toLocaleDateString('es-MX', options);
  },
  capitalize: string =>
    string && string.replace(/^\w/, chr => chr.toUpperCase()),
  phoneRegex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
  emailRegex: /\w*@\w*\.\w*/
};

export class Stack {
  constructor() {
    this.items = []
  }
  push = (element) => this.items.push(element)
  pop = () => this.items.pop()
  isEmpty = () => this.items.length === 0
  empty = () => (this.items.length = 0)
  top = () => this.items[this.items.length - 1]
  toArray = () => [...this.items]
}
