import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { UserCreationForm, UserProfile } from './components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_USERS } from 'constants/queries';
import { UserInitial } from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const UserCreation = props => {
  const { userId } = useParams();
  const { className, user, ...rest } = props;
  const { data } = useQuery(FETCH_USERS, {
    variables: { query: { id: userId } }
  });
  const classes = useStyles();

  if (data)
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={4} md={6} xl={4} xs={12}>
            <UserProfile user={UserInitial} />
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            <UserCreationForm user={UserInitial} />
          </Grid>
        </Grid>
      </div>
    );
  return <div></div>;
};

export default UserCreation;
