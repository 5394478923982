import React from 'react'
import {
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { FormOptions } from '../../components'


const IndexFields = ({type, contract, product, register, errors, setValue, control, classes, fieldsEnabled}) => {
  const { t } = useTranslation();
  return (
  <React.Fragment>
    <Grid item md={4} xs={12}>
      <FormControl
        fullWidth
        variant="outlined"
        error={errors.priceIndexId}
        className={classes.formControl}>
        <Controller
          name="priceIndexId"
          control={control}
          rules={{ required: true }}
          defaultValue={contract ? contract.priceIndex : null}
          as={({ value, onChange }) => (
            <Autocomplete
              id="price-index-id"
              value={value}
              onChange={(_, value) => setValue( 'priceIndexId', value) }
              options={product && product.priceIndices ? product.priceIndices : []}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  error={errors.product}
                  label={t('priceIndex')}
                  variant="outlined"
                />
              )}
            />
          )}
        />
        <FormHelperText>
          {errors.product && t(errors.product.type)}
        </FormHelperText>
      </FormControl>
    </Grid>
    <Grid item md={4} xs={12}>
      <FormControl
        fullWidth
        error={errors.priceIndexType}
        className={classes.formControl}
        variant='outlined'
      >
        <Controller
          name='priceIndexType'
          defaultValue={contract ? FormOptions.priceIndexTypeOptions.find(e =>  e.value == contract.priceIndexType) : null}
          control={control}
          rules={{required: true}}
          as={({value, onChange}) => (
            <Autocomplete
              id='price-index-type'
              value={value && value.label ? value : FormOptions.priceIndexTypeOptions.find(e => e.value == value )}
              onChange={(_, value) => setValue( 'priceIndexType', value) }
              options={FormOptions.priceIndexTypeOptions}
              getOptionLabel={option => t(option.label)}
              renderInput={params => (
                <TextField
                  {...params}
                  error={errors.priceIndexType}
                  label={t('price_index_type')}
                  variant='outlined'
                />
              )}
            />
          )}
        >
        </Controller>
        <FormHelperText>
            {errors.priceIndexType && t(errors.priceIndexType.type)}
        </FormHelperText>
      </FormControl>
    </Grid>
    <Grid item md={4} xs={12}>
      <TextField
        fullWidth
        id='index-offset'
        margin='dense'
        defaultValue={contract ? contract.offset : 0}
        name='offset'
        label={t('offset')}
        type='number'
        variant='outlined'
        inputRef={register({required: true})}
        error={errors.offset}
        helperText={
          errors.offset && t(errors.offset.type)
        }
        InputLabelProps={{
          shrink: true
        }}
      />
    </Grid>  
  </React.Fragment>
  )
}
export default IndexFields 
