import React, {useEffect, useState} from 'react'
import {
  Card,
  CardHeader,
  Divider,
  CardActions,
  CardContent
} from '@material-ui/core'
import clsx from 'clsx'

import './assets/slider.scss'

const Slider = (props) => {
  return (
    <div class="slider">
      {props.slides.map((slide, index) => (
          <div className={clsx('slider__item', index == props.index && 'active')}>
              {slide}
          </div>
      ))}
    </div>
  )
}

export default Slider
