import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { FETCH_PRODUCT_DASHBOARD_DATA, CREATE_LOT } from 'constants/queries'
import { Button, makeStyles, Grid, Typography, Tooltip, Chip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import clsx from 'clsx' 

import { RackSolutionFinder, ProductPriceClass } from 'business'
import { QueryResult, ErrorObject, ErrorBoundary } from 'components'
import { PriceIndexValueForm } from 'views'
import { UnitConverter } from 'business'

import { ProductPrices } from './components/ProductPrices'
import { query } from './query.js'




const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    clientCard: {
        cursor: 'pointer !important',
        padding: '1em',
        transition: '0.3s all',
        '&:hover': {
            boxShadow: '0 3px 6px #000a'
        }

    },
    tooltipCard: {
        padding: '1em',
        boxShadow: '0 3px 6px #000a',
        border: 'red',
        display: 'inline-block',
        boxSizing: 'border-box'
    },
    productPrice: {
        // display: 'flex',
        // alignItems: 'center',
        // flexDirection: 'column',
    },
    invalidPrice: {
        // background: 'red',
        border: '1px solid red',
        color: `${theme.palette.error[500]} !important`
    },
    clientText: {
        fontWeight: '300',
        letterSpacing: '5px',
        textTransform: 'uppercase',
        marginBottom: '0.5em',
    },
    regionText: {
        fontWeight: 'bold',
        marginBottom: '0.25em',
    },
    productText: {
        color: props => !props.valid ? theme.palette.error.main : '#777',
        fontWeight: '500'
    },
    icon: {
        border: '1px solid #DDD',
        borderRadius: '5px',
        padding: '5px',
        cursor: 'pointer',
        position: 'absolute',
        top: '0',
        right: '0',
    },
    card: {
        position: 'relative',
        margin: '1em',
        borderLeft: `2px solid ${theme.palette.info.main}`,
        padding: '1em',
        flexDirection: 'column',
        boxShadow: '0 3px 10px #999A',
        borderRadius: '5px'
    },
    regionTip: {
        flex: '1, 0, auto',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: '10px 10px 10px 15px',
        borderRadius: '5px',
        border: props => !props.valid ? `1px solid ${theme.palette.error.main}` : '1px solid',
        margin: '3px 0',
        width: '100%',
    },
    regionTipText: {
        color: props => !props.valid ? theme.palette.error.main : 'initial'
    },
    outdatedRegionTip: {
        color: theme.palette.error[500],
        background: theme.palette.error[100],
        borderColor: theme.palette.error[500],
    }
}))


const RegionTip = ({route, name}) => {
    const classes = useStyles({valid: !route.isOutdated});
    const {t} = useTranslation();
    if(route)
        return <div className={clsx(classes.regionTip)}>
            <Typography className={classes.regionTipText}>{route.destinationRegion.name}</Typography>
            <div style={{display: 'flex', alignItems: 'center', margin: '0 10px'}}>
                <Typography className={classes.regionTipText} style={{marginRight: '5px'}}>+{route.totalCost.value.toFixed(2)} {t(route.totalCost.unit)}</Typography>
                <Chip label={route.carrier.name}/>
            </div>
        </div>
    return 
}

const TooltipProductPrice = ({productPrice}) => {
    const { t } = useTranslation()
    const classes = useStyles();



    return <div className={classes.tooltipCard}> 
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography style={{margin: '0 1em'}}>
                    {productPrice.getFormulaText()}
                </Typography>

                <Chip label={productPrice.provider.name}/>
            </div>
        </div>
}

const OriginRegionTip = ({pricePathPair}) => {
    const { t } = useTranslation()
    const classes = useStyles({valid: !pricePathPair.productPrice.isOutdated});
    const startPrice = `${pricePathPair.productPrice.varPriceIndex.name} + ${pricePathPair.productPrice.varAdder} ${t(pricePathPair.productPrice.unitType)}`

    return (
        <div className={classes.regionTip}>
            <Typography style={{marginRight: '10px' }} className={classes.regionTipText}>{pricePathPair.path.originRegion.name}</Typography>
            <Typography className={classes.regionTipText}>{startPrice}</Typography>
        </div>
    )
}

const TooltipIndirectProductPrice = ({pricePathPair}) => {
    const { t } = useTranslation()
    const classes = useStyles();

    const priceInIndexUnits = UnitConverter.convert({value: pricePathPair.finalCost,
        from: pricePathPair.unitType,
        to: pricePathPair.productPrice.varPriceIndex.unitType}) 
    const finalPriceInIndexUnits = (priceInIndexUnits - pricePathPair.productPrice.varCurrentValue).toFixed(2);
    const finalPrice = `${pricePathPair.productPrice.varPriceIndex.name} + ${finalPriceInIndexUnits} ${t(pricePathPair.productPrice.varPriceIndex.unitType)}`

    return (
        <div className={classes.tooltipCard}> 
            <Typography>
                {finalPrice}
            </Typography>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <OriginRegionTip pricePathPair={pricePathPair}/>
                {pricePathPair.path.routes.map(r => (
                    <RegionTip route={r}/>
                ))}
            </div>
        </div>


    )
}

const IndirectProductPrice = ({clientId, pricePathPair, revealTooltip, hideTooltip}) => {
    const classes = useStyles({valid: !pricePathPair.isOutdated});
    const { t } = useTranslation();

    const onMouseOver = () => {
        revealTooltip({clientId, child: <TooltipIndirectProductPrice pricePathPair={pricePathPair}/>})
    }

    const onMouseLeave = () => {
        hideTooltip()
    }

    return (
        <div classname={classes.productPrice} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            <Typography className={classes.productText}>
                {pricePathPair.product.name} - ${pricePathPair.finalCost.toFixed(2)} - {t(pricePathPair.unitType)} <Chip style={{margin: '0 1em'}} label={t('indirect')}/>
            </Typography>
        </div>
    )

}


export const ProductPricesView = () => {
    const {refetch, ...data} = useQuery(query)
    const [indexModalVisible, setIndexModalVisible] = useState(false)

    useEffect(() => {
        const handleKeys = e => {
            const isShift = !!window.event.shiftKey
            if(!isShift) return
            if(e.key == 'A') {
                setIndexModalVisible(true);
            }
            if(e.key == 'E') {
                setIndexModalVisible(false);
            }
        }
        document.addEventListener('keypress', handleKeys)
        return () => window.removeEventListener('keypress', handleKeys)
    }, [])

    return (
        <QueryResult {...data}>
            {data => (
                <>
                <div style={{opacity: indexModalVisible ? 1 : 0,
                             pointerEvents: indexModalVisible ? 'auto' : 'none',
                             position: 'absolute',
                             zIndex: 2,
                             top: 'calc(64px + 5%)',
                             right: '1em',
                             width: '30vw',
                             maxHeight: '40vh'}}
                >
                    <PriceIndexValueForm onCompleted={refetch} onClose={() => setIndexModalVisible(false)}/>
                </div>
                <ProductPrices data={data}/>
                </>
            )}
        </QueryResult>
    )
}
