import COUNTRIES from 'constants/countries'
import { Stack } from 'lib/helpers'
import RouteClass from './RouteClass'
import PathClass from './PathClass'
import Path from './Path'

const reverseArray = a => {
    let i = 0;
    while(i < a.length / 2) {
        let tmp = a[i]
        a[i] = a[a.length - 1 - i]
        a[a.length - 1 - i] = tmp;
        i++;
    }
    return a;
}


const PathFinder = {
  pathsConnect(a, b)  {
    return a?.destinationRegion?.id == b?.originRegion?.id
  },

  findFromTo(origin, destination, routes, options) {
    const validRoutes = routes.filter(this.filterRoutes(options))
    return validRoutes.map(r => new Path([r]))
  },

  filterRoutes(options) {
    return route => (
        route.destination.region.country == COUNTRIES.MEXICO &&
        route.origin.region.id == origin.region.id
      )
  },

  findAllPathsFrom({originRegion, routes}) {
      const routeStack = new Stack()
      const calculatedPathsByRegion = {
          [originRegion.id]: {routes: [], isOrigin: true}
      }

      const startingRoutes = routes.filter(r => r.originRegion.id == originRegion.id);
      startingRoutes.forEach(sr => routeStack.push(sr));


      do {
          const currentRoute = routeStack.top(); routeStack.pop();

          const currentRegion = currentRoute.destinationRegion;

          // console.log('------------------------------------------------------------')
          // console.log(`currentTable= ${JSON.stringify(calculatedPathsByRegion)}`)
          // console.log(`originRegion= ${JSON.stringify(currentRoute.originRegion)}`)
          // console.log(`currentRoute= ${JSON.stringify(currentRoute)}`)

          const currentPath = new PathClass([...calculatedPathsByRegion[currentRoute.originRegion.id].routes, currentRoute])
          const competingPath = calculatedPathsByRegion[currentRegion.id]
          // console.log(competingPath)
          // console.log(currentPath)
          if(competingPath && (competingPath.isOrigin || competingPath.isCheaperThan(currentPath)))
              continue;

          calculatedPathsByRegion[currentRegion.id] = currentPath;
          routes.filter(r => currentRoute.isFollowedBy(r)).forEach(r => routeStack.push(r));

      } while(!routeStack.isEmpty())
      return Object.keys(calculatedPathsByRegion).map(k => calculatedPathsByRegion[k])
  },

  findAllPathsTo(destination, routes) {
      let resultList = [];

      const destinationsTable = {}
      routes.forEach(r => {
          if(destinationsTable[r.destinationRegion.id]) {
              destinationsTable[r.destinationRegion.id].push(r)
          } else {
              destinationsTable[r.destinationRegion.id] = [r]
          }
      })

      let pathStack = new Stack();
      let currentPath = [];


      if(!destinationsTable[destination.id]) return []

      destinationsTable[destination.id].forEach(pathStack.push);
      let counter = 50;

      do {
          let currentRoute = pathStack.pop();
          while(currentPath.length && 
              currentRoute.destinationRegion.id != currentPath[currentPath.length - 1].originRegion.id) {
                  currentPath.pop();
          }

          if(destinationsTable[currentRoute.originRegion.id])
              destinationsTable[currentRoute.originRegion.id].forEach(pathStack.push);

          // if(!currentRoute.freightRate) debugger
          currentPath.push(new RouteClass({...currentRoute}));
          resultList.push(reverseArray([...currentPath]));
      } while(!pathStack.isEmpty() && counter--)

      return resultList.map(r => new PathClass(r));
  }
}
export default PathFinder
