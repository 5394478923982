import React, {useEffect} from 'react'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  Button,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { makeStyles  } from '@material-ui/styles'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import gql from 'graphql-tag';

import { TextField, LoadingBar, Loader,  Select, FileBox } from 'components'
import { FETCH_CONTRACTS, CREATE_MOVEMENT, UPDATE_MOVEMENT } from 'constants/queries'
import * as Fragments from 'constants/fragments'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4) 
  }
}))

const movementFieldList = ['contractId', 'containerId', 'originId', 'loadingDate', 'netVolume', 'rollover', 'bol']
const getMovementData = (data ) => ({
  contractId: data.contractId,
  containerId: data.containerId,
  loadingDate: data.loadingDate,
  netVolume: parseInt(data.netVolume),
  rollover: data.rollover,
  originId: data.originId,
  bol: data.bol,
})

const MovementFields = (props) => {
  const { form, movement, contracts, origins } = props
  const contractOptions = contracts.map(e => ({label: e.canonicName.replaceAll(':', '.'), value: e.id}))
  const originOptions = origins.map(e => ({label: e.city, value: e.id}))

  const { t } = useTranslation(0)

  useEffect(() => {
    form.register('bol')
  }, [])


  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Select 
          name='contractId'
          label={t('contract')}
          subject={movement}
          options={contractOptions}
          sv={form.setValue}
          errors={form.errors}
          control={form.control}
        />
      </Grid>
      <Grid item xs={12}>
        <Select 
          name='originId'
          label={t('origin')}
          subject={movement}
          options={originOptions}
          sv={form.setValue}
          {...form}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField 
          name='containerId'
          label={t('containerId')}
          subject={movement}
          required={true}
          {...form}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField 
          name='loadingDate'
          required={true}
          label={t('loadingDate')}
          subject={movement}
          type='date'
          InputLabelProps={{
            shrink: true
          }}
          {...form}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField 
          name='netVolume'
          required={true}
          label={t('netVolume')}
          subject={movement}
          type='number'
          {...form}
        />
      </Grid>
      <Grid item xs={12}>
        <FileBox 
          onSave={file => form.setValue('bol', file)}
          label='BOL'
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox inputRef={form.register()} name='rollover'/>}
          label='Rollover'
        />
      </Grid>
    </React.Fragment>
  )
}

const FETCH_CREATION_DATA = gql`
  query FetchMovementCreationData {
    contracts { ...contractFields }
    destinations { ...destinationFields }
  }
  ${Fragments.ContractFragments.fields}
  ${Fragments.DestinationFragments.fields}
`

const MovementForm = (props) => {
  const { movement, onCompleted, onError, fieldsEnabled = true } = props
  const { t } = useTranslation()
  const {data} = useQuery(FETCH_CREATION_DATA)
  const form = useForm()
  const [mutate, mutationStatus] = useMutation(movement ? UPDATE_MOVEMENT : CREATE_MOVEMENT, { onCompleted, onError })
  const classes = useStyles()
  const onSubmit = (data) => { ; mutate({ variables: { input: {...getMovementData(data)} } }) }

  if(data)
    return (
      <div>
        <form autoComplete='off' noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <Card>
            <CardHeader title={t('movement')}/>
            <Divider />
            <CardContent>
              <MovementFields form={form} movement={movement} contracts={data.contracts} origins={data.destinations}/>
            </CardContent>
            <Divider/>
            <CardActions>
                {fieldsEnabled && (
                  <Button color='primary' variant='contained' type='submit' disabled={mutationStatus.loading}>
                      {t('changesSaved')}
                  </Button>
                )}
            </CardActions>
            {mutationStatus.loading && <LoadingBar/>}
          </Card>
        </form>
      </div>
    )
  return <Loader/>

}

export default MovementForm
