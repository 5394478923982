import {CREATE_ROUTE, UPDATE_ROUTE} from 'constants/queries'
const RouteModel = {
  keys: {
      create: 'routes'
  },
  fields: [
    'route.routeCode',
    'route.miles',
    'route.carrierId',
    'route.productId',
    'route.originId',
    'route.destinationId',
  ],
  extractFields: data => ({
    routeCode: data.route.routeCode,
    miles: Number(data.route.miles),
    carrierId: data.route.carrier.id,
    productId: data.route.product.id,
    originRegionId: data.route.originRegion.id,
    destinationRegionId: data.route.destinationRegion.id,
  }),
  getDefaultValues: routeInstance => (routeInstance ? {
    'route.routeCode': routeInstance.routeCode,
    'route.miles': routeInstance.miles,
    'route.carrierId': routeInstance.carrier.id,
    'route.productId': routeInstance.product.id,
    'route.originId': routeInstance.origin.id,
    'route.destinationId': routeInstance.destination.id,
  } : null),
  queries: {
    create: CREATE_ROUTE,
    update: UPDATE_ROUTE
  },
  // buildCarrierOptions: ( carrierArray, t) => carrierArray.map(carrier => ({label: t(carrier.routeCode), value: carrier })),
  // buildRegionOptions: ( regionArray, t) => regionArray.map(region => ({label: t(region.routeCode), value: region })),
  // buildProductOptions: ( productArray, t) => productArray.map(product => ({label: t(product.routeCode), value: product }))
}

export default RouteModel
