import React, { useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  Button,
  Typography
} from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'
import Monthrangepicker from 'react-bootstrap-monthrangepicker';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-monthrangepicker/daterangepicker.css'
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment'
import * as Helpers from 'lib/helpers'

const useStyles = makeStyles(theme => ({
  preFadeIn: {
    position: 'relative',
    right: '3em',
    opacity: '0',
    transition: 'all 0.6s, right 0.3s'
  },
  fadeInRight: {
    position: 'relative',
    right: '-2em',
    opacity: '1',
    transition: 'opacity 0.6s, right 0.3s'
  }
}))

const buildName = (e) => {
  return `term.year-${e.year()}.month-${e.month()}.value`
}

const rangeMap = (low, high, fn) => {
  const arr = [];
  const start = moment(low)
  while(!start.isSame(high, 'month')) {
    arr.push(fn(start))
    start.add(1, 'month')
  }
  arr.push(fn(high))
  return arr;
}


const buildNameWithId = (e) => {
  return `term.id-${e.id}`
}

const TermMonth = (params) => {
  const name = buildNameWithId(params)
  const {year, month, value} = params
  const display = moment(`${year}/${month}`)

  useEffect(() => () => params.unregister(name), [])

  return (
    <Grid item md={3} xs={6} key={name}>
      <TextField
        name={name}
        defaultValue={value}
        inputRef={params.register({required: true})}
        error={params.errors[name]}
        id={name}
        InputProps={{
          startAdornment: <InputAdornment position='start'>{display.format('MMM "YY')}</InputAdornment>
        }}
        variant='outlined'
      />
    </Grid>
  )
}

const FromInquiry = (params) => {
  const {type, inquiry, register, errors, unregister, setValue, fieldsDisabled} = params
  const classes = useStyles();
  const { t } = useTranslation()
  const watchAll = params.watch()

  const start = moment(`${inquiry.startYear}/${inquiry.startMonth}`, 'YYYY-MM');
  const end = moment(`${inquiry.endYear}/${inquiry.endMonth}`, 'YYYY-MM') 

  let now = new Date();
  let maxTime = new Date(new Date().setMonth(now.getMonth()+16))

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Monthrangepicker
          startDate={now}
          endDate={maxTime}
          monthOrQuarter={0}
          onApply={() => {}}
        >
          <Button 
            disabled={true}
            color='primary'
            variant='contained'
            onClick={(e) => params.setUsingRange(true)}
          >
              {t('set_months') + ' '}
          </Button>
          <Typography variant='subtitle' className={classes.fadeInRight}>
              {`${start.format('MMM "YY')} - ${end.format('MMM "YY')}`}
          </Typography>
        </Monthrangepicker>
      </Grid>
        {inquiry.termMonths.sort((a, b) => a.id - b.id).map((term) => <TermMonth {...term} {...params}/>)}
    </Grid>
  )
  
}


const FromRange = (params) => {
    const classes = useStyles();
    const { t } = useTranslation()
    const {type, inquiry, register, errors, unregister, setValue, fieldsDisabled} = params
    const watchAll = params.watch()

    let now = new Date();
    let maxTime = new Date(new Date().setMonth(now.getMonth()+16))
    
    const [range, setRange] = useState(null)
    const [termFields, setTermFields] = useState([])

    const fieldBuilder = (e) => {
      const name = buildName(e)
      termFields.push(name)
      return <Grid item md={3} xs={6} key={name}>
        <TextField
            name={name}
            inputRef={register({required: true})}
            error={errors[name]}
            id={name}
            InputProps={{
              startAdornment: <InputAdornment position="start">{e.format('MMM "YY')} - Qty. {t(watchAll.productUnit?.name)}s: </InputAdornment>,
            }}
            variant="outlined"
          />
      </Grid>
    }

    // useEffect(() => {
    //   register('startMonth')
    //   register('endMonth')
    //   return () => {
    //     unregister('startMonth')
    //     unregister('endMonth')
    //   }
    // }, [])
    //
    // useEffect(() => {
    //   if(!range) return
    //   setValue('startMonth', range.start)
    // }, [range])

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Monthrangepicker
            startDate={now}
            endDate={maxTime}
            monthOrQuarter={0}
            onApply={(_, picker) => setRange({start: picker.startDate, end: picker.endDate})}
          >
            <Button 
              color='primary'
              variant='contained'
            >
                {t('set_months') + ' '}
            </Button>
            <Typography variant='subtitle' className={clsx({[classes.preFadeIn]: !range, [classes.fadeInRight]: range})}>
                {range && `${range.start.format('MMM "YY')} - ${range.end.format('MMM "YY')}`}
            </Typography>
          </Monthrangepicker>
        </Grid>
          {range && (
            rangeMap(range.start, range.end, fieldBuilder)
          )}
      </Grid>
    )
}

const InquiryTermFields = (params) => {
  const [usingRange, setUsingRange] = useState(false)

  if(params.inquiry && !usingRange)
    return <FromInquiry {...params} setUsingRange={setUsingRange}/>
  else
    return <FromRange {...params}/>
  
}

export default InquiryTermFields
