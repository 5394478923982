import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { InquiriesToolbar, InquiriesTable} from './components';
import { QueryResult, ErrorBoundary } from 'components'
import { useQuery } from '@apollo/react-hooks';
import { FETCH_INQUIRIES } from 'constants/queries';
import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const InquiryList = () => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(FETCH_INQUIRIES, {fetchPolicy: 'network-only'});
  const filter = useState('');

  return (
    <div className={classes.root}>
      <ErrorBoundary>
        <QueryResult loading={loading} error={error}>
          <InquiriesToolbar filter={filter} />
          <div className={classes.content}>
            <InquiriesTable inquiries={data?.inquiries} />
          </div>
        </QueryResult>

      </ErrorBoundary>
    </div>
  );
  return <div></div>;
};

export default InquiryList;
