import React, { useContext } from 'react'
import moment from 'moment'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

import { ManagerContext } from './CarrierManager'
import { ScrollContexts } from './ManagerReducer'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
}))

const RouteItem = ({ item }) => {
  return (
    <div className={clsx('cm__item')}>
      <Typography>{item.product.name}</Typography>
      <Typography>{item.routeCode}</Typography>
      <Typography>{item.miles} Miles</Typography>
      <div style={{ display: 'flex' }}>
        <Typography>{item.originRegion.name}</Typography>
        <Typography>-</Typography>
        <Typography>{item.destinationRegion.name}</Typography>
      </div>
    </div>
  )
}

const FreightRateItem = ({ item }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const columns = [
    { value: item.rate },
    { value: moment(item.startDate).format('D/M/YYYY') },
    { value: moment(item.endDate).format('D/M/YYYY') },
  ]
  return (
    <div className={clsx('cm__item', classes.item)}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography style={{ marginRight: '0.5em' }}>{item.rate}</Typography>
        <Typography>{t(item.unitType)}</Typography>
        {item.document?.link && (
          <a href={item.document.link}>
            <Typography style={{ marginLeft: '0.5em' }}>Document</Typography>
          </a>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <Typography>{moment(item.startDate).format('D/M/YYYY')}</Typography>
        <Typography style={{ margin: '0 0.5em' }}>-</Typography>
        <Typography>{moment(item.endDate).format('D/M/YYYY')}</Typography>
      </div>
    </div>
  )
}

const List = ({ items, Component }) => {

  return (
    <div className='cm__body'>
      {items.map(i => <Component item={i} />)}
    </div>
  )

}

const ManagerBody = () => {
  const context = useContext(ManagerContext)

  if (!context.selectedCarrier) {
    return <div>Incomplete Context</div>
  } else if (context.scrollContext == ScrollContexts.CARRIERS) {
    return <List items={context.selectedCarrier.routes} Component={RouteItem} />
  } else if (context.scrollContext == ScrollContexts.ROUTES) {
    return <List items={context.selectedRoute ? context.selectedRoute.freightRates : []} Component={FreightRateItem} />
  } else {
    return <div>Invalid Context</div>
  }

}

export default ManagerBody
