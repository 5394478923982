import React, { useContext } from 'react'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import { useMutation } from "@apollo/react-hooks"
import { TextField,
   Card,
   Grid,
   CardActions,
   Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { RoutesContext } from '../RouteManager'
import { updateCache } from 'business'
import { Select } from 'components'
import { UPDATE_CARRIER, CREATE_CARRIER, FETCH_ROUTE_MANAGER_DATA } from 'constants/queries'

const CarrierTypeOptions = [
  {label: 'railroad', value: 'railroad'}
]

export const carrierDirtyFields = ['carrier.name', 'carrier.carrierType']

export const getCarrierValues = (data) => ({
  name: data.carrier.name,
  carrierType: data.carrier.carrierType
})

export const CarrierFields = () => {
  const { t } = useTranslation()

  const methods = useFormContext()
  const { errors, register, carrier, classes } = methods;
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField
            fullWidth
            className={clsx('route__form-control')}
            InputProps={{className: classes.Input}}
            InputLabelProps={{className: classes.Label}}
            style={{margin: '0'}}
            defaultValue={carrier?.name}
            label={t('name')}
            margin="dense"
            name="carrier.name"
            required
            className={clsx("rm__text", classes.FormControl)} variant="outlined"
            inputRef={register({ required: true })}
            error={errors.carrier?.name}
            helperText={errors.carrier?.name && t(errors.carrier?.name)}
          />
      </Grid>
      <Grid item xs={12}>
        <Select 
          name='carrier.carrierType'
          TextFieldProps={{className: clsx('route__form-control', 'route__form--hidden')}}
          subject={carrier}
          required={true}
          control={methods.control}
          label={t('type')}
          options={CarrierTypeOptions.map(o => ({...o, label: t(o.label)}))}
          sv={methods.setValue}
          errors={errors}
          getDefaultMatcher={(option, subject) => subject.carrierType == option}
        />
      </Grid>
    </React.Fragment>
  )

}

const CarrierForm = (props) => {
    const { classes, carrier, onCompleted, onError } = props
    const methods = useForm()
    const context = useContext(RoutesContext)
    const isUpdate = !!carrier
    const query = isUpdate ? UPDATE_CARRIER : CREATE_CARRIER
    const [mutate, mutationStatus] = useMutation(query, {
      onCompleted,
      onError,
      update: isUpdate ? undefined : context.handleUpdate('carriers', r => r.data.carrier.create)
    })



    const onSubmit = (data) => {
      let payload = {variables: {}}
      if(carrier) payload.variables.id = carrier.id

      const carrierAttributes = getCarrierValues(data)
      payload.variables.input = {
        ...carrierAttributes
      }

      mutate(payload)
    }

    return (
      <FormProvider {...methods} classes={classes} carrier={carrier} fieldsEnabled={true}>
        <form
          className={clsx(classes.formRoot, 'route__form')}
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <CarrierFields />
                <Grid item xs={4}>
                  <Button className='route__form--hidden' type="submit" className={"rm__text"} variant="contained" color="primary">Submit</Button>
                </Grid>
            </Grid>
          </form>
      </FormProvider>
    )
}

export default CarrierForm
