import React, { useState, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useForm, Controller } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  FormControl,
  TextField,
  Typography,
  InputLabel,
  FormHelperText,
  Paper,
  Chip
} from '@material-ui/core';
import { Can } from 'lib/can';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom'

import { StoreContext } from 'App';
import { LoadingBar, Select } from 'components';
import { CREATE_INQUIRY, UPDATE_INQUIRY, FETCH_INQUIRY_CREATION_DATA } from 'constants/queries'
import { Loader, Slider } from 'components'
import {
  IndexFields,
  FixedPriceFields,
  InquirySpotFields,
  InquiryTermFields,
  FormOptions,
} from './components'
import { safeStringify } from 'lib/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
  },
  errorBtn: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  validBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

const QTFields = (params) => {
  const { t } = useTranslation()

  if(params.type == 'spot')
    return <Grid container spacing={3}>
      <InquirySpotFields {...params}/>
    </Grid>
  else
    return <Grid container spacing={3}>
      <InquiryTermFields {...params}/>
    </Grid>
}

const PTFields = (params) => {
  const { t } = useTranslation()
  const classes = useStyles();

  if(params.type == 'fixed')
    return <FixedPriceFields {...params} classes={classes}/>
  else
    return <IndexFields {...params} classes={classes}/>
}

const InquiryForm = props => {
  const { className, inquiry, onCompleted, fieldsEnabled, ...rest } = props;
  const classes = useStyles();
  const context = useContext(StoreContext);
  const { data } = useQuery(FETCH_INQUIRY_CREATION_DATA)
  const history = useHistory()

  const { t } = useTranslation();
  const form = useForm();
  const {
    register,
    unregister,
    errors,
    handleSubmit,
    setValue,
    control,
    watch,
    formState,
  } = form;
  const inquiryType = watch('inquiryType', FormOptions.inquiryTypeOptions[1])
  const priceType = watch('priceType', FormOptions.priceTypeOptions[1].value)
  const productCategory = watch('productCategory')
  const product = watch('product')
  const watchAll = watch();

  const titles = ['general','product', 'term', 'pricing']


  const [mutate, mutationStatus] = useMutation(inquiry ? UPDATE_INQUIRY : CREATE_INQUIRY, { onCompleted })


  const buildTermMonthAttributes = (term) => {
    let termMonthAttributes = []
    Object.keys(term).forEach(yearKey => {
      const year = parseInt(yearKey.replace('year-', ''))
      const values = Object.keys(term[yearKey]).map( monthKey => (
        {
          year,
          month: parseInt(monthKey.replace('month-', '')) + 1,
          value: parseInt(term[yearKey][monthKey].value)
        }
      ))
      termMonthAttributes.push(values)
    })
    return termMonthAttributes.flat()
  }

  const termAlreadyExists = (term) => Object.keys(term)[0].match(/id/)

  const buildUpdatedTerm = (term) => {
    
    const termMonthAttributes = Object.keys(term).map(key => ({ id: key.substr(3), value: parseInt(term[key])}))
    return termMonthAttributes
  }

  const getQTFields = (data) => {
    let result = {}

    if(data.inquiryType == 'term') {
      result.termMonthsAttributes = termAlreadyExists(data.term) ? buildUpdatedTerm(data.term) : buildTermMonthAttributes(data.term)
    }
    else if (data.inquiryType == 'spot') {
      result = {
        month: data.month
      }
    }
    return result
  }

  const getPTFields = (data) => {
    let result = {}
    if(data.priceType == 'fixed') {
      result = {
        price: data.price
      }
    }
    else if(data.priceType == 'variable') {
      result = {
        offset: data.offset,
        priceIndexId: data.priceIndexId.id,
        priceIndexType: data.priceIndexType.value
      }
    }
    return result
  }

  const onSubmit = ({product, productCategory, productUnit, ...data}) => {
    console.log(data)

    const QuantityTypeFields = getQTFields(data)
    const PriceTypeFields = getPTFields(data)
    const dirtyFields = [
       'term',
       'priceIndexId',
       'priceIndexType',
       'offset',
       'price',
       'termMonthsAttributes',
       'startMonth',
       'endMonth',
       'month'
     ]
    dirtyFields.forEach(key => delete data[key])

    mutate({variables: {
      ...inquiry ? {id: inquiry.id} : {},
      input: {
        ...data,
        ...PriceTypeFields,
        ...QuantityTypeFields,
        productId: product.id,
        productUnitId: productUnit.id,
        userId: context.state.session.id,
        termsOfPaymentDays: parseInt(data.termsOfPaymentDays),
        spotUnits: parseInt(data.spotUnits),
        spotMonth: parseInt(data.spotMonth)
      }
    }})
  }
    
  useEffect(() => {
    register('productCategory')
  }, [])

  useEffect(() => {
    if(!data) return
    setValue('productCategory', data.productCategories.first)
  }, [data])


  const [idx, setIndex] = useState(0)
  if(data) {
    const productCategories = data.productCategories
    const Destinations = data.destinations
    if(inquiry && !productCategory) {
      setTimeout(() => {
        setValue('productCategory', data.productCategories.find(e => e.id == (product ? product.productCategoryId : inquiry ? inquiry.product.productCategoryId : e.id)))
      }, 1000)
      return <Loader/>
    }

    const next = nxt => nxt()
    const prev = prv => prv()


    const Slides = [
    ( <Grid className='slide__grid-override' container spacing={1}>
          <Grid item md={6} xs={12}>
            <Select 
              name='destinationId'
              label={t('destination')}
              subject={inquiry}
              control={control}
              options={Destinations.map(d => ({label: d.city, value: d.id}))}
              fieldsDisabled={false}
              sv={setValue}
              errors={errors}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select 
              name='incoterm'
              label={t('incoterm')}
              subject={inquiry}
              options={FormOptions.incotermOptions}
              fieldsDisabled={false}
              control={control}
              sv={setValue}
              errors={errors}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              id="datetime-local"
              fullWidth
              margin="dense"
              name="expirationDate"
              defaultValue={inquiry && inquiry.expirationDate}
              label={t('expiration_date')}
              type="date"
              variant="outlined"
              inputRef={register({ required: true })}
              error={errors.expirationDate}
              helperText={
                errors.expirationDate && t(errors.expirationDate.type)
              }
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              error={errors.receptionType}
              className={classes.formControl}>
              <Controller
                name="receptionType"
                control={control}
                rules={{ required: true }}
                defaultValue={inquiry && FormOptions.receptionTypeOptions.find(e => e.value == inquiry.receptionType).value}
                as={({ value, onChange }) => (
                  <Autocomplete
                    id="reception-type"
                    value={value && value.label ? value : FormOptions.receptionTypeOptions.find( e => e.value == value)}
                    onChange={(_, {value}) => setValue('receptionType', value) }
                    options={FormOptions.receptionTypeOptions}
                    getOptionLabel={option => t(option.label)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={errors.receptionType}
                        label={t('reception_type')}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {errors.receptionType && t(errors.receptionType.type)}
              </FormHelperText>
            </FormControl>
          </Grid>
          </Grid>
          ),
(
        <Grid  className='slide__grid-override' container spacing={1}>
          <Grid item md={4} xs={12}>
            <FormControl
              error={errors.productCategory}
              className={classes.productCategory}
              variant='outlined'
              fullWidth
            >
              <Controller
                name='productCategory'
                defaultValue={inquiry && productCategories.find(pc => pc.id == inquiry.product.productCategoryId)}
                control={control}
                rules={{required: true}}
                as={({value, onChange}) => (
                  <Autocomplete
                    id='product-category-select'
                    value={value}
                    onChange={(_, value) => setValue( 'productCategory', value)}
                    options={data.productCategories}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={errors.productCategory}
                        label={t('category')}
                        variant='outlined'
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>{errors.productCategory && t(errors.productCategory.type)}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              error={errors.product}
              className={classes.formControl}>
              <Controller
                name="product"
                defaultValue={inquiry && productCategory && productCategory.products.find(p => p.id == inquiry.product.id)}
                control={control}
                rules={{ required: true }}
                as={({ value, onChange }) => (
                  <Autocomplete
                    id="product-id-select"
                    value={value}
                    onChange={(_, value) => setValue( 'product', value) }
                    options={productCategory ? productCategory.products : []}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={errors.product}
                        label={t('product')}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {errors.product && t(errors.product.type)}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              error={errors.productUnit}
              className={classes.formControl}>
              <Controller
                name="productUnit"
                defaultValue={inquiry && inquiry.product.productUnits.find(p => p.id == inquiry.productUnit.id)}
                control={control}
                rules={{ required: true }}
                as={({ value, onChange }) => (
                  <Autocomplete
                    id="productUnit-id-select"
                    value={value}
                    onChange={(_, value) => setValue( 'productUnit', value) }
                    options={product ? product.productUnits : []}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={errors.productUnit}
                        label={t('productUnit')}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {errors.productUnit && t(errors.productUnit.type)}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        ), (
(
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <Select 
              name='inquiryType'
              label={t('inquiry_type')}
              subject={inquiry}
              control={control}
              options={FormOptions.inquiryTypeOptions}
              sv={setValue}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper variant='outlined' className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <QTFields {...{type: inquiryType, inquiry, register, errors, setValue, unregister, watch, ...props}}/>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{marginBottom: '1.5em'}}>
            <TextField
              fullWidth
              multiline
              defaultValue={inquiry && inquiry.comments}
              rows={5}
              // helperText={t("please_first_name")}
              label={t("comments")}
              margin="dense"
              name="comments"
              variant="outlined"
              inputRef={register({ required: true })}
              error={errors.comments}
              helperText={errors.comments && t(errors.comments.type)}
            />
          </Grid>
        </Grid>
      )
        ),
        (<Grid className='slide__grid-override' container spacing={1}>
          <Grid item xs={6}>
            <Select 
              name='priceType'
              label={t('pricing')}
              subject={inquiry}
              options={FormOptions.priceTypeOptions}
              fieldsDisabled={false}
              control={control}
              sv={setValue}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <PTFields {...{type: priceType, inquiry, product, register, errors, setValue, control, priceIndices: data.priceIndices, ...props}}/>
          <Grid item xs={12}></Grid>
          <Grid item md={4} xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              error={errors.paymentType}
              className={classes.formControl}>
              <Controller
                name="paymentType"
                control={control}
                rules={{ required: true }}
                defaultValue={inquiry && FormOptions.paymentTypeOptions.find(e => e.value == inquiry.paymentType).value}
                as={({ value, onChange }) => (
                  <Autocomplete
                    id="payment-type"
                    value={value && value.label ? value : FormOptions.paymentTypeOptions.find(e => e.value == value)}
                    onChange={(_, {value}) => setValue('paymentType', value) }
                    options={FormOptions.paymentTypeOptions}
                    getOptionLabel={option => t(option.label)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={errors.paymentType}
                        label={t('payment_type')}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
              <FormHelperText>
                {errors.paymentType && t(errors.paymentType.type)}
              </FormHelperText>
            </FormControl>
          </Grid>
                
                <Grid item md={4} xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={errors.termsOfPayment}
                    className={classes.formControl}>
                    <Controller
                      name="termsOfPayment"
                      control={control}
                      defaultValue={inquiry && FormOptions.termsOfPaymentOptions.find(e => e.value == inquiry.termsOfPayment).value}
                      rules={{ required: true }}
                      as={({ value, onChange }) => (
                        <Autocomplete
                          id="price-type"
                          value={value && value.label ? value : FormOptions.termsOfPaymentOptions.find(e => e.value == value)}
                          onChange={(_, {value}) => setValue( 'termsOfPayment', value) }
                          options={FormOptions.termsOfPaymentOptions}
                          getOptionLabel={option => t(option.label)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={errors.termsOfPayment}
                              label={t('terms-of-payment')}
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText>
                      {errors.termsOfPayment && t(errors.termsOfPayment.type)}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    defaultValue={inquiry && inquiry.termsOfPaymentDays}
                    label={t("terms-of-payment-days")}
                    margin="dense"
                    name="termsOfPaymentDays"
                    required
                    variant="outlined"
                    type='number'
                    error={errors.termsOfPaymentDays}
                    inputRef={register({ required: true })}
                    helperText={errors.termsOfPaymentDays && t(errors.termsOfPaymentDays.type)}
                  />
                </Grid>
        </Grid>
        )
    ]


    const slideCount = Slides.length
    
    const nextSlide = () => setIndex((idx+1)%slideCount)
    const prevSlide = () => {
      const nextIndex = (idx-1);
      setIndex( nextIndex < 0 ? slideCount - 1 : nextIndex)
    }
    return (
      <Card {...rest} className={clsx(className)}>
        <form autoComplete="off" noValidate onSubmit={(e) => {
          handleSubmit(onSubmit)(e)
          e.preventDefault()
        }}>
          <CardHeader title={t("inquiry")} subheader={t('complete_the_following_parts_of_x', {x: t('inquiry')})}/>
          <React.Fragment>
              {titles.map((title, index) => (
                <Chip
                  onClick={() => setIndex(index)}
                  style={{margin: '1em 0.25em 1em 1em', cursor: 'pointer'}}
                  label={t(title)}
                  color={index == idx ? 'primary' : 'disabled'}/>
              ))}
          </React.Fragment>

          <Divider />
          <CardContent>
            
            <Slider 
              CardHeader={{title: t('inquiry')}}
              slides={Slides}
              index={idx}
            />

          </CardContent>
          <Divider />
          <CardActions style={{justifyContent: 'space-between'}}>
          <div>
            {fieldsEnabled && (
              <Button className={classes.validBtn} variant='contained' type='submit' disabled={mutationStatus.loading} style={{marginRight: '1em'}}>
                  {`${inquiry ? t('update') : t('create')} ${t('inquiry')}`}
              </Button>
            )}
            {inquiry && (
              <Button color='primary' variant='contained' disabled={mutationStatus.loading} onClick={() => history.push('/contracts/create/' + inquiry.id)}>
                  {`${t('create')} ${t('contract')}`}
              </Button>
            )}
          </div>
          <div>
            <Button onClick={() => prevSlide()}>{t('prev')}</Button>
            <Button onClick={() => nextSlide()}>{t('next')}</Button>
          </div>
          </CardActions>
        </form>
        {mutationStatus.loading && <LoadingBar />}
      </Card>
    );
  }
  else
    return <Loader/>
};

export default InquiryForm;
