import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles';
import {
Card,
CardHeader,
CardContent,
CardActions,
Grid,
Chip,
Typography,
} from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import MovementCard from './MovementCard'
import { FETCH_MOVEMENTS } from 'constants/queries'
import { List } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));


const MovementListContainer = ({movements}) => {
  const classes = useStyles()
  const { t } = useTranslation()
const headCells = [{
  id: 'id',
  numeric: false,
  label: 'ID',
  numeric: false,
  searchable: true,
  disablePadding: true,
  fn: p => p.id,
  display: p => '#' + p.id
}, 
{
  id: 'containerId',
  numeric: false,
  label: t('containerId'),
  numeric: false,
  searchable: true,
  disablePadding: true,
  fn: p => p.containerId,
  display: p => p.containerId
},

{
  id: 'loadingDate',
  numeric: false,
  label: t('loadingDate'),
  numeric: false,
  searchable: true,
  disablePadding: true,
  fn: p => p.loadingDate.toString(),
  display: p => p.loadingDate,
},
{
  id: 'netVolume',
  numeric: false,
  label: t('netVolume'),
  numeric: false,
  searchable: true,
  disablePadding: true,
  fn: p => p.netVolume.toString(),
  display: p => p.netVolume,
},
{
      id: 'origin',
      numeric: false,
      label: t('origin'),
      numeric: false,
      searchable: true,
      disablePadding: true,
      fn: p => p.origin.city,
      display: p => p.origin.city,
}, {
  id: 'bol',
  numeric: false,
  label: 'BOL',
  numeric: false,
  searchable: false,
  disablePadding: true,
  fn: p => 'bol',
  display: p => ( p.bol ? <a href={p.bol.link}>BOL</a> : null)
}

]
return <List 
  label={t('movements')}
  subject={movements}
  headCells={headCells}
/>
  // return (
  // <div className={classes.root}>
  //   <Grid container spacing={3}>
  //       {movements.map(m => 
  //       <Grid item xs={12} md={6}>
  //         <MovementCard movement={m}/>
  //       </Grid>
  //         )}
  //   </Grid>
  // </div>
  // )
}

const MovementList = () => {
  const history = useHistory();
  const { t } = useTranslation()
  const { contractId } = useParams()
  const { data } = useQuery(FETCH_MOVEMENTS, {
    variables: {
      query: {contractId: contractId}
    }
  })

  if(data)
    return (
    <div>
      <div style={{margin: '0 2em -4em'}}>
        <Typography variant='title' style={{fontWeight: 'bold'}}>{`${t('movementsFor')} ${data.movements[0].contract.canonicName}`}</Typography>
      </div>
      <MovementListContainer movements={data.movements}/>
    </div>
    )

  return <div></div>
}

export default MovementList
