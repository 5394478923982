export const RouteUnitTypes = [
    {
		value: 0,
		label: 'railcar_usd'
	},
    {
		value: 1,
		label: 'hopper_usd'
	},
]
