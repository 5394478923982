import gsap from 'gsap'

const horizontalScroll = (idx) => {
  gsap.to(`.cm__array`, {xPercent: `-${105.5*idx}`, duration: 0.15} )
}

export const Actions = {
  SET: 'set',
  SCROLL_UP: 'scroll_up',
  SCROLL_DOWN: 'scroll_down',
  SCROLL_RIGHT: 'scroll_right',
  SCROLL_LEFT: 'scroll_left',
}


export const ScrollContexts = {
  CARRIERS: 'carriers',
  ROUTES: 'routes',
}
export const InitialState = {
  carriers: null,
  products: null,
  selectedCarrier: null,
  selectedCarrierIndex: null,
  selectedRoute: null,
  selectedRouteIndex: null,
  scrollContext: ScrollContexts.CARRIERS,
}


const set = (prevState, action) => {
  const newState = {
    ...prevState,
    ... action.payload.idx != undefined ? {[action.payload.name + 'Index']: action.payload.idx} : {},
    [action.payload.name]: action.payload.value,
  }
  return newState
}

const getNextIndexDown = (prevIndex, max)  => {
  if(prevIndex === undefined) {
    return 0
  } else {
    let idx = prevIndex + 1
    return idx > max ? max : idx
  }
}


const getNextIndexUp = (prevIndex, max)  => {
  let idx = null

  if(prevIndex === undefined) {
    return 0
  } else {
    idx = prevIndex - 1
    return idx < 0 ? 0 : idx
  }
}

export const ManagerReducer = (prevState, action) => {

  switch(action.type) {
    case Actions.SET: {
      return set(prevState, action)
    }
    case Actions.SCROLL_DOWN: {
      let name = null
      let idx = null
      let value = null

      if(prevState.scrollContext == ScrollContexts.CARRIERS) {
        idx = getNextIndexDown(prevState.selectedCarrierIndex, prevState.carriers.length - 1)
        value = prevState.carriers[idx]
        name = 'selectedCarrier'
      } else {
        idx = getNextIndexDown(prevState.selectedRouteIndex, prevState.selectedCarrier.routes.length - 1)
         value = prevState.selectedCarrier.routes[idx]
         name = 'selectedRoute'
      }

      return set(prevState, {payload: {name, value, idx}})
    }

    case Actions.SCROLL_UP: {
      let name = null
      let idx = null
      let value = null
      if(prevState.scrollContext == ScrollContexts.CARRIERS) {
        idx = getNextIndexUp(prevState.selectedCarrierIndex, prevState.carriers.length - 1)
        value = prevState.carriers[idx]
        name = 'selectedCarrier'
      } else {

        idx = getNextIndexUp(prevState.selectedRouteIndex, prevState.selectedCarrier.routes.length - 1)
        value = prevState.selectedCarrier.routes[idx]
        name = 'selectedRoute'
      }

      return set(prevState, {payload: {name, value, idx}})
      
    }
    case Actions.SCROLL_RIGHT: {
      horizontalScroll(1)
      let newState =  set(prevState, {payload: {name: 'scrollContext', value: ScrollContexts.ROUTES}})
      return set(newState, {payload: {name: 'selectedRoute', value: newState.selectedCarrier.routes[0], idx: 0}})
    }

    case Actions.SCROLL_LEFT: {
      horizontalScroll(0)
      return set(prevState, {payload: {name: 'scrollContext', value: ScrollContexts.CARRIERS}})
    }
  }
}

