export const updateCache = ({query, resultReducer, dataReducer}) => (cache, result) => {
  const oldData = cache.readQuery({query})
  const newData = resultReducer(result)
  const data = dataReducer(oldData, newData)
  cache.writeQuery({ query, data  })
}

//
// const updateCache = (isUpdate) => (cache, result) => {
//   const oldData = cache.readQuery({query: FETCH_ROUTE_MANAGER_DATA})
//   const newCarrier = isUpdate ? result.data.carrier.update : result.data.carrier.create
//   const newCarriers = [...oldData.carriers, newCarrier]
//   cache.writeQuery({
//     query: FETCH_ROUTE_MANAGER_DATA,
//     data: {
//       ...oldData,
//       carriers: newCarriers
//     }
//
//   })
// }
//
//
