import {CostCalculator, UnitConverter} from 'business'
import { UNIT_TYPES } from './Costs/UnitConverter';
import PathFinder from './PathFinder';

const DEFAULT_UNIT = UNIT_TYPES.GALLONS;

class RouteClass {
    constructor({id,
     productId,
     destinationRegion,
     originRegion,
     miles,
     freightRate,
     isFreightRateUpdated,
     unitType = DEFAULT_UNIT,
     carrier
    }) {
        this.id = id;
        this.productId = productId;
        this.destinationRegion = destinationRegion;
        this.originRegion = originRegion;
        this.miles = miles;
        this.fsc = carrier.fsc;
        this.freightRate = freightRate;
        this.carrier = carrier
        this.unitType = unitType; 
        this.isFreightRateUpdated = isFreightRateUpdated;

        const parameterList = [productId, id, destinationRegion, originRegion, miles, freightRate, carrier];
        if(this.freightRate == null) {
            throw {type: 'RouteClass', message: `Route ${id} is has no freigth rate.\n Carrier: ${this.carrier.name}, ${this.originRegion.name} - ${this.destinationRegion.name}`}
        }
        if(parameterList.some(p => p == null || p == undefined)) {
            throw {type: 'RouteClass', message: `Parameters [${Object.keys(this).filter(k => !this[k]).join(',')}] must not be null`}
        }
        this.totalCost = CostCalculator.calculateFreightCost({
            fsc: this.fsc,
            freightRate: this.freightRate,
            miles: this.miles,
            fromUnit: this.unitType,
            toUnit: DEFAULT_UNIT
        })
        this.isOutdated = !(this.carrier.isRouteRateUpdated && this.isFreightRateUpdated)
        this.isFollowedBy = (route) => PathFinder.pathsConnect(this, route)

    }
}

export default RouteClass
