import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks';
import { FETCH_CONTRACTS_FULL } from 'constants/queries';

import ContractForm from './ContractForm.js'
import { Loader } from 'components'
import { ContractsTable } from './components';
import { StoreContext } from 'App'
import { FETCH_INQUIRIES } from 'constants/queries'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Contract = props => {
  const history = useHistory()
  const classes = useStyles();
  const context = useContext(StoreContext)

  const { t } = useTranslation()
  const { contractId } = useParams()
  const { className, ...rest } = props;

  const { data } = useQuery(FETCH_CONTRACTS_FULL, {
    variables: {
      query: {id: contractId}
    }
  })


  if(data) {
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ContractForm
              fieldsEnabled={true}
              contract={data.contracts[0]}
              onCompleted={ () => {
                context.state.showAlert({
                  severity: 'success', 
                  message: `${t('contract')} ${t('updated')}`
                });
                setTimeout(() => history.push('/inquiries/view/' + data.contracts[0].inquiryId), 1000)
              }}
              onError={ (e) => {
                context.state.showAlert({
                  severity: 'error', 
                  message: e.message
                });
              }}
            />
          </Grid>
          <Grid item lg={4} md={6} xl={4} xs={12} />
        </Grid>
      </div>
    );
  } else {
    return <Loader/>
  }

};

export default Contract;
