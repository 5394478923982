
import { ValueClass } from 'business/ValueClass'
import { RouteClass } from 'business'
import { UNIT_TYPES } from 'business/Costs/UnitConverter'

class PathClass {
    constructor(arrayOfRouteClass) {
        if(!arrayOfRouteClass.every(r => r instanceof RouteClass))
            throw {location:  'PathClass.constructor', message: 'All members of array must be instance of RouteClass'}
        let totalDistance = 0;
        let totalCost = new ValueClass({value: 0, unitType: UNIT_TYPES.GALLONS });
        let carriers = new Set();
        for(let i = 0; i < arrayOfRouteClass.length; i++) {
            totalDistance += arrayOfRouteClass[i].miles;
            totalCost = totalCost.plus(arrayOfRouteClass[i].totalCost)
            carriers.add(arrayOfRouteClass[i].carrier);
        }
        this.routes = arrayOfRouteClass;
        this.originRegion = arrayOfRouteClass[0].originRegion;
        this.destinationRegion = arrayOfRouteClass[arrayOfRouteClass.length - 1].destinationRegion;
        this.totalDistance = totalDistance;
        this.unitType = arrayOfRouteClass[0].unitType
        this.totalCost = totalCost
        this.isOutdated = arrayOfRouteClass.some(r => r.isOutdated)
        this.carriers = Array.from(carriers);

        if(Object.keys(arrayOfRouteClass.reduce((acc, route) => {
            acc[route.unitType] = 1;
            return acc;
        }, {})).length > 1) {
            throw {type: 'PathClass', message: 'All routes must have the same unit type'}
        }
    }

    isCheaperThan(competingPath) {
        if(competingPath.unitType != this.unitType)
          throw {type: 'PathClass', location: 'findAllPathsFrom', message: 'Paths have different unitTypes', paths: [competingPath, this]}
        return this.totalCost.minus(competingPath.totalCost);
    }
}

export default PathClass
