const FormOptions = {
  inquiryTypeOptions: [
    {
      value: 'spot',
      label: "spot"
    }, {
      value: 'term',
      label: "term"
    }
  ],

  priceTypeOptions: [
    {
      value: 'fixed',
      label: "fixed"
    }, {
      value: 'variable',
      label: "index"
    }
  ],

  termsOfPaymentOptions: [
    {
      value: 'prepaid',
      label: "prepaid"
    }, {
      value: 'credit',
      label: "credit"
    }
  ],

  receptionTypeOptions: [
    {
      value: 'unrestricted',
      label: "unrestricted"
    }, {
      value: 'traders',
      label: "traders"
    }, {
      value: 'producer',
      label: "producer"
    }
  ],

  paymentTypeOptions: [
    {
      value: 'on_delivery',
      label: "on_delivery"
    }, {
      value: 'on_load',
      label: "on_load"
    }
  ],
  incotermOptions: [
    {
      value: 'FOB',
      label: 'FOB'
    },{
      value: 'CIF',
      label: 'CIF'
    }, {
      value: 'CFR',
      label: 'CFR'
    }, {
      value: 'DDP',
      label: 'DDP'
    }
  ],
  priceIndexTypeOptions: [
    {
      label: 'avg_monthly',
      value: 'avg_monthly'
    }, {
      label: 'avg_daily',
      value: 'avg_daily'
    }
  ]

}

export default FormOptions
