import { Actions } from './ManagerReducer'
const keyboardNavigation = (dispatch) => {
    return document.addEventListener('keydown', (e) => {
      const isShift = !!window.event.shiftKey; 
      if(!isShift) return
      if(e.key == 'ArrowRight') {
        e.preventDefault()
        dispatch({type: Actions.SCROLL_RIGHT})
        // horizontalScroll(1)
      }


      if(e.key == 'ArrowLeft') {
        e.preventDefault()
        dispatch({type: Actions.SCROLL_LEFT})
         // horizontalScroll(0)
      }


      if(e.key == 'ArrowDown') {
        e.preventDefault()
        dispatch({type: Actions.SCROLL_DOWN})
      }


      if(e.key == 'ArrowUp') {
        e.preventDefault()
        dispatch({type: Actions.SCROLL_UP})
      }
    })
}

export default keyboardNavigation
