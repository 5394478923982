
import React, { useState, useEffect } from 'react'
import { QueryResult, ErrorObject, ErrorBoundary } from 'components'
import { Button, makeStyles, Grid, Typography, Tooltip, Chip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import clsx from 'clsx' 

import {Validator} from 'business'
import { RackSolutionFinder, ProductPriceClass } from 'business'
import { ProductPrice } from './ProductPrice'
import { UnitConverter } from 'business'
import ProductPricePathPair from 'business/ProductPricePathPair'
import { ValueClass } from 'business/ValueClass'

const useStyles = makeStyles(theme => ({
    clientCard: {
        cursor: 'pointer !important',
        padding: '1em',
        transition: '0.3s all',
        '&:hover': {
            boxShadow: '0 3px 6px #000a'
        }
    },
    clientText: {
        fontWeight: '300',
        letterSpacing: '5px',
        textTransform: 'uppercase',
        marginBottom: '0.5em',
    },
    regionText: {
        fontWeight: 'bold',
        marginBottom: '0.25em',
    },
    productText: {
        color: props => props.valid ? theme.palette.text.primary : theme.palette.error.main
    }
}))


const IndirectProductPrice = ({clientId, pricePathPair, revealTooltip, hideTooltip}) => {
    const classes = useStyles({valid: !pricePathPair.isOutdated});
    const { t } = useTranslation();

    const onMouseOver = () => {
        revealTooltip({clientId, child: <TooltipIndirectProductPrice pricePathPair={pricePathPair}/>})
    }

    const onMouseLeave = () => {
        // hideTooltip()
    }

    return (
        <div classname={classes.productPrice} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            <Typography className={classes.productText}>
                {pricePathPair.product.name} - ${pricePathPair.finalCost.value.toFixed(2)} - {t(pricePathPair.finalCost.unitType)} <Chip style={{margin: '0 1em'}} label={t('indirect')}/>
            </Typography>
        </div>
    )

}

const OriginRegionTip = ({pricePathPair}) => {
    const { t } = useTranslation()
    const classes = useStyles({valid: !pricePathPair.productPrice.isOutdated});
    const adder = pricePathPair.productPrice.varAdder.toFixed(2)
    const startPrice = `${pricePathPair.productPrice.varPriceIndex.name} ${adder >= 0 ? `+ ${adder}` : `${adder}`} ${t(pricePathPair.productPrice.unitType)}`

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography style={{marginRight: '1em'}}>Start: {pricePathPair.path.originRegion.name} </Typography>
            <Chip label={`${pricePathPair.productPrice.value.value} ${t(pricePathPair.productPrice.value.unitType)}`}/>
        </div>
    )
}

const RegionTip = ({route, unitType}) => {
    const classes = useStyles({valid: !route.isOutdated});
    const {t} = useTranslation();
    if(route) {
        const costInIndexUnits = UnitConverter.convertValueClass({
            value: route.totalCost,
            to: unitType
        })

        return <div>
            <Typography style={{marginTop: '0.5em'}}>
                Route to {route.destinationRegion.name} ({route.carrier.name}):  <Chip label={`${route.totalCost.value.toFixed(2)} ${t(route.totalCost.unitType)}`}/> 
            </Typography>
        </div>
    }
    return 
}


const TooltipIndirectProductPrice = ({pricePathPair}) => {
    const { t } = useTranslation()
    const classes = useStyles({valid: !pricePathPair.isOutdated});
    // if(pricePathPair.isOutdated)

    const validityErrors = Validator.getValidityErrors(pricePathPair)
    const priceInIndexUnits = UnitConverter.convertValueClass({value: pricePathPair.finalCost,
        to: pricePathPair.productPrice.varPriceIndex.unitType}) 
    const priceDifference = priceInIndexUnits.minus(new ValueClass({value: pricePathPair.productPrice.varIndexLatestValue.value, unitType: pricePathPair.productPrice.varPriceIndex.unitType}))

    const finalPriceInRelationToIndex = `${pricePathPair.productPrice.varPriceIndex.name} + ${(priceDifference.value).toFixed(2)}`

    return (
        <div className={classes.tooltipCard}> 
            {validityErrors.map(e => (
                <Typography variant='h5' style={{color: 'red'}}>
                    {t(e.attribute)} - {t(e.type)}
                </Typography>
            ))}
            <Typography variant='h4'>
                Price Breakdown
            </Typography>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <OriginRegionTip pricePathPair={pricePathPair}/>
                {pricePathPair.path.routes.map(r => (
                    <RegionTip route={r} unitType={pricePathPair.productPrice.unitType}/>
                ))}

                <div style={{marginTop: '1em'}}>
                    <Typography variant='h5'>
                        Final Price: {pricePathPair.finalCost.value.toFixed(2)} {t(pricePathPair.finalCost.unitType)}
                    </Typography>

                    <Typography variant='h5'>
                        ( {finalPriceInRelationToIndex} )
                    </Typography>
                </div>
            </div>
        </div>


    )
}

export const ProductPriceClient = ({client, routes, revealTooltip, hideTooltip}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    let regions = client.regions.reduce((acc, region) => {
        acc[region.id] = {...region, productPrices: client.latestProductPrices.filter(pp => pp.regionId == region.id).map(pp => new ProductPriceClass(pp))}
        return acc;
    }, {})

    const createPriceTextFromRegion = region => (
        region.productPrices.map(pp => (
            `${region.name} - ${pp.product.name} - $${(pp.value)?.toFixed(2)} - ${t(pp.unitType)}`
        ))
    )


    const solutionFinder = new RackSolutionFinder();

    const productPricePairsByRegion = Object.keys(regions).reduce((acc, r) => {
        const reg = regions[r]
        acc[reg.id] = client.products.map(prod => {
            return solutionFinder.findOptimalSolutions({
                destination: reg,
                routes,
                productId: prod.id,
                productPrices: client.latestProductPrices
            })
        })
        return acc;
    }, {})




    const copyToClipboard = () => {

        const pricesArray = Object.keys(regions).map(k => createPriceTextFromRegion(regions[k]).join("\r\n")).join('\r\n')
        const str = [moment().format('DD-MM-YYYY'), '-------------------------------', pricesArray].join('\r\n')
        navigator.clipboard.writeText(str)
        console.log(str)
    }


    return (
        <div className={classes.clientCard} onClick={copyToClipboard}>

               <Typography variant='h2' className={classes.clientText}>
                    {client.name}
                </Typography>
            
            {Object.keys(regions).map(k => (
                <>
                    <Typography variant='h3' className={classes.regionText}>{regions[k].name}</Typography>
                    {regions[k].productPrices.map(pp => (
                        <ProductPrice clientId={client.id} productPrice={pp} region={regions[k]} revealTooltip={revealTooltip} hideTooltip={hideTooltip}/>
                    ))}

                    {productPricePairsByRegion[k].map(pairsForRegion => (
                        pairsForRegion.map(ppp => (
                            <IndirectProductPrice pricePathPair={ppp} clientId={client.id} revealTooltip={revealTooltip} hideTooltip={hideTooltip}/>
                        ))
                    )).flat()}
                </>
            ))}
        </div>
        
    )
}
