import { defineAbility } from '@casl/ability';

export default function defineAbilityFor(user) {
  return defineAbility((can, cannot) => {
    if (!user || !user.id) {
      can('visit', '/sign-in');
      return;
    }

    can('view', 'header');
    can('read', 'user', { id: user.id });
    can('update', 'user', { id: user.id });
    can('destroy', 'user', { id: user.id });
    cannot('update', 'user', 'credentials');
    can('view', 'user', 'create');
    can('visit', 'all');
    

    if (user.credentials == 'provider') {
      can('create', 'lot');
      can(['update', 'destroy'], 'lot', { 'provider.id': { $eq: user.id } });
      can('destroy', 'lot', {'lot.user_id': { $eq: user.id}});

      can(['read', 'update'], 'lot', 'credentials', {
        'provider.id': { $eq: user.id }
      });
    }

    if (user.credentials == 'admin') {
      can('manage', 'all');
      cannot('update', 'lot', { 'provider.id': { $ne: user.id } });
      cannot('destroy', 'lot', { 'provider.id': { $ne: user.id } });
  
    }
    cannot('choose for', 'inquiry', { 'inquiry.status': { $ne: 'inactive'}})
    cannot('visit', '/dashboard');
    cannot('visit', '/sign-in');
    cannot('visit', '/settings');
  });
}
