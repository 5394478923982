import React, { useContext, Fragment } from 'react';
import { Typography, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment'

import { numberWithCommas } from 'lib/helpers'
import { RoutesContext } from '../RouteManager.js'

const FreightRateCard = props => {
  const { t } = useTranslation()
  const startDate = moment(props.freightRate.startDate).format('DD/MM/YYYY')
  const endDate = moment(props.freightRate.endDate).format('DD/MM/YYYY')

  return (
    <Fragment>
      <Typography className={"rm__text"} variant='body2'>{props.freightRate.id}</Typography>
      <Typography className={"rm__text"} variant='body2'>{props.freightRate.product.name}</Typography>
      {props.freightRate.document.link && (
        <a href={props.freightRate.document.link}>
          <Typography className={"rm__text"} variant='body2'>Document</Typography>
        </a>
      )}
      <Typography className={"rm__text"} variant='body2'>Rate: ${numberWithCommas(props.freightRate.rate)}</Typography>
      <Typography className={"rm__text"} variant='body2'>{startDate} - {endDate}</Typography>
    </Fragment>
  )
}

export default FreightRateCard
