import React, {useState, useContext, useReducer, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles'
import { useQuery } from '@apollo/react-hooks'
import clsx from 'clsx'
import events from './data.js'
import { Button, Typography, FormControlLabel, Checkbox } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {useForm} from 'react-hook-form'

import { FETCH_CONTRACTS }  from 'constants/queries'
import { Calendar as MonthCalendar } from 'views'
import { MovementForm } from 'views'
import {StoreContext} from 'App'
import { CustomModal } from 'components'

const getMonth = (date) => moment(date).month()

const contractToEvent = (contract) => {

  const compressMonths = (contract) => {
    const months = contract.termMonths.map(tm => {
      return {month: tm.month, expectedUnits: tm.value, movements: [], loadedUnits: 0}
    })
    contract.movements.forEach(mov => {
      const loadingDate = moment(mov.loadingDate)
      const month = months.find(term => term.month == loadingDate.month() + (mov.rollover ? 0 : 1))
      if(!month) return
      month.movements.push(mov)
      month.loadedUnits += 1;
    })
    return months;
  }

  const event = {
    id: contract.id,
    clientId: contract.user.id,
    title: contract.canonicName,
    start: new Date(contract.startDate + ' 06:00:00'),
    end: new Date(contract.endDate + ' 06:00:00'),
    contract: contract,
    subEvents: compressMonths(contract)
  }
  return event;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  keyVal: {
    fontWeight: 'bold',
    '& span': {
      fontWeight: 'normal'
    }
  },
  rollover: {
    color: theme.palette.error.light
  },
  margined: {
    marginRight: '1em'
  }
  
}))

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const Month = (props) => {
  return (
    <span className="month">{props.name}</span>
  )
}

const Block = ({row, col}) => {
  const style = {
    '--grid-row': row,
    '--grid-col': col
  }
  return (
    <div class="day" style={{gridRow: row, gridCol: col}}></div>
  )
}

const BlockList = ({rows}) => {
  let blocks = []
  for(let i = 0; i < rows; i++) {
    for(let j = 0; j < 12; j++) {
        blocks.push(<Block row={i + 2} col={j + 1}/>)
    }
  }
  return blocks
}

const calculateClientRows = (events) => {
  let lastRow = 2;
  events.reduce((map, event) => {
    map[event.id] = {row: lastRow++}
    return map;
  }, {})
}
const clientRows = calculateClientRows(events)

const Calendar = ({events, dispatch, setAsOnlyVisible}) => {
  const { t } = useTranslation()
  const MonthList = months.map(m => <Month name={m}/>)
  const classes = useStyles()
  // const BlockList = days.map(d => <Block/>)

  const Events = events.map((e, idx) => {
    const row = idx + 2;
    const colStart = e.start.getMonth() + 1;
    const colSpan = e.end.getMonth() - e.start.getMonth() + 1;
    e.subEvents = e.subEvents || []

    const subEvents = e.subEvents.map((e, idx) => (
    <React.Fragment>
      <section
        class={clsx("task task--subtask")}
        style={{'--row': row, '--col-start': colStart + idx }}>
        {`${e.loadedUnits}/${e.expectedUnits}`}
          <div className="task__detail" style={{minWidth: '22em'}}>
              {
                e.movements.length > 0 ?
                e.movements.map(m => <Typography style={{display: 'flex', justifyContent: 'space-between'}} className={clsx(classes.keyVal, m.rollover && classes.rollover)} variant='subtitle1'> {moment(m.loadingDate).format('MMM DD, YYYY')}: <span>#{m.containerId}</span> </Typography>) :
                (
                  <Typography variant='subtitle1'>
                      {t('noMovements')}
                  </Typography>
                )
              }
          </div>
      </section>
    </React.Fragment>
    ))


    return (
    <React.Fragment>
      <section
        className={clsx('task', true &&'task--danger')}
        style={{'--row': row, '--col-start': colStart, '--col-span': colSpan, '--bg': '#fdc5d0'}}
        onClick={() => {dispatch({shownDate: e.start, type: 'showDetail'}); setAsOnlyVisible(e.contract)}}>
        {e.title}
        <div className="task__detail">
            <Typography className={classes.keyVal} variant='subtitle1'>
              {t('product')} <span >{e.contract.product.name}</span>
            </Typography>
            <Typography className={classes.keyVal} variant='subtitle1'>
              {t('price')}: <span>{e.contract.canonicPrice}</span>
            </Typography>
            <Typography className={classes.keyVal} variant='subtitle1'>
              {t('destination')}: <span>{t( e.contract.destination.city )}</span>
            </Typography>
            <Typography className={classes.keyVal} variant='subtitle1'>
              {t('carsPerMonth')}: <span>{t( e.contract.termMonths[0].value )}</span>
            </Typography>
            <p></p>
        </div>
      </section>
        {subEvents}
    </React.Fragment>
    )
  })
  
  return (
    <div class="calendar-container">
      <div className="calendar-header">
          <h1>2021</h1>
      </div>
      <div className="calendar">
          {MonthList}
          <BlockList rows={events.length > 8 ? events.length : 8}/>
          {Events}
      </div>
    </div>
  )
}

const reducer = (state, action) => {
  switch(action.type) {
    case 'showYear': {
      return {shownDate: new Date(), view: 'year'}
    }
    case 'showDetail': {
      return {shownDate: action.shownDate, view: 'detail'}
    }
    case 'toggle': {
      return {...state, view: state.view == 'year' ? 'detail' : 'year'}
    }
    default: {
      throw new Error('No valid action for calendar reducer')
    }
  }
}
const ContractFilters = (props) => {
  const {contracts, filterForm} = props
  const watch = filterForm.watch()


  const ContractCheckbox = ({contract}) => {
      return <FormControlLabel
        control={
          <Checkbox
            checked={watch[contract.id]}
            onChange={(e) => filterForm.setValue(contract.id, e.target.checked )}
            name={contract.id}
            color="primary"
          />
        }
        label={contract.canonicName}
      />
  }
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
        {contracts.map(c => <ContractCheckbox contract={c}/>)}
    </div>
  )
}
const YearCalendar = props => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, {shownDate: new Date(), view: 'year'})
  const {data} = useQuery(FETCH_CONTRACTS, {variables: {query: {status: 'approved'}}})
  const [showYear, setShowYear] = useState(true)
  const {shownDate, setShownDate} = useState(new Date())
  const context = useContext(StoreContext)
  const filterForm = useForm()
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [shownModal, setShownModal] = useState('form')

  useEffect(() => {
    if(!data) return
    data.contracts.forEach(c => {filterForm.register(c.id, {value: true}); })
    data.contracts.forEach((c) => filterForm.setValue(c.id, true) )
  }, [data])

  if(data) {
    const setAsOnlyVisible = (contract) => {
      data.contracts.forEach((c) => filterForm.setValue(c.id, c.id == contract.id) )
    }

    const showAllContracts = () => {
      data.contracts.forEach((c) => filterForm.setValue(c.id, true) )
    }


    const hideAllContracts = () => {
      data.contracts.forEach((c) => filterForm.setValue(c.id, false) )
    }

    const events = data.contracts
      .filter(c => filterForm.watch(c.id))
      .map(contractToEvent)

    
    return (
      <div>
        <div style={{padding: '1em'}}>
          <Button color="primary" className={classes.margined} variant="contained" onClick={() => dispatch({type: 'toggle'})}>
            View Detail
          </Button>

          <Button color="primary" className={classes.margined} variant="contained" onClick={() => {setShowModal(true); setShownModal('filters')}}>
              Filters
          </Button>
          <Button color="primary" className={classes.margined} variant="contained" onClick={() => {setShowModal(true); setShownModal('movements')}}>
              Add Movement
          </Button>
        </div>
          {state.view == 'year' ? ( <Calendar events={events} dispatch={dispatch} setAsOnlyVisible={setAsOnlyVisible}/>) : (<MonthCalendar events={events} dispatch={dispatch} date={state.shownDate}/>)}
          <CustomModal
            open={showModal}
            onClose={() => setShowModal(false)}
            title={t('filters')}
            Actions={ () =>
            <React.Fragment>
              <Button onClick={() => showAllContracts()} className={classes.margined}>
                  {t('showAll')}
              </Button>
              <Button onClick={() => hideAllContracts()}>
                  {t('hideAll')}
              </Button>
            </React.Fragment>
            }
          >
                { shownModal == 'filters' 
                ?  <ContractFilters filterForm={filterForm} contracts={data.contracts}/>
                : (
                  <MovementForm
                    onCompleted={(e) => {
                      context.state.showAlert({
                        severity: 'success',
                        message: t('changesSaved')
                      })
                    }}
                    onError={(e) => {
                      context.state.showAlert({
                        severity: 'error',
                        message: e.message
                      })
                    }}
                  />
                )
                  }
          </CustomModal>
      </div>
    )
  }
  else return <div>Loading</div>
}

export default YearCalendar
