import React, { useState, useEffect } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { CustomModal } from 'components'
import { StateButtons, ProductPriceForm, RvoiVarFields } from './components'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    }
}))

const SlidePicker = ({setSlide, slideNumber, data}) => {
    switch(slideNumber) {
        case 'rvoi_spot':
            return <ProductPriceForm data={data} priceType={slideNumber}/>
        case 'rvoi_var':
            return <ProductPriceForm data={data} priceType={slideNumber}/>
        default:
            return <StateButtons setSlide={setSlide} />
    }
}

const ProductDashboardInputs = ({data}) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [slide, setSlide] = useState(null)
    const hideModal = () => {
        setModalVisible(false)
        setSlide(null)
    } 
    const { t } = useTranslation()
    const classes = useStyles()

    const addPriceFn = () => { setModalVisible(true) }


    useEffect(() => {
        return document.addEventListener('keyup', (e) => {
          if(e.target.closest('input') != null) return
          if(e.key == 'a') {
            e.preventDefault();
              addPriceFn()
          }
        })
    }, [])

    return (
        <div className={classes.root}>
            <CustomModal
              open={modalVisible}
              onClose={hideModal}
              Actions={ () => <div></div> } >
                <SlidePicker 
                    slideNumber={slide}
                    data={data}
                    setSlide={setSlide}
                    />
            </CustomModal>
            <Button color='primary' variant='contained' 
                    onClick={addPriceFn}>
                {t('registerPrice')}
            </Button>
        </div>
    )
}

export default ProductDashboardInputs
