const SET_SESSION = 'set session';
const LOG_IN_SUCCESS = 'log in success';
const LOG_OUT = 'log out';
const FETCHING_SESSION = 'fetching session';
const SESSION_FETCHED = 'session fetched';
const SHOW_ALERT = 'show alert';
const HIDE_ALERT = 'hide alert';
const OPEN_DIALOG = 'open dialog';
const HIDE_DIALOG = 'hide dialog';

export {
  SET_SESSION,
  LOG_IN_SUCCESS,
  LOG_OUT,
  FETCHING_SESSION,
  SESSION_FETCHED,
  SHOW_ALERT,
  HIDE_ALERT,
  OPEN_DIALOG,
  HIDE_DIALOG,
};
