import React, { Fragment } from 'react';
import { Typography, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment'

import { RoutesContext } from '../RouteManager.js'


const RouteRateCard = props => {
  const { t } = useTranslation()
  const startDate = moment(props.routeRate.startDate).format('DD/MM/YYYY')
  const endDate = moment(props.routeRate.endDate).format('DD/MM/YYYY')

  return (
    <Fragment>
        <Typography className={"rm__text"} variant='body2'>{props.routeRate.id}</Typography>
        <Typography className={"rm__text"} variant='body2'>FSC: ${props.routeRate.fsc}</Typography>
        <Typography className={"rm__text"} variant='body2'>{startDate} - {endDate}</Typography>
    </Fragment>
  )
}

export default RouteRateCard
