import React from 'react'
import moment from 'moment'

import { Line } from 'react-chartjs-2'

const colors = [
    {
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor:  'rgb(53, 162, 235, 0.5)'
    }, {
        borderColor: 'rgb(255, 206, 86)',
        backgroundColor:  'rgba(255, 206, 86, 0.2)'
    }, {
        borderColor: 'rgb(99, 132, 255)',
        backgroundColor:  'rgb(99, 132, 255, 0.5)'
    }
]

const LineChart = ({productPrice}) => {
    const prices = productPrice.historic.filter(p => p.spotPrice != 999.0)
    const competingProviders = {}
    const competitors = productPrice.competitors.filter(c => {
        if(competingProviders[c.provider.id]) {
            return false
        }
        competingProviders[c.provider.id] = true
        return true
    })
    const labels = prices.map(p => moment(p.startDate).format('D, MMM'))
    const data = {
        labels, 
        datasets: [{
            label: productPrice.provider.name,
            data: prices.map(p => p.spotPrice),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'transparent'
        }, ...competitors.map((c, idx) => ({
            label: c.provider.name, // data: c.historic.filter(p => p.spotPrice != 999.0).map(p => p.spotPrice),
            data: labels.map(l => c.historic.filter(p => p.spotPrice != 999.0).find(c => moment(c.startDate).format('D, MMM') == l)?.spotPrice),
            borderColor: colors[idx].borderColor,
            backgroundColor: 'transparent'
        }))]
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Historic Prices'
            },
        },
    }
    return (
        <>
            <Line options={options} data={data}/>
        </>
    )
}

export default LineChart
