import * as SessionServices from './Authentication';
import { IT_Session } from './types';
import ErrorTypes from 'constants/errorTypes'
import {ErrorObject} from 'components'

const SessionHandler = {
  fetchCurrentUser: async (): Promise<null | IT_Session> => {
    const storedToken = SessionServices.getAuthToken();
    if (storedToken) {
      console.log('stored token:', storedToken);
      try {
        const res = await SessionServices.fetchCurrentUser();
        if (res.data?.currentUser)
          return { authToken: storedToken, ...res.data.currentUser };
        else return null;
      }
      catch(err) {
        
          throw new ErrorObject({
            message: err,
            errorType: ErrorTypes.COMMUNICATION,
            incidentTime: new Date(),
            functionCall: {name: 'fetchCurrentUser'}
          })
      }
    }
    return null;
  }
};
export default SessionHandler;
