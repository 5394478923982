import React, { useState, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useForm, Controller } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  FormControl,
  TextField,
  Typography,
  InputLabel,
  FormHelperText,
  Paper,
  Chip
} from '@material-ui/core';
import { Can } from 'lib/can';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { StoreContext } from 'App';
import { LoadingBar } from 'components';
import { CREATE_CONTRACT, UPDATE_CONTRACT, FETCH_CONTRACT_CREATION_DATA } from 'constants/queries'
import { Loader, Select, Slider } from 'components'
import {
  IndexFields,
  FixedPriceFields,
  ContractSpotFields,
  ContractTermFields,
  FormOptions,
} from './components'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(3),
  }
}));

const QTFields = (params) => {
  const { t } = useTranslation()

  if(params.type == 'spot')
    return <Grid container spacing={3}>
      <ContractSpotFields {...params}/>
    </Grid>
  else if(params.type == 'term')
    return <Grid container spacing={3}>
      <ContractTermFields {...params}/>
    </Grid>
  else 
    return <div>Invalid Term Type</div>
}

const PTFields = (params) => {
  const { t } = useTranslation()
  const classes = useStyles();

  if(params.type == 'variable')
    return <IndexFields {...params} classes={classes}/>
  else if(params.type == 'fixed')
    return <FixedPriceFields {...params} classes={classes}/>
  else
    return <div>Invalid Price Type</div>
}

const contractFromInquiry = (inquiry) => {
  const contract = {
    ...inquiry,
    term: inquiry.termMonths.map(e => ({year: e.year, month: e.month, value: e.value})),
    contractType: inquiry.inquiryType,
    validUntil: inquiry.expirationDate
  }
  delete contract.inquiryType
  delete contract.id
  delete contract.expirationDate
  delete contract.termMonths
  return contract
}


const ContractFormFields = props => {

  const classes = useStyles()
  const { form, contract, idx, destinations, productCategories} = props;
  const { t } = useTranslation()
  const {errors, register, control, setValue} = form
  const watchAll = form.watch();
  let productCategory = null
  let product = null
  if(watchAll.productCategory) {
    productCategory = watchAll.productCategory
    if(watchAll.product) {
      product = watchAll.product
    }
  } else if(contract) {
    productCategory = productCategories.find(e => e.id == contract.product.productCategoryId)
    product = productCategory.products.find(e => e.id == contract.product.id)
  } else {
    productCategory = productCategories.first
    product = productCategory.frist.products.first
  }

  const Slides = [
    (
    <Grid container='slide__grid-override' container spacing={1}>

      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          defaultValue={contract && contract.quotationName}
          label={t("quotation_name")}
          margin="dense"
          name="quotationName"
          required
          variant="outlined"
          error={errors.quotationName}
          inputRef={register({ required: true })}
          helperText={errors.quotationName && t(errors.quotationName.type)}
        />
      </Grid>

      <Grid item md={6} xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
            error={errors.destinationId}
            className={classes.formControl}>
            <Controller
              name="destination"
              control={control}
              defaultValue={destinations.find(e => e.id == contract?.destinationId)}
              rules={{ required: true }}
              as={({ value, onChange }) => (
                <Autocomplete
                  id="destination"
                  value={value}
                  onChange={(_, destination) => setValue( 'destination', destination) }
                  options={destinations}
                  getOptionLabel={option => t(option.city)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={errors.destination}
                      label={t('destination')}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
            <FormHelperText>
              {errors.destination && t(errors.destination.type)}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12}>
          <Select 
            name='incoterm'
            label={t('incoterm')}
            subject={contract}
            options={FormOptions.incotermOptions}
            fieldsDisabled={false}
            control={control}
            sv={setValue}
            errors={errors}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            id="datetime-local"
            fullWidth
            margin="dense"
            name="validUntil"
            defaultValue={contract && contract.validUntil}
            label={t('valid_until')}
            type="date"
            variant="outlined"
            inputRef={register({ required: true })}
            error={errors.validUntil}
            helperText={
              errors.validUntil && t(errors.validUntil.type)
            }
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>

    </Grid>
    ), 
(

    <Grid container='slide__grid-override' container spacing={1}>
      <Grid item md={4} xs={12}>
        <Select
          name='productCategory'
          label={t('productCategory')}
          subject={contract}
          control={control}
          options={productCategories.map(e => ({label: e.name, value: e}))}
          sv={setValue}
          errors={errors}
          getDefaultMatcher={(option, subject) => option.id == subject.product.productCategoryId}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <Select 
          name='product'
          label={t('product')}
          subject={contract}
          control={control}
          options={productCategory.products.map(e => ({label: e.name, value: e}))}
          sv={setValue}
          errors={errors}
          getDefaultMatcher={(option, subject) => option.id == subject.product.id}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <Select 
          name='productUnit'
          label={t('productUnit')}
          subject={contract}
          control={control}
          options={product ? product.productUnits.map(e => ({label: e.name, value: e})) : []}
          sv={setValue}
          errors={errors}
          getDefaultMatcher={(option, subject) => option.id == subject.productUnit.id}
        />
      </Grid>
    </Grid>
    ),
    (

    <Grid container='slide__grid-override' container spacing={1}>
      <Grid item md={6} xs={12}>
        <Select 
          name='priceType'
          label={t('priceType')}
          subject={contract}
          options={FormOptions.priceTypeOptions}
          fieldsDisabled={false}
          control={control}
          sv={setValue}
          errors={errors}
        />
      </Grid>


        <Grid item xs={6}></Grid>
        <PTFields {...{type: watchAll.priceType, contract, product: watchAll.product, register, errors, setValue, control, ...props}}/>
        <Grid item xs={12}></Grid>

        <Grid item xs={12} md={4}>
          <Select 
            name='paymentType'
            label={t('paymentType')}
            control={control}
            subject={contract}
            options={FormOptions.paymentTypeOptions}
            fieldsDisabled={false}
            control={control}
            sv={setValue}
            errors={errors}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Select 
            name='termsOfPayment'
            label={t('termsOfPayment')}
            control={control}
            subject={contract}
            options={FormOptions.termsOfPaymentOptions}
            sv={setValue}
            errors={errors}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <TextField
            fullWidth
            defaultValue={contract && contract.termsOfPaymentDays}
            label={t("terms-of-payment-days")}
            margin="dense"
            name="termsOfPaymentDays"
            required
            variant="outlined"
            type='number'
            error={errors.termsOfPaymentDays}
            inputRef={register({ required: true })}
            helperText={errors.termsOfPaymentDays && t(errors.termsOfPaymentDays.type)}
          />
        </Grid>

      </Grid>
    ), (
    <Grid container='slide__grid-override' container spacing={1}>

      <Grid item md={12} xs={12}>
        <Select 
          name='contractType'
          label={t('contractType')}
          subject={contract}
          control={control}
          options={FormOptions.contractTypeOptions}
          sv={setValue}
          errors={errors}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper variant='outlined' className={classes.paper}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <QTFields {...{type: watchAll.contractType, contract, watchAll, ...form, ...props}}/>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          defaultValue={contract && contract.comments}
          rows={5}
          label={t("comments")}
          margin="dense"
          name="comments"
          variant="outlined"
          inputRef={register({ required: true })}
          error={errors.comments}
          helperText={errors.comments && t(errors.comments.type)}
        />
      </Grid>
    </Grid>

    )
  ]

  return (
    <Slider 
      slides={Slides}
      index={idx}
    />
  )

}

const ContractForm = props => {
  const { className, inquiry, onCompleted, onError, fieldsEnabled, ...rest } = props;
  let {contract} = props
  const classes = useStyles();
  const { t } = useTranslation()
  if(inquiry && !contract)
    contract = contractFromInquiry(inquiry)

  const { data } = useQuery(FETCH_CONTRACT_CREATION_DATA)
  const [idx, setIndex] = useState(0)
  const context = useContext(StoreContext)
  const form = useForm({
    defaultValues: {
      contractType: FormOptions.contractTypeOptions[1].value,
      priceType: FormOptions.priceTypeOptions[1].value,
    }
  });

  const titles = ['general','product', 'pricing', 'term']

  const { register, unregister, errors, handleSubmit, setValue, control, watch } = form
  const contractType = watch('contractType', FormOptions.contractTypeOptions[1].value)
  const priceType = watch('priceType', FormOptions.priceTypeOptions[1].value)

  const [mutate, mutationStatus] = useMutation(contract.id ? UPDATE_CONTRACT : CREATE_CONTRACT, { onCompleted, onError })


  const buildTermMonthAttributes = (term) => {
    let termMonthAttributes = []
    Object.keys(term).forEach(yearKey => {
      const year = parseInt(yearKey.replace('year-', ''))
      const values = Object.keys(term[yearKey]).map( monthKey => (
        {
          year,
          month: parseInt(monthKey.replace('month-', '')) + 1,
          value: parseInt(term[yearKey][monthKey].value)
        }
      ))
      termMonthAttributes.push(values)
    })
    return termMonthAttributes.flat()
  }

  const termAlreadyExists = (term) => Object.keys(term)[0].match(/id/)

  const buildUpdatedTerm = (term) => {
    const termMonthAttributes = Object.keys(term).map(key => ({ id: key.substr(3), value: parseInt(term[key])}))
    return termMonthAttributes
  }

  const getQTFields = (data) => {
    let result = {}

    if(data.contractType == 'term') {
      result.termMonthsAttributes = termAlreadyExists(data.term) ? buildUpdatedTerm(data.term) : buildTermMonthAttributes(data.term)
    }
    else if (data.contractType == 'spot') {
      result = {
        month: data.month
      }
    }
    return result
  }

  const getPTFields = (data) => {
    let result = {}
    if(data.priceType == 'fixed') {
      result = {
        price: parseInt(data.price)
      }
    }
    else if(data.priceType == 'variable') {
      result = {
        offset: parseInt(data.offset),
        priceIndexId: data.priceIndexId.id,
        priceIndexType: data.priceIndexType.value
      }
    }
    return result
  }

  const onSubmit = ({product, productCategory, productUnit, destination, ...data}) => {
    console.log(data)

    const QuantityTypeFields = getQTFields(data)
    const PriceTypeFields = getPTFields(data)
    const dirtyFields = [
       'term',
       'priceIndexId',
       'priceIndexType',
       'offset',
       'price',
       'termMonthsAttributes',
       'startMonth',
       'endMonth',
       'month'
     ]
    dirtyFields.forEach(key => delete data[key])

    mutate({variables: {
      ...contract.id ? {id: contract.id} : {},
      input: {
        ...data,
        ...PriceTypeFields,
        ...QuantityTypeFields,
        destinationId: destination.id,
        productId: product.id,
        productUnitId: productUnit.id,
        userId: context.state.session.id,
        termsOfPaymentDays: parseInt(data.termsOfPaymentDays),
        spotUnits: parseInt(data.spotUnits),
        spotMonth: parseInt(data.spotMonth),
        ...contract.id ? {} : {inquiryId: inquiry.id}
      }
    }})
  }
    
  if(data) {
    const slideCount = 4
    
    const nextSlide = () => setIndex((idx+1)%slideCount)
    const prevSlide = () => {
      const nextIndex = (idx-1);
      setIndex( nextIndex < 0 ? slideCount - 1 : nextIndex)
    }

    return (
      <div className={classes.root}>
        <Card {...rest} >
          <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
            <CardHeader subheader={t("information_editable")} title={t("contract")} />
            <React.Fragment>
                {titles.map((title, index) => (
                  <Chip
                    onClick={() => setIndex(index)}
                    style={{margin: '1em 0.25em 1em 1em', cursor: 'pointer'}}
                    label={t(title)}
                    color={index == idx ? 'primary' : 'disabled'}/>
                ))}
            </React.Fragment>
            <Divider />
            <CardContent>
              <ContractFormFields 
                idx={idx}
                form={form}
                destinations={data.destinations}
                productCategories={data.productCategories}
                contract={contract}
              />
            </CardContent>
            <Divider />
            <CardActions style={{justifyContent: 'space-between'}}>
            <div>
              {fieldsEnabled && (
                <Button color='primary' variant='contained' type='submit' disabled={mutationStatus.loading}>
                    {`${props.contract ? t('update') : t('create')} ${t('contract')}`}
                </Button>
              )}
            </div>
            <div>
              <Button onClick={() => prevSlide()}>{t('prev')}</Button>
              <Button onClick={() => nextSlide()}>{t('next')}</Button>
            </div>
            </CardActions>
          </form>
          {mutationStatus.loading && <LoadingBar />}
        </Card>
      </div>
    );
  }
  else
    return <Loader/>
};

export default ContractForm;
