import React, { useContext, useEffect } from 'react'
import { makeStyles, Typography } from '@material-ui/styles'
import clsx from 'clsx'
import gsap from 'gsap'

import { ManagerContext } from './CarrierManager'
import { Actions } from './ManagerReducer'

const useStyles = makeStyles(theme => ({
  window: {
    display: 'flex',
    overflowX: 'hidden',
    scrollSnapStyle: 'both mandatory',
  },
  array: {
    flex: '1 0 90%',
    margin: '0 5% 0 0',
    scrollSnapAlign: 'center',
    '&:first-of-type': {
      margin: '0 5% 0 5%',
    }
  },
  item: {
    '&:hover': {
      background: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      borderColor: 'black'
    }
  },
  selectedItem: {
    background: theme.palette.info.dark,
    color: theme.palette.info.contrastText,
    borderColor: 'black'
  }
}))

const CarrierItem = ({item, idx}) => {
  const context = useContext(ManagerContext)
  const classes = useStyles()
  const isSelected = context.selectedCarrier?.id == item.id ? true : false
const select = () => {
  context.dispatch({
    type: Actions.SET,
    payload: {name: 'selectedCarrier', value: item, idx}
  })


  context.dispatch({
    type: Actions.SCROLL_RIGHT,
  })
}

  return (
    <div className={clsx('cm__item', classes.item, isSelected && classes.selectedItem)} onClick={select}>
        {item.name}
    </div>
  )
}


const RouteItem = ({item, idx}) => {
  const context = useContext(ManagerContext)
  const classes = useStyles()
  const isSelected = context.selectedRoute?.id == item.id ? true : false
  const select = () => context.dispatch({
    type: Actions.SET,
    payload: {name: 'selectedRoute', value: item, idx}
  })
    debugger

  return (
    <div className={clsx('cm__item', classes.item, isSelected && classes.selectedItem)} onClick={select}>
        {`${item.carrier.name} - ${item.origin.name} - ${item.destination.name} (${item.product.name} ${item.product.STCC ? '-' + item.product.STCC : ''})`}
    </div>
  )
}


const Array = ({items, Component}) => {
  const classes = useStyles()
  
  return (
    <div className={clsx(classes.array, 'cm__array')}>
        {items.map((c, idx) => <Component item={c} idx={idx}/>)}
    </div>
  )
}

const SelectionWidget = () => {
  const context = useContext(ManagerContext)
  const classes = useStyles()

  return (
    <div className={clsx(classes.window)}>
      <Array
        Component={CarrierItem}
        items={context.carriers}
      />

      <Array
        Component={RouteItem}
        items={context.selectedCarrier ? context.selectedCarrier.routes : []}
      />
    </div>
  )
}

export default SelectionWidget
