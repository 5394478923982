import React, { useState } from 'react'
import { Rows } from 'components'
import { makeStyles, Typography, TableSortLabel, Tooltip, Drawer } from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import ProductPriceInfo from './ProductPriceInfo'
import TimelineIcon from '@material-ui/icons/Timeline';
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { numberWithCommas } from 'lib/helpers'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    }
}))


const Dashboard = (props) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [drawerVisible, setDrawerVisible] = useState(false)

    const headCells = [{
            id:'product',
            numeric: false,
            label: t('product'),
            searchable: true,
            cols: 3,
            disablePadding: true,
            display: r => r.product.name
        }, {
            id: 'price',
            numeric: true,
            label: t('price'),
            cols: 3,
            searchable: true,
            disablePadding: true,
            display: r => {
                const prevPrice = r.historic.length > 0 ? r.historic[0].spotPrice : null
                const change = prevPrice ? 100 - (prevPrice/r.spotPrice*100) : 0
                const Arrow = change > 0 ? ArrowDropUpIcon : ArrowDropDownIcon
                const color = change > 0 ? 'error' : 'primary'
                const ChangeDiv = () => (
                    <div style={{margin: '0 0.5em'}}>
                        <Typography color={color}>
                            <Arrow color={color}/>
                            {`${change.toFixed(0).toString()}%`}
                        </Typography>
                    </div>
                )

                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Typography varian='body1'>{numberWithCommas(r.priceType == 'rvoi_spot' ? r.spotPrice : r.varCurrentValue || 0)}</Typography>
                        {prevPrice && <ChangeDiv/> }
                    </div>

                )
            }
        }, {
            id: 'region',
            numeric: false,
            label: t('region'),
            searchable: true,
            disablePadding:true,
            display: r => r.region.name
        }, {
            id: 'provider',
            numeric: false,
            label: t('provider'),
            searchable: true,
            disablePadding: true,
            display: r => r.provider.name
        }, {
            id:'info',
            numeric: false,
            label: t('info'),
            searchable: true,
            disablePadding: true,
            display: p => (
                <>
                    {p.isBest && (
                        <Tooltip title={t('bestProductPrice')} placement='top'>
                            <ThumbUpIcon color='primary'/>
                        </Tooltip>
                    )}
                    {p.isOutdated && (
                        <Tooltip title={t('priceOutdated')} placement='top'>
                            <TimerOffIcon color='error'/>
                        </Tooltip>
                    )}
                    {
                        <Tooltip title={t('viewDate')} placement='top' style={{marginLeft: '0.5em'}}>
                            <TimelineIcon onClick={() => setDrawerVisible(p.id)} />
                        </Tooltip>
                    }
                </>
            ),
            fn: p => p.isBest ? 'Best' : ''
        }
    ]

    const childHeadCells = [
        {
            id:'price',
            numeric: false,
            label: t('price'),
            searchable: true,
            disablePadding: true,
            display: r => numberWithCommas(r.spotPrice)
        }, {
            id:'startDate',
            numeric: false,
            label: t('startDate'),
            searchable: true,
            disablePadding: true,
            display: r => moment(r.startDate).format("MMM Do 'YY")
        }, {
            id:'expirationDate',
            numeric: false,
            label: t('expirationDate'),
            searchable: true,
            disablePadding: true,
            display: r => moment(r.expirationDate).format("MMM Do 'YY")
        }

    ]


    const productPrices = props.productPrices.map(pp => ({
        ...pp,
        children: { headCells: childHeadCells, data: pp.historic }
    }))

    return (
        <div className={classes.root}>
            <div>Dashboard</div>
            <Drawer anchor={'right'} open={drawerVisible} onClose={() => setDrawerVisible(false)}>
                {drawerVisible && <ProductPriceInfo productPriceId={drawerVisible}/>}
            </Drawer>
            <Rows headCells={headCells} data={productPrices}/>
        </div>

    )
}

export default Dashboard
