import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Card,
 CardContent,
 Grid,
 makeStyles,
 Typography,
 Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    priceTypeBtn: {
        width: '100%',
        padding: '1em',
    },
    priceTypeText: {
        color: theme.palette.info.contrastText,
    }

}))

const StateButton = ({setSlide, name, idx}) => {
    const classes = useStyles();
    const { t } = useTranslation()
    const onClick= () => setSlide(name)


    useEffect(() => {
        const listener = (e) => {
          if(parseInt(e.key) == idx) {
            e.preventDefault();
            onClick();
          }
        }

        document.addEventListener('keyup', listener)

        return () => document.removeEventListener('keyup', listener)
    }, [])

    return (
        <Grid item md={6}>
            <Button 
                variant='contained' 
                color='primary' 
                onClick={() => setSlide(name)}
                className={classes.priceTypeBtn}>
                <Typography 
                    variant='body1' 
                    className={classes.priceTypeText}>
                    {idx} - {t(name)}
                </Typography>
            </Button>
        </Grid>
    )
}

const StateButtons = ({setSlide}) => { 

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <StateButton setSlide={setSlide} name='rvoi_spot' idx={1}/>
                    <StateButton setSlide={setSlide} name='rvoni_spot' idx={2}/>
                    <StateButton setSlide={setSlide} name='rvoi_var' idx={3}/>
                    <StateButton setSlide={setSlide} name='rvoni_var' idx={4}/>
                </Grid>
            </CardContent>
        </Card>
    )
}


export default StateButtons
