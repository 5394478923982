import { CREATE_ROUTE_RATE, UPDATE_ROUTE_RATE } from 'constants/queries'
const RouteRateModel = {
  keys: {
    create: 'routeRate'
  },
  fields: [
    'routeRate.fsc',
    'routeRate.startDate',
    'routeRate.endDate',
    'routeRate.carrierId',
    'routeRate.fscType',
  ],
  extractFields: data => {
    return {
      fsc: data.routeRate.fsc,
      startDate: data.routeRate.startDate,
      endDate: data.routeRate.endDate,
      carrierId: data.routeRate.carrier.id,
      fscType: data.routeRate.fscType,
    }
  },
  queries: {
    create: CREATE_ROUTE_RATE,
    update: UPDATE_ROUTE_RATE
  },
}

export default RouteRateModel
