import React, { useContext, Fragment, useEffect } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import { Typography, Grid, TextField, Button, Card, CardContent } from '@material-ui/core'
import { useForm, FormProvider, useFormContext} from 'react-hook-form'


import { FormHandler } from 'lib/Business'
import { ManagerContext } from './CarrierManager'
import { ScrollContexts } from './ManagerReducer'
import { FreightRateModel } from 'models'
import { routeCanonicName } from 'lib/helpers'
import { Field, Select, ErrorBoundary, FileInput } from 'components'

const makeDefaultValue = value => (option, _) => option.id == value.id

const buildRouteOptions = (context) => {
  if(context.selectedCarrier)
    return context.selectedCarrier.routes.map(r => ({value: r, label: r.routeCode + ' - ' + routeCanonicName(r)}))
  else
    return context.carriers
      .reduce((acc, c) => { acc.push(c.routes); return acc}, [])
      .flat().map(r => ({value: r, label: r.routeCode + ' - ' + routeCanonicName(r)}))
}

const FreightRateFields = () => {
  const context = useFormContext()
  context.register('freightRate.document')
  const { t } = useTranslation()
  const watchAll = context.watch()

  useEffect(() => {
    if(context.selectedRoute)
      document.querySelector('#rRate-input').focus()
    context.register('freightRate.product')
  }, [])
  const unitTypeOptions = FreightRateModel.buildUnitTypeOptions().map(u => ({...u, label: t(u.label)}))

  return (
    <Fragment>
      <Grid
          item
        md={3}
      >
        <Field
          field={Select}
            getDefaultMatcher={makeDefaultValue(watchAll.freightRate.carrier)}
            label={t('carrier')}
            name="freightRate.carrier"
            options={context.carriers.map(c => ({value: c, label: c.name}))}
        />
      </Grid>
      <Grid
          item
        md={3}
      >
        <Field
          field={Select}
            label={t('route')}
            name="freightRate.route"
            options={buildRouteOptions(context)}
          {...context.selectedRoute ? {getDefaultMatcher: makeDefaultValue(watchAll.freightRate.route)} : {}}
        />
      </Grid>

      <Grid
          item
        md={3}
      >
        <Field
          field={Select}
            label={t('units')}
            name="freightRate.unitType"
            options={unitTypeOptions}
        />
      </Grid>

      <Grid
          item
        md={3}
      >
        <Field
          InputProps={{id: 'rRate-input'}}
            field={TextField}
            fullWidth
            label={`${t('rate')} (${t(unitTypeOptions.find( u => u.value == watchAll.freightRate.unitType)?.label)})`}
            name="freightRate.rate"
            type="number"
        />
      </Grid>
      <Grid
          item
        md={4}
      >
        <Field
          field={TextField}
            fullWidth
            label={t('startDate')}
          // defaultValue={moment().format('YYYY-MM-DD')}
            name="freightRate.startDate"
            type="date"
        />
      </Grid>

      <Grid
          item
        md={4}
      >
        <Field
          field={TextField}
            fullWidth
            label={t('endDate')}
            name="freightRate.endDate"
          // defaultValue={moment().add(1, 'M').format('YYYY-MM-DD')}
            type="date"
        />
      </Grid>

      <Grid
          item
        md={4}
      >
        <FileInput
            label={t('document')}
            name="document"
            onChange={file => {
              context.setValue('freightRate.document', file)
          }}
        />
        
      </Grid>
      <Grid
          item
        md={8}
      > </Grid>
    </Fragment>
  )
}



const FreightRateForm = (props) => {
  const FreightRateFormHandler = new FormHandler({model: FreightRateModel, instance: props.product})
  const context = useContext(ManagerContext)
  const methods = useForm({
    defaultValues: {
      ['freightRate.carrier']: context.selectedCarrier,
      ['freightRate.route']: context.selectedRoute,
      ['freightRate.startDate']: moment().format('YYYY-MM-DD'),
      ['freightRate.endDate']: moment().add(1, 'M').format('YYYY-MM-DD'),
      ['freightRate.product']: context.selectedRoute?.product
    }
  })

  const [mutate, mutationStatus] = useMutation(FreightRateFormHandler.getQuery(), {
    onCompleted: props.onCompleted
  })
  const onSubmit = FreightRateFormHandler.getOnSubmit(mutate)
  // const watchAll = methods.watch() //must stay for fields to have correct default value

  useEffect(() => {
    if(context.selectedCarrier)
      methods.setValue('freightRate.carrier', context.selectedCarrier)
  }, [context.selectedCarrier])


  useEffect(() => {
    if(context.selectedRoute)
      methods.setValue('freightRate.route', context.selectedRoute)
    
  }, [context.selectedRoute])


  if(context.selectedCarrier.routes.length == 0) return (
    <>
      <Typography variant="h6">No Routes Available</Typography>
    </>
  )
  return (
    <ErrorBoundary>
      <FormProvider {...{...context, ...methods}}>
        <form
            autoComplete="off"
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div style={{padding: '1em', boxSizing: 'border-box'}}>
            <Grid
                container
              spacing={3}
            >
              <FreightRateFields/>
            </Grid>
            <Button
                type="submit"
              variant="contained"
              color="primary"
            >
                  Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </ErrorBoundary>
  )
}
export default FreightRateForm
