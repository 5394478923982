import React from 'react'
import { useFormContext } from 'react-hook-form'

/*
* Field Component
* Funnel through which aspects of a form are wrapped and prepared for a material-ui input.
*
* Exposes API:
* FormProvider
* useForm

*/
const Field = (props) => {
  const formContext = useFormContext();

  const fieldProps = {
    name: props.name,
    subject: formContext.subject,
    control: formContext.control,
    options: props.options,
    fieldsDisabled: props.fieldsDisabled,
    label: props.label,
    errors: formContext.errors,
    sv: formContext.setValue,
    variant: 'outlined',
    ...props.type == 'date' ? {InputLabelProps: {shrink: true} } : {}
  }

  const Field = props.field

  return (
    <Field
      {...{...props, ...fieldProps, register: formContext.register}}
      inputRef={formContext.register({required: props.required,
                                      valueAsNumber: props.valueAsNumber})}
    />
  )
}
export default Field
