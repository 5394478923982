import React from 'react'
import PDF from 'react-to-pdf'
import moment from 'moment'
import './PDFView.scss'
import { Button } from '@material-ui/core'

const ProductRow = ({product, region, price}) => (
    <tr>
        <td class='body2'>{product}</td>
        <td class='body2'>{region}</td>
        <td class='body2'>${price} / Gal</td>
    </tr>
)
const PDFView = ({productPrices}) => {
    const ref = React.useRef()
    const date = moment().format('D MMMM, YYYY')
    const fileName = `Rack - ULSD & Gas Quote - ${date}`

    return (
        <>
        <PDF targetRef={ref} filename={fileName} options={{ unit: 'in', format: 'letter', orientation: 'portrait' }}>
              {({ toPdf }) => (
                  <Button onClick={toPdf} variant='contained' color='primary' style={{margin: '0 1em'}}>
                      Print PDF
                  </Button>
              )}
        </PDF>
        <div ref={ref}>
            <div class="invoice">
            <div class="ribbon">
                <div class="ribbon__img">
                <svg style={{fill: 'white', stroke: 'white', width: '75%', height: '75%'}}width="107.95mm" height="131.5mm" version="1.1" viewBox="0 0 107.95 131.5" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(-52.55 -25.256)">
                        <g transform="translate(.24942 .24942)">
                            <path d="m95.762 41.374 7.9814-9.2285c3.5183-2.787 6.4526-1.8104 9.2285 0.18706 3.6112 4.1821 7.2598 8.3582 10.843 12.545 6.9804 8.1568 13.711 16.354 19.431 24.712 6.3692 10.152 11.186 19.554 12.128 24.038 2.1351 7.6763 2.1829 14.944 0.15589 22.198-0.73879 2.8764-1.6411 5.344-2.7124 7.389-0.68156 1.0363-1.5529 1.6552-2.6501 1.7771-2.5308 0.068-3.3406-1.6598-3.9907-3.5542 1.1905-3.3094 2.5046-6.4953 3.3048-10.195 0.6137-4.2777 0.49801-8.6921-0.21824-13.219-1.1852-5.444-4.0111-11.23-7.1708-17.085-2.7017-4.7702-5.9969-9.3921-9.3532-13.999-7.6254-10.087-16.105-19.747-24.35-29.525l-7.4826 8.3244c-2.6075 0.38627-5.1406 0.61922-5.1443-4.3648z" style={{strokeWidth: '.26458px'}}/>
                            <path d="m86.302 52.901c3.1538-0.2293 5.3926 0.60891 4.828 4.7178-4.5565 6.0178-9.2601 11.815-13.492 18.32-2.6841 4.1017-5.1534 8.3192-7.0767 12.831-1.3291 2.8446-2.3143 6.8196-3.4171 10.626-0.08064 4.5996-0.45327 9.081 0.70546 14.131 1.2128 3.6808 2.2877 7.263 5.0926 12.081-0.36222 0.24148 6.9958 8.2937 7.253 8.686 0.30964 0.4722 8.7044 6.561 8.5758 6.261 4.582 2.0756 8.0725 3.2935 11.013 4.079 14.384 2.2734 25.015-1.3626 33.89-7.7632l4.8637-4.1778c3.5068-0.31646 5.3188 0.70369 4.0219 5.5496-5.7439 6.292-13.175 9.8317-21.138 12.502-9.4798 2.5185-19.355 2.4695-29.774-1.1224-7.351-2.2542-13.906-7.9911-20.328-14.31-5.3471-6.3681-9.029-13.832-10.788-23.152-0.98824-7.3112-0.87957-14.587 1.7637-21.781 2.9666-8.4344 8.1186-16.097 13.007-23.853z" style={{strokeWidth: '.26458px'}}/>
                            <path d="m104.53 60.882c3.3392-0.90154 5.5832-0.19564 5.291 4.2328-7.4009 9.8983-16.837 19.377-21.733 29.792-5.0692 14.09 1.4657 19.832 6.0484 24.942 11.923 8.5563 22.157 5.7819 29.494-1.3094 6.907-7.1712 8.3826-14.579 4.8637-23.009-2.8812-6.9883-7.0215-12.592-11.037-18.332 0.0159-2.9172 0.40768-5.5157 5.7366-3.9283 6.4362 8.9903 12.335 17.879 13.718 24.505 1.8958 10.771-1.6174 17.84-6.3602 24.069-9.0353 10.918-24.234 12.944-37.226 6.2978-7.6826-4.7987-14.101-12.499-14.217-25.752 0.07087-5.615 2.3685-10.673 5.2378-15.589z" style={{strokeWidth: ".26458px"}}/>
                        </g>
                    </g>
                </svg>

                </div>
            </div>
            <div class='invoice__content'>
                <div class="row" style={{marginBottom: '6em;'}}>
                <div class="flex--col">
                    <div class="flex--row">
                    <h2 class="title">QUOTE</h2>
                    </div>
                    <h5 class='text--light' style={{textAlign: 'right', paddingRight: '15px'}}><span class='text--normal'>NGL Resources</span> // {moment().format('D MMMM, YYYY')}</h5>
                </div>
                </div>
            <div class="row">
                <div class="flex--col">
                    <div class="body1 text--bold">Quote For:</div>
                <div class="body1">ULSD & Regular Gasoline</div>
                <div class="body1"></div>
            </div>

            <div class="flex--col"></div>
            </div>
            <div class="row">
            <table>
            <tr class='tr--heading'>
                <th class='body1 text--bold'>Product</th>
                <th class='body1 text--bold'>Destination</th>
                <th class='body1 text--bold'>Cost Per Gallon</th>
            </tr>

            {productPrices.filter(p => p.isBest).sort(
                (a, b) => {
                    if(a.product.name > b.product.name) return -1
                    if(a.product.name < b.product.name) return 1
                    return 0
                }
            ).map(p => <ProductRow 
                product={p.product.name} 
                region={p.region.name} 
                price={p.spotPrice}/>
            )}
            

            </table>
            </div>  
            </div>
            <div class="row" >
            <div class="flex--col">
            <div class="flex--row">
            <div class="body2 text--shaded">Thank You For Your Business!</div>
            </div>
            <div class="flex--row" style={{borderTop: '1px solid var(--text-soft)', paddingTop: '1em', marginTop: '1em'}}>
            <div class="body3"><span class='text--bold'>Tel:</span> +52 181 2436 0283 <span style={{margin: '0 0.25em'}}>|</span> <span class='text--bold'>Email:</span> operations@nglresources.com <span class='divider-span'>|</span> www.nglresources.com</div></div>
            </div>
            </div>

            </div>
        </div>
        </>

            
    )
}

export default PDFView
