import 'date-fns';
import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  Select,
  FormHelperText
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { StoreContext } from 'App';
import { CREATE_REQUIREMENT, FETCH_PRODUCTS } from 'constants/queries';
import { LoadingBar } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
    margin: '0px'
  }
}));

const RequirementDetails = props => {
  const classes = useStyles();
  const context = useContext(StoreContext);

  const { t } = useTranslation();
  const { className, requirement, ...rest } = props;
  const { register, errors, handleSubmit, setValue, control } = useForm();
  const { data, loading, error } = useQuery(FETCH_PRODUCTS);
  const [createRequirement, mutationStatus] = useMutation(CREATE_REQUIREMENT, {
    onCompleted: () =>
      context.state.showAlert({
        severity: 'success',
        message: 'Requerimento Creado'
      })
  });

const onSubmit = ({ product, ...data }) =>{

    createRequirement({
      variables: {
        input: {
          ...data,
          productId: product.id,
          units: parseInt(data.units)
        }
      }
    });

}
  if (data) {
    return (
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardHeader
            subheader={t('this_information_is_editable')}
            title={t('new_requirement')}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={6}>
                <TextField
                  fullWidth
                  label={t('name')}
                  margin="dense"
                  name="name"
                  required
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.name}
                  helperText={errors.name && t(errors.name.type)}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <FormControl
                  variant="outlined"
                  error={errors.product}
                  className={classes.formControl}>
                  <Controller
                    name="product"
                    control={control}
                    rules={{ required: true }}
                    as={({ value, onChange }) => (
                      <Autocomplete
                        id="product-id-select"
                        value={value}
                        onChange={e =>
                          setValue(
                            'product',
                            data.products[e.target.dataset.optionIndex]
                          )
                        }
                        options={data.products}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={errors.product}
                            label={t('product')}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                  <FormHelperText>
                    {errors.product && t(errors.product.type)}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  label={t('required_quantity')}
                  margin="dense"
                  name="units"
                  required
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.units}
                  helperText={errors.units && t(errors.units.type)}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.unitType}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('unit')}
                  </InputLabel>
                  <Select
                    native
                    label="Unidad"
                    inputProps={{
                      name: 'unitType',
                      id: 'outlined-unit-type-native-simple'
                    }}
                    inputRef={register({ required: true })}
                    error={errors.unitType}>
                    <option aria-label="None" value="" />
                    <option value={'kg'}>Kg</option>
                    <option value={'costal'}>Costal</option>
                  </Select>
                  <FormHelperText>
                    {errors.unitType && t(errors.unitType.type)}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={errors.priceDetail}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('price_detail')}
                  </InputLabel>
                  <Select
                    native
                    label="Detalle de Precio"
                    inputProps={{
                      name: 'priceDetail',
                      id: 'outlined-price-detail-native-simple'
                    }}
                    inputRef={register({ required: true })}
                    error={errors.unitType}>
                    <option aria-label="None" value="" />
                    <option value={'CIF'}>CIF</option>
                    <option value={'FOB'}>FOB</option>
                  </Select>
                  <FormHelperText>
                    {errors.priceDetail && t(errors.priceDetail.type)}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  margin="dense"
                  label={t('closing_contract')}
                  type="date"
                  name="deadline"
                  variant="outlined"
                  // className={classes.textField}
                  inputRef={register({ required: true })}
                  error={errors.deadline}
                  helperText={errors.deadline && t(errors.deadline.type)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <TextField
                  id="datetime-local"
                  fullWidth
                  margin="dense"
                  name="deliveryDate"
                  label={t('delivery_date')}
                  type="date"
                  defaultValue=""
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.deliveryDate}
                  helperText={
                    errors.deliveryDate && t(errors.deliveryDate.type)
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('comments')}
                  margin="dense"
                  name="comments"
                  multiline
                  rows={5}
                  variant="outlined"
                  inputRef={register({ required: true })}
                  error={errors.comments}
                  helperText={errors.comments && t(errors.comments.type)}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={mutationStatus.loading}>
              {t('create_requirement')}
            </Button>
          </CardActions>
          {mutationStatus.loading && <LoadingBar />}
        </form>
      </Card>
    );
  }
  return <div></div>;
};

export default RequirementDetails;
