import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag'


import { LotProfile, LotDetails } from './components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_LOTS } from 'constants/queries';
import * as Fragments from 'constants/fragments'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const FETCH_LOTS_WITH_REQUIREMENTS = gql`
  query fetchLotsWithRequirements($query: LotQuery) {
    lots(query: $query) {
      ...lotFields
      requirement {
        ...requirementFields
      }
    }
  }
  ${Fragments.LotFragments.fields}
  ${Fragments.RequirementFragments.fields}
`

const Lot = props => {
  const { lotId } = useParams();
  const { className, lots, ...rest } = props;
  const { data } = useQuery(FETCH_LOTS_WITH_REQUIREMENTS, {
    variables: { query: { id: lotId } }
  });
  const classes = useStyles();

  if (data) {
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <LotDetails lot={data.lots[0]} />
          </Grid>
        </Grid>
      </div>
    );
  }
  return <div></div>;
};

export default Lot;
