import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {Typography} from '@material-ui/core'

import {FETCH_PRODUCT_PRICE_DETAIL} from 'constants/queries'
import {QueryResult, Rows} from 'components'
import LineChart from './LineChart'



const ProductPriceInfo = ({productPriceId}) => {
    const { t } = useTranslation()
    const data = useQuery(FETCH_PRODUCT_PRICE_DETAIL, {
        variables: {query: {id: productPriceId}}
    })

    const headCells = [
        {
            id: 'provider',
            numeric: false,
            label: t('provider'),
            disablePadding: true,
            display: i => i.provider.name
        },{
            id: 'price',
            numeric:false,
            label: t('price'),
            disablePadding:true,
            display: i => i.spotPrice
        }
    ]
    return (
        <div style={{width: '40em', padding: '1em 2em 1em 1em'}}>
            <QueryResult {...data}>
                {data => (
                    <>
                        <Typography variant='h2'>
                            {data.productPrices[0].provider.name}, {moment(data.productPrices[0].startDate).format('DD MMMM, YYYY')}
                        </Typography>
                        <LineChart productPrice={data.productPrices[0]}/>
                        <Typography variant='h2'>
                            {

                                t('otherPricesFor_Date', {date: moment(data.productPrices[0].startDate).format('DD MMMM, YYYY')})
                            }
                        </Typography>
                        <Rows headCells={headCells} data={data.productPrices[0].competitors}/>
                    </>
                )}
            </QueryResult>
        </div>
    )
}

export default ProductPriceInfo
