import React, { useState, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useForm, Controller } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  FormControl,
  TextField,
  Typography,
  InputLabel,
  FormHelperText,
  Select,
  Paper
} from '@material-ui/core';
import { Can } from 'lib/can';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useParams } from 'react-router-dom'
import gql from 'graphql-tag'

import { StoreContext } from 'App';
import { LoadingBar } from 'components';
import { CREATE_CONTRACT, FETCH_CONTRACT_CREATION_DATA, FETCH_CONTRACTS } from 'constants/queries'
import * as Fragments from 'constants/fragments'
import { Loader } from 'components'
import {
  IndexFields,
  FixedPriceFields,
  ContractSpotFields,
  ContractTermFields,
  FormOptions
} from '../components'

const query = gql`
  query FetchContractCreationData($query: ContractQuery!) {
    contracts(query: $query) {
      ...contractFields 
      product {
        id
        name
        priceIndices {
          id
          name
        }
      }
    }
    productCategories {
      ...productCategoryFields
    }
  }
  ${Fragments.ContractFragments.fields}
  ${Fragments.ProductCategoryFragments.fields}
`



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(3),
  }
}));

const QTFields = (params) => {
  const { t } = useTranslation()

  if(params.type.value == 'spot')
    return <Grid container spacing={3}>
      <ContractSpotFields {...params}/>
    </Grid>
  else if(params.type.value == 'term')
    return <Grid container spacing={3}>
      <ContractTermFields {...params}/>
    </Grid>
  else 
    return <div>Invalid Term Type</div>
}

const PTFields = (params) => {
  const { t } = useTranslation()
  const classes = useStyles();

  if(params.type.value == 'variable')
    return <IndexFields {...params} classes={classes}/>
  else if(params.type.value == 'fixed')
    return <FixedPriceFields {...params} classes={classes}/>
  else
    return <div>Invalid Price Type</div>
}

const ContractDetail = props => {
  const { className, user, ...rest } = props;
  const classes = useStyles();
  const context = useContext(StoreContext);
  const { contractId } = useParams()
  const { data } = useQuery(query, {
    variables: {query: {id: contractId }}
  })

  const { t } = useTranslation();
  const { register, errors, handleSubmit, setValue, control, watch, unregister } = useForm();
  const contractType = watch('contractType')
  const priceType = watch('priceType')
  const productCategory = watch('productCategory')
  const product = watch('product')

  const [createContract, mutationStatus] = useMutation(CREATE_CONTRACT, {
    onCompleted: () =>
      context.state.showAlert({
        severity: 'success', 
        message: `${t('contract')} ${t('created')}`
    })
  })


  useEffect(() => {
    if(!data) return
    const contract = data.contracts[0]
    setValue('priceType', FormOptions.priceTypeOptions.find(e => e.value == contract.priceType))
  }, [data])



  const buildTermMonthAttributes = (term) => {
    let termMonthAttributes = []
    Object.keys(term).forEach(yearKey => {
      const year = parseInt(yearKey.replace('year-', ''))
      const values = Object.keys(term[yearKey]).map( monthKey => (
        {
          year,
          month: parseInt(monthKey.replace('month-', '')) + 1,
          value: parseInt(term[yearKey][monthKey].value)
        }
      ))
      termMonthAttributes.push(values)
    })
    return termMonthAttributes.flat()
  }

  const getQTFields = (data) => {
    let result = {}

    if(data.contractType == 'term') {
      result = {
        termMonthsAttributes: buildTermMonthAttributes(data.term),
        startMonth: data.startMonth.month(),
        endMonth: data.endMonth.month()
      }
    }
    else if (data.contractType == 'spot') {
      result = {
        month: data.month
      }
    }
    return result
  }

  const getPTFields = (data) => {
    let result = {}
    if(data.priceType == 'fixed') {
      result = {
        price: data.price
      }
    }
    else if(data.priceType == 'variable') {
      result = {
        offset: data.offset,
        priceIndexId: data.priceIndexId.id,
        priceIndexType: data.priceIndexType.value
      }
    }
    return result
  }

  const onSubmit = ({product, productCategory, ...data}) => {
    console.log(data)

    const QuantityTypeFields = getQTFields(data)
    const PriceTypeFields = getPTFields(data)
    const dirtyFields = [
       'term',
       'priceIndexId',
       'offset',
       'price',
       'termMonthsAttributes',
       'startMonth',
       'endMonth',
       'month'
     ]
    dirtyFields.forEach(key => delete data[key])

    createContract({variables: {
      input: {
        ...data,
        ...PriceTypeFields,
        ...QuantityTypeFields,
        contractType: data.contractType.value,
        incoterm: data.incoterm.value,
        priceType: data.priceType.value,
        termsOfPayment: data.termsOfPayment.value,
        productId: product.id,
        userId: context.state.session.id,
        contractId,
        termsOfPaymentDays: parseInt(data.termsOfPaymentDays)
      }
    }})
  }


  if(data) {
    const contract = data.contracts[0]
    const productCategories = data.productCategories
    const productCategory = productCategories.find(pc => pc.id == (product ? product.productCategoryId : contract.product.productCategoryId))
    return (
      <div className={classes.root}>
        <Card {...rest} className={clsx(classes.root, className)}>
          <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
            <CardHeader subheader={t("information_editable")} title={t("contract")} />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <FormControl
                    error={errors.unit}
                    className={classes.formControl}
                    variant='outlined'
                    fullWidth
                  >
                    <Controller
                      name='productCategory'
                      control={control}
                      rules={{required: true}}
                      defaultValue={productCategories.find(pc => pc.id == contract.product.productCategoryId)}
                      as={({value, onChange}) => (
                        <Autocomplete
                          id='product-category-select'
                          disabled={true}
                          value={value}
                          onChange={e => 
                            setValue(
                              'productCategory',
                              data.productCategories[e.target.dataset.optionIndex]
                            )
                          }
                          options={productCategories}
                          getOptionLabel={option => option.name}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={errors.productCategory}
                              label={t('category')}
                              variant='outlined'
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText>{errors.productCategory && t(errors.productCategory.type)}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={errors.product}
                    className={classes.formControl}>
                    <Controller
                      name="product"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={productCategory.products.find(p => p.id == contract.product.id)}
                      as={({ value, onChange }) => (
                        <Autocomplete
                          id="product-id-select"
                          value={value}
                          disabled={true}
                          onChange={e =>
                            setValue(
                              'product',
                              productCategory.products[e.target.dataset.optionIndex]
                            )
                          }
                          options={productCategory ? productCategory.products : []}
                          getOptionLabel={option => option.name}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={errors.product}
                              label={t('product')}
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText>
                      {errors.product && t(errors.product.type)}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="datetime-local"
                    fullWidth
                    margin="dense"
                    defaultValue={contract.expirationDate}
                    name="expirationDate"
                    label={t('expiration_date')}
                    type="date"
                    variant="outlined"
                    inputRef={register({ required: true })}
                    error={errors.expirationDate}
                    helperText={
                      errors.expirationDate && t(errors.expirationDate.type)
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              <Grid item md={4} xs={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={errors.contractType}
                  className={classes.formControl}>
                  <Controller
                    name="contractType"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={FormOptions.contractTypeOptions.find(e => e.value == contract.contractType)}
                    as={({ value, onChange }) => (
                      <Autocomplete
                        id="contract-type"
                        value={value}
                        onChange={e =>
                          setValue(
                            'contractType',
                            FormOptions.contractTypeOptions[e.target.dataset.optionIndex]
                          )
                        }
                        options={FormOptions.contractTypeOptions}
                        getOptionLabel={option => t(option.label)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={errors.product}
                            label={t('contract_type')}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                  <FormHelperText>
                    {errors.contractType && t(errors.contractType.type)}
                  </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item md={4} xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={errors.incoterm}
                    className={classes.formControl}>
                    <Controller
                      name="incoterm"
                      control={control}
                      defaultValue={FormOptions.incotermOptions.find(o => o.value == contract.incoterm)}
                      rules={{ required: true }}
                      as={({ value, onChange }) => (
                        <Autocomplete
                          id="contract-type"
                          value={value}
                          onChange={e =>
                            setValue(
                              'incoterm',
                              FormOptions.incotermOptions[e.target.dataset.optionIndex]
                            )
                          }
                          options={FormOptions.incotermOptions}
                          getOptionLabel={option => t(option.label)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={errors.incoterm}
                              label={t('incoterm')}
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText>
                      {errors.incoterm && t(errors.incoterm.type)}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={errors.priceType}
                    className={classes.formControl}>
                    <Controller
                      name="priceType"
                      control={control}
                      rules={{ required: true }}
                      as={({ value, onChange }) => (
                        <Autocomplete
                          id="price-type"
                          value={value}
                          onChange={e =>
                            setValue(
                              'priceType',
                              FormOptions.priceTypeOptions[e.target.dataset.optionIndex]
                            )
                          }
                          options={FormOptions.priceTypeOptions}
                          getOptionLabel={option => t(option.label)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={errors.priceType}
                              label={t('price_type')}
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText>
                      {errors.priceType && t(errors.priceType.type)}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                  {priceType && <PTFields {...{type: priceType, contract, register, errors, setValue, control, priceIndices: product.priceIndices, unregister}}/>}
                <Grid item md={6} xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={errors.termsOfPayment}
                    className={classes.formControl}>
                    <Controller
                      name="termsOfPayment"
                      control={control}
                      rules={{ required: true }}
                      as={({ value, onChange }) => (
                        <Autocomplete
                          id="price-type"
                          value={value}
                          onChange={e =>
                            setValue(
                              'termsOfPayment',
                              FormOptions.termsOfPaymentOptions[e.target.dataset.optionIndex]
                            )
                          }
                          options={FormOptions.termsOfPaymentOptions}
                          getOptionLabel={option => t(option.label)}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={errors.termsOfPayment}
                              label={t('terms-of-payment')}
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    />
                    <FormHelperText>
                      {errors.termsOfPayment && t(errors.termsOfPayment.type)}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t("terms-of-payment-days")}
                    margin="dense"
                    name="termsOfPaymentDays"
                    required
                    variant="outlined"
                    type='number'
                    error={errors.termsOfPaymentDays}
                    inputRef={register({ required: true })}
                    helperText={errors.termsOfPaymentDays && t(errors.termsOfPaymentDays.type)}
                  />
                </Grid>
                
                <Grid item md={6} xs={12}>
                  <FormControl
                    error={errors.paymentType}
                    className={classes.formControl}
                    variant='outlined'
                    fullWidth
                  >
                    <InputLabel id='payment-type'>
                        {t('payment_type')}
                    </InputLabel>
                    <Select
                      native
                      inputRef={register({required: true})}
                      name='paymentType'
                      label={t('payment_type')}
                      inputProps={{
                        name: 'paymentType',
                        id: 'payment-type-select'
                      }}
                    >
                    {FormOptions.paymentTypeOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {t(option.label)}
                      </option>
                    ))}
                    </Select>
                    <FormHelperText>{errors.paymentType && t(errors.paymentType.type)}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    helperText={t("please_first_name")}
                    label={t("comments")}
                    margin="dense"
                    name="comments"
                    variant="outlined"
                    inputRef={register({ required: true })}
                    error={errors.comments}
                    helperText={errors.comments && t(errors.comments.type)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Paper variant='outlined' className={classes.paper}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                          {contractType && <QTFields {...{type: contractType, contract, register, errors, setValue, unregister}}/>}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper variant='outlined' className={classes.paper}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                >
                {t("create")}
              </Button>
            </CardActions>
            {mutationStatus.loading && <LoadingBar />}
          </form>
        </Card>
      </div>
    );

  }
  else
    return <Loader/>
};

export default ContractDetail;
