import {CREATE_FREIGHT_RATE, UPDATE_FREIGHT_RATE} from 'constants/queries'
import {RouteUnitTypes} from 'constants/routeUnitTypes'
const FreightRateModel = {
  keys: {
      create: 'freightRates'
  },
  fields: [
    'freighRate.rate',
    'freighRate.startDate',
    'freighRate.endDate',
    'freighRate.routeId',
    'freighRate.carrierId',
    'freighRate.productId',
  ],
  extractFields: data => ({
    rate: Number(data.freightRate.rate),
    startDate: data.freightRate.startDate,
    endDate: data.freightRate.endDate,
    routeId: data.freightRate.route.id,
    carrierId: data.freightRate.carrier.id,
    productId: data.freightRate.product.id,
    unitType: data.freightRate.unitType,
    document: data.freightRate.document
  }),
  queries: {
    create: CREATE_FREIGHT_RATE,
    update: UPDATE_FREIGHT_RATE
  },
  buildCarrierOptions: ( carrierArray, t) => carrierArray.map(carrier => ({label: t(carrier.name), value: carrier })),
  buildRouteOptions: ( routeArray, t) => routeArray.map(route => ({label: t(route.name), value: route })),
  buildUnitTypeOptions: () => RouteUnitTypes
}

export default FreightRateModel
