import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Chip
} from '@material-ui/core';

import { getInitials } from 'helpers';
import { Can } from 'lib/can'
import { subject } from '@casl/ability';
import Helpers from 'lib/helpers';
import { UPDATE_REQUIREMENT } from 'constants/queries'
import { StoreContext } from 'App'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ContractsTable = props => {
  const { t } = useTranslation();
  const { className, requirement, contracts, ...rest } = props;

  const classes = useStyles();
  const context = useContext(StoreContext);
  const history = useHistory();

  const [ updateRequirement, mutationStatus ] = useMutation(UPDATE_REQUIREMENT, {
    onCompleted: () => context.state.showAlert({
      severity: 'success',
      message: 'Contracte Elegido'
    })
  })
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { contracts } = props;

    let selectedContracts;

    if (event.target.checked) {
      selectedContracts = contracts.map(contract => contract.id);
    } else {
      selectedContracts = [];
    }

    setSelectedContracts(selectedContracts);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedContracts.indexOf(id);
    let newSelectedContracts = [];

    if (selectedIndex === -1) {
      newSelectedContracts = newSelectedContracts.concat(selectedContracts, id);
    } else if (selectedIndex === 0) {
      newSelectedContracts = newSelectedContracts.concat(selectedContracts.slice(1));
    } else if (selectedIndex === selectedContracts.length - 1) {
      newSelectedContracts = newSelectedContracts.concat(selectedContracts.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedContracts = newSelectedContracts.concat(
        selectedContracts.slice(0, selectedIndex),
        selectedContracts.slice(selectedIndex + 1)
      );
    }

    setSelectedContracts(newSelectedContracts);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={t("contract")} />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("contact")}</TableCell>
                  <TableCell>{t("contract_type")}</TableCell>
                  <TableCell>{t("valid_until")}</TableCell>
                  <TableCell>{t("price_type")}</TableCell>
                  <TableCell>{t("user_actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contracts.slice(0, rowsPerPage).map(contract => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={contract.id}
                    selected={selectedContracts.indexOf(contract.id) !== -1}>
                    <TableCell>
                        {contract.user.email}
                    </TableCell>
                    <TableCell>{t(contract.contractType)}</TableCell>
                    <TableCell>
                      {moment(contract.validUntil).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                        {contract.priceType == 'fixed' ? t('fixed') : `${contract.priceIndex.name} ${t(contract.priceIndexType)} + $${t(contract.offset)}`}
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => history.push('/contracts/view/' + contract.id)}>
                        { t('action_see') }
                      </Button>
                      {'  /  '}
                      <Button
                        color='primary'
                        variant='contained'
                        // disabled={contract.status == 'inactive'}
                        onClick={() =>
                          updateRequirement({
                            variables: {
                              // id: requirement.id,
                              input: {
                                acceptedContractId: contract.id,
                                status: 'inactive'
                              }
                            }
                          })
                        }
                      >
                          { t('choose_contract') }
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={contracts.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

ContractsTable.propTypes = {
  className: PropTypes.string,
  contracts: PropTypes.array.isRequired
};

export default ContractsTable;
