import React, {Fragment} from 'react'
import {ErrorObject, ErrorTypes, Loader} from 'components'

/*
* Exposes API
* ErrorObject
*   message
*   errorType
* */
const QueryResult = ({data, error, loading, children}) => {

  if(loading) return <Loader/>
  if(error) {
    throw new ErrorObject({
      message: error.message,
      errorType: ErrorTypes.COMMUNICATION,
    })
  }

  return <Fragment>
      {data ? children(data) : children}
  </Fragment>

}

export default QueryResult
