import React, { useEffect, useState, useReducer } from 'react'
import ReactDOM from 'react-dom'
import { useQuery } from '@apollo/react-hooks'
import { useForm, FormProvider } from 'react-hook-form'
import L from 'leaflet'
import 'leaflet-routing-machine'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'
import 'leaflet/dist/leaflet.css'
import MarkerIcon from 'leaflet/dist/images/marker-icon.png'
import MarkerIconShadow from 'leaflet/dist/images/marker-shadow.png'
import RedMarkerIcon from 'icons/marker-32.png'
import GreenMarkerIcon from 'icons/green-marker-32.png'
import { Grid, Typography, Card, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { FETCH_MAP_DATA } from 'constants/queries'
import { TextField, Select, Field } from 'components'
import { ProductPriceClass, RackSolutionFinder } from 'business'
import './style.css'


var markerIcon = L.icon({
    iconUrl: MarkerIcon,
    shadowUrl: MarkerIconShadow
});

var redMarkerIcon = L.icon({
    iconUrl: RedMarkerIcon,
    shadowUrl: MarkerIconShadow
})


var greenMarkerIcon = L.icon({
    iconUrl: GreenMarkerIcon,
    shadowUrl: MarkerIconShadow
})

const initMapOnCenter = (map, coors, setMap, setLayerGroup) => {
        var map = L.map('map').setView(coors, 8);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '© OpenStreetMap'
        }).addTo(map);
        setMap(map);
        const layerGroup = L.layerGroup().addTo(map)
        setLayerGroup(layerGroup)

}

const addRoute = ({map, startCoordinates, endCoordinates, styles = [{}]})  => {
                if(!startCoordinates || !endCoordinates) {
                    return
                }
                return L.Routing.control({
                    show: false,
                    waypoints: [
                        L.latLng(startCoordinates),
                        L.latLng(endCoordinates)
                    ],
                    routeWhileDragging: false,
                    createMarker: () => null,
                    routeLine: route => {
                        var line = L.Routing.line(route, { addWaypoints: false, });
                        line.eachLayer(function(l) {
                            l.on('click', function() {
                                alert('touched')
                            });
                        });
                        return line;
                    }

                }).addTo(map)
}

// document.querySelector('#map > div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-overlay-pane > svg > g > path:nth-child(5)').getBBox()
const MonterreyCoordinates = [ 25.643738, -100.343477 ]

const CustomMenu = () => {
    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <ul>
                <li>Hello</li>
                <li>There</li>
                <li>Test</li>
            </ul>
        </div>

    )
}

const ProductPrice = ({object: productPrice}) => {
    return (
        <Grid container spacing={3} style={{padding: '1em'}}>
            <Typography variant='h5'>Product</Typography>
            <Typography variant='h6'>{productPrice.product.name}</Typography>
            <Typography variant='h6'>{productPrice.region.name}</Typography>
        </Grid>
    )
}


const ClientLocation = ({object: client}) => {
    return (
        <Grid container spacing={3} style={{padding: '1em'}}>
            <Typography variant='h5'>Client</Typography>
            <Typography variant='h6'>{client.name}</Typography>
            <Typography variant='h6'>{client.clientLocation.region.name}</Typography>
        </Grid>
    )
}

const useStyles = makeStyles(() => ({
    product: {
        padding: '0.5em',
        borderRadius: '10px',
        transition: 'all',
        duration: '0.3',
        '&:hover': {
            background: '#0005'
        }
    }
}))

const RegionMenu = ({region, onClientProductClick}) => {
    const classes = useStyles();
    // const clients = region.clientLocations.reduce((arr, cl) => {
    //     let client = arr.find(c => c.id == cl.client.id)
    //     if(client) {
    //         client.clientLocations.push(cl)
    //     } else {
    //         client = cl.client;
    //         client.clientLocations = [cl]
    //     }
    //     return [...arr.filter(c => c.id != client.id), client];
    // }, [])

    return (
        <Grid container>
        <Grid item md='12'>
            <Typography variant='h4'>{region.name}</Typography>
        </Grid>

        <Grid item md='12'>
            {region.productPrices?.length > 0 && <div>
                    <Typography variant='h5'>Products</Typography>
                    {region.productPrices.map(pp => (
                        <div className={classes.product} >
                            <Typography variant='b1'>{pp.provider.name} - {pp.product.name} - {new ProductPriceClass(pp).value.value.toFixed(2)}</Typography>
                        </div>
                    ))}
                </div>}
        </Grid>

        <Grid item md='12'>
            {region.terimals?.length > 0 && <div>
                    <Typography variant='h5'>Terminals</Typography>
                    {region.terminals.map(term => (
                        <div>
                            <Typography variant='b1'>{term.name}</Typography>
                        </div>
                    ))}
                </div>
            }
        </Grid>

        <Grid item md='12'>
            {region.clients?.length > 0 && (
                <div>
                    <Typography variant='h5'>Clients</Typography>
                    {region.clients.map(client => (
                        <div>
                            <Typography variant='h6'>{client.name}</Typography>
                            {client.clientLocations.filter(cl => cl.region.id == region.id).map(cl => (
                                <div onClick={() => onClientProductClick({product: cl.product, region: cl.region})}>
                                    <Typography variant='b1'>{cl.product.name}</Typography>
                                </div>
                            ))}

                        </div>
                    ))}
                </div>
            )}
        </Grid>
        

        
        </Grid>
    )

}

const BindRegionMenu = ({marker, region, onClientProductClick}) => {

    const el = document.createElement('div')
    el.classname = 'marker-popup'
    ReactDOM.render(<RegionMenu region={region} onClientProductClick={onClientProductClick}/>, el)
    marker.bindPopup(el)
}

const BindMenu = (marker, object, Element)  => {
    const el = document.createElement('div')
    el.className = 'marker-popup'
    ReactDOM.render(<Element object={object}/>, el)
    marker.bindPopup(el)
}


const MapComponent = ({data, dispatch}) => {
    const [map, setMap] = useState(null)
    const [layerGroup, setLayerGroup] = useState(null)
    const [routeLayerGroup, setRouteLayerGroup] = useState(null)
    useEffect(() => initMapOnCenter(map, MonterreyCoordinates, setMap, setLayerGroup), [])

    const regions = data.regions.map(r => {
        r.productPrices = [...data.latestProductPrices.filter(pp => pp.region.id == r.id)]
        r.terminals = [...data.terminals.filter(t => t.region.id == r.id)]
        r.clients = [...data.clients.filter(c => c.clientLocations.some(cl => {return cl.region.id == r.id}))]
        return r
    })

    const onClientProductClick = ({product, region}) => {
        const solutionFinder = new RackSolutionFinder()
        const solutions = solutionFinder.findSolutions({
                                       destination: region,
                                       routes: data.routes,
                                       productId: product.id,
                                       productPrices: data.latestProductPrices
                                    })

            solutions.forEach((sol, idx) => {
                sol.path.routes.forEach(r => {
                    addRoute({map,
                             startCoordinates: r.originRegion.location.split(',').map(t => Number(t)),
                             endCoordinates: r.destinationRegion.location.split(',').map(t => Number(t)),
                                styles: [{
                                    color: idx == 0 ? 'red' : 'gray',
                                    opacity: 1
                                }]
                    })
                })

            })    
    }

    useEffect(() => {
        if(map) {
            if(layerGroup) {
                layerGroup.clearLayers();

            }

            regions.filter(r => {
                return r.productPrices.length > 0 || r.clients.length > 0 || r.terminals.length > 0
            }).forEach(region => {
                const loc = region.location.split(',').map(t => Number(t))
                const marker = L.marker(loc, {icon: markerIcon}).addTo(map)
                marker.addTo(layerGroup)
                BindRegionMenu({region, marker, onClientProductClick: (p) => {
                    dispatch({type: 'set_selected_origin', payload: p})
                    onClientProductClick(p)
                }})
                // addRoute({startCoordinates: MonterreyCoordinates, endCoordinates: loc, map})
            })

            // terminals.forEach(t => {
            //     debugger
            //     L.marker(loc, {icon: markerIcon}).addTo(map)
            // })
            // data.clients.forEach(c => {
            //     c.clientLocations.forEach(cl => {
            //         const loc = cl.region.location.split(',').map(t => Number(t))
            //         const marker = L.marker(loc, {icon: redMarkerIcon}).addTo(map)
            //     // addRoute({startCoordinates: MonterreyCoordinates, endCoordinates: loc, map})
            //         BindMenu(marker, {...c, clientLocation: cl}, ClientLocation)
            //     })
            // })

            // productPrices.forEach(p => {
            //     const loc = p.region.location.split(',').map(t => Number(t))
            //     const marker = L.marker(loc, {icon: greenMarkerIcon}).addTo(map)
            //         .on('click', () => map.flyTo(loc, 10, {
            //             animate: true,
            //             duration: 1
            //         }))
                
            //     BindMenu(marker, p, ProductPrice)
            // })
        }
        
    }, [data, map, data.latestProductPrices, data.clientLocations])

    return (
        <div id='map' style={{zIndex: '1'}}></div>
    )
}

export const Filters = ({data, children}) => {
    // const [products, setProducts] = useState([])
    // const [origins, setOrigins] = useState([])
    // const [destinations, setDestinations] = useState([])

    const methods = useForm()

    let filteredData = data
        const products = methods.watch('products')
        const origins = methods.watch('origins')
        const destinations = methods.watch('destinations')
    if(products && products.length > 0) {
        filteredData.latestProductPrices = data.latestProductPrices.filter(pp => products.some(p => p.id == pp.product.id))
    }
    if(origins && origins.length > 0) {
        filteredData.latestProductPrices = data.latestProductPrices.filter(pp => origins.some(reg => reg.id == pp.region.id))
    }

    if(destinations && destinations.length > 0) {
        // filteredData.clientLocations = data.clientLocation.filter(cl => destinations.some(reg => reg.id == cl.region.id))
        // filteredData.clients = data.clients.filter(c => data.clientLocations.some(cl => cl.client.id == c.id && destinations?.some(reg => reg.id == cl.region.id)))
        filteredData.clients = data.clients.map(c => ({
            ...c,
            clientLocations: c.clientLocations.filter(cl => destinations.some(reg => reg.id == cl.region.id))
        })).filter(c => c.clientLocations.length > 0)
    }


    return (
        <>
            <div style={{
                position: 'absolute',
                top: '12em',
                left: '1em',
                zIndex: '2',
                padding: '1em',
                width: '60em'
            }}>
                <Card>
                    <FormProvider {...methods}>
                        <Grid container spacing={4}>
                            <Grid item md={4}>
                                <Typography variant='h6'>Products</Typography>
                                <Field
                                    field={Select}
                                    name='products'
                                    fullWidth={true}
                                    type='select'
                                    multiple
                                    options={
                                        data.products.map(p => ({id: p.id, label: p.name}))
                                    }
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant='h6'>Origins</Typography>

                                <Field
                                    field={Select}
                                    name='origins'
                                    fullWidth={true}
                                    type='select'
                                    multiple
                                    options={
                                        data.regions.map(p => ({id: p.id, label: p.name}))
                                    }
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Typography variant='h6'>Destinations</Typography>

                                <Field
                                    field={Select}
                                    name='destinations'
                                    fullWidth={true}
                                    type='select'
                                    multiple
                                    options={
                                        data.regions.map(p => ({id: p.id, label: p.name}))
                                    }
                                />
                            </Grid>
                        </Grid>
                    </FormProvider>
                    
                </Card>
            </div>

            {children(filteredData)}
        </>
    )

} 

const reducer = (state, action) => {
    switch(action.type) {
        case 'set_data': {

            // const regions = data.regions.map(r => {
            //     r.productPrices = [...data.latestProductPrices.filter(pp => pp.region.id == r.id)]
            //     r.terminals = [...data.terminals.filter(t => t.region.id == r.id)]
            //     r.clients = [...data.clients.filter(c => c.clientLocations.some(cl => {return cl.region.id == r.id}))]
            //     return r
            // })
            return {
                ...state, 
                data: action.payload,
                // regions,
            }
        }
        case 'set_selected_origin': {

            const solutionFinder = new RackSolutionFinder()
            const solutions = solutionFinder.findSolutions({
                                   destination: action.payload.region,
                                   routes: state.data.routes,
                                   productId: action.payload.product.id,
                                   productPrices: state.data.latestProductPrices
                                })
            return {
                ...state,
                selectedDestination: action.payload.region,
                solutions
            }
        }
    }
}


const Menu = ({state}) => {

    const methods = useForm({ })

    useEffect(() => {
        if(state.solutions)
            methods.setValue('targetPrice', state.solutions[0].finalCost.value)
    }, [state.selectedDestination])

    return (
        <div style={{
                        position: 'absolute',
                        top: '12em',
                        left: '1em',
                        zIndex: '2',
                        padding: '1em',
                        width: '60em'
                    }}>
                        <Card>
                            <FormProvider {...methods}>
                                <Grid container spacing={4}>
                                    <Grid item md={6}>
                                        <Typography variant='h6'>Products</Typography>
                                        <Field
                                            field={TextField}
                                            name='targetPrice'
                                            fullWidth={true}
                                            type='text'
                                            InputProps={{
                                                onChange: e => {
                                                    state.solutions.forEach(sol => {
                                                        debugger
                                                        methods.setValue(sol.path.originRegion.name, Number(e.target.value) - sol.path.totalCost.value)
                                                    })
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <div style={{display: 'flex'}}>
                                            {state.solutions?.map(sol => {
                                                return (
                                                    <Field
                                                        field={TextField}
                                                        name={sol.path.originRegion.name}
                                                        label={sol.path.originRegion.name}
                                                        fullWidth={true}
                                                        type='text'
                                                    />
                                                )

                                            })}
                                            
                                        </div>

                                    </Grid>
                                </Grid>
                            </FormProvider>
                            
                        </Card>
                    </div>
    )

}


export const Map = () => {
    const {data, loading, error} = useQuery(FETCH_MAP_DATA)
    const [state, dispatch] = useReducer(reducer, {selectedOrigin: null})

    useEffect(() => {
        dispatch({type: 'set_data', payload: data})
    }, [data])



    if(loading)
        return <div>Loading</div>
    if(error)
        return <div>Error</div>
            debugger

    return (
        <>
            <Menu state={state}/>
            <MapComponent data={data} dispatch={dispatch}/>
        </>
    )

}
