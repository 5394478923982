import { makeStyles } from '@material-ui/core'
export default makeStyles((theme => ({
  button: {
    danger: {
      background: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      border: `1px solid  ${theme.palette.error.dark}`
    },
  }

})))

