import React, { useContext, Fragment } from 'react';
import { Typography, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx'

import { RoutesContext } from '../RouteManager.js'

const CarrierCard = (props) => {
  const { t } = useTranslation()
  const context = useContext(RoutesContext)
  
  return (
    <Fragment>
        <Typography className={"rm__text"} variant='body2'>{props.carrier.name}</Typography>
          {!props.carrier.isRouteRateUpdated && (
            <Typography className={clsx('rm__text', props.classes.errorChip)} variant='body2'>{t('routeRateMissing')}</Typography>
          )}
    </Fragment>

  )
}

export default CarrierCard
