import React, { createContext, useReducer, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { FETCH_ROUTE_MANAGER_DATA_v2 } from 'constants/queries'
import { QueryResult } from 'components'
import { StoreContext } from 'App'
import { Actions, InitialState, ManagerReducer, ScrollContexts } from './ManagerReducer'
import SelectionWidget from './SelectionWidget'
import ManagerBody from './ManagerBody'
import FormWidget from './FormWidget'
import FSCWidget from './FSCWidget'
import keyboardNavigation from './KeyboardNavigation'
import './styles.scss'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}))

export const ManagerContext = createContext();


const RouteManager = ({ refetch, carriers, products, regions }) => {
  const [state, dispatch] = useReducer(ManagerReducer, { ...InitialState, carriers, products, regions })
  const classes = useStyles()
  const { t } = useTranslation()
  const context = useContext(StoreContext);

  useEffect(() => {
    dispatch({ type: Actions.SET, payload: { name: 'selectedCarrier', value: carriers[0] } })
    return keyboardNavigation(dispatch)
  }, [])


  const onFormCompleted = () => {
    refetch();
    context.state.showAlert({
      severity: 'success',
      message: 'Index value updated'
    })


  }

  return (
    <ManagerContext.Provider value={{ ...state, dispatch }}>
      <div className={classes.root}>
        <Grid container spacing={3}>

          <Grid item md={6} lg={6}>
          </Grid>
          <Grid item md={6} lg={6}>
            <FSCWidget />
          </Grid>

          <Grid item md={3}>
            <Typography variant='h1'>
              {state.scrollContext == ScrollContexts.CARRIERS ?
                t(state.scrollContext) :
                `${state.selectedCarrier.name} ${t(state.scrollContext)}`
              }
            </Typography>
          </Grid>
          <Grid item md={9}>
          </Grid>

          <Grid item md={3}>
            <SelectionWidget />
          </Grid>
          <Grid item md={9}>
            <FormWidget onCompleted={onFormCompleted} />
            <ManagerBody />
          </Grid>

        </Grid>
      </div>
    </ManagerContext.Provider>
  )

}


const CarrierManager = () => {
  const { refetch, ...carrierQuery } = useQuery(FETCH_ROUTE_MANAGER_DATA_v2, { fetchPolicy: 'cache-and-network' })

  return (
    <QueryResult {...carrierQuery}>
      {(data) => (
        <RouteManager
          refetch={refetch}
          carriers={data.routeManagerData.carriers}
          products={data.routeManagerData.products}
          regions={data.routeManagerData.regions}
        />
      )}
    </QueryResult>
  )

}
export default CarrierManager
