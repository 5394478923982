const Validator = {

  checkRouteValidity(route) {
    let valid = true
    let validErrors = []
    try {
        if(route.carrier.currentRouteRate === null) {
          valid = false
          validErrors.push({route, attribute: 'routeRate', type: 'missing'})
        } 
        if(route.currentFreightRate === null) {
          valid = false
          validErrors.push({route, attribute: 'freightRate', type: 'missing'})
        }
        if(!route.carrier.isRouteRateUpdated) {
            valid = false
            validErrors.push({route, attribute: 'routeRate', type: 'outdated'})
        }

        if(!route.carrier.isFreightRateUpdated) {
            valid = false
            validErrors.push({route, attribute: 'freightRate', type: 'outdated'})
        }

        return {valid, validErrors}
    } catch(error) {
      return {valid: false, validErrors: ['validationCalledFailed', error]}
    }
  },

    checkProductPriceValidity(productPrice) {
        if(productPrice.isOutdated) {
            return {
                valid: false,
                validErrors: [{price: productPrice, attribute: 'productPrice', type: 'outdated'}]
            }
        }
        return {valid: true, validErrors: []}
    },

    getValidityErrors(pricePathPair) {
        if(pricePathPair.isOutdated) {
            const errors = []

            const priceValidity = this.checkProductPriceValidity(pricePathPair.productPrice)
            if(!priceValidity.valid)
                errors.push(priceValidity.validErrors)

            pricePathPair.path.routes.forEach(route => {
                const validity = this.checkRouteValidity(route)
                if(!validity.valid)
                    errors.push(validity.validErrors)
            })

            return errors.flat()
        } else {
            return null
        }
    }
}

export default Validator
