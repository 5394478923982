import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/react-hooks';
import { FETCH_INQUIRIES } from 'constants/queries';

import { ContractsTable } from './components';
import InquiryForm from './InquiryForm.js'
import { StoreContext } from 'App'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Inquiry = props => {
  const history = useHistory()
  const context = useContext(StoreContext)
  const classes = useStyles();

  const { className, ...rest } = props;
  const { t } = useTranslation()
  

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <InquiryForm
            fieldsEnabled={true}
            onCompleted={ () => {
              context.state.showAlert({
                severity: 'success', 
                message: `${t('inquiry')} ${t('created')}`
              });
              setTimeout(() => history.push('/inquiries'), 1000)
            }}
          />
        </Grid>
        <Grid item lg={4} md={6} xl={4} xs={12} />
      </Grid>
    </div>
  );
};

export default Inquiry;
