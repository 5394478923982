import gql from 'graphql-tag';

export const ProductPriceFragments = {
fields: gql`
  fragment productPriceFields on ProductPrice {
    id
    productId
    providerId
    regionId
    startDate
    expirationDate
    spotPrice
    varAdder
    rvoValue
    priceType
  }
`,
detailedFields: gql`
  fragment productPriceFields on ProductPrice {
       id
       priceType 
       varAdder
       spotPrice
       varIndexLatestValue {
       id
        value
        date
       }
       productId
       unitType
       product {
           id
           name
       }
       
       priceIndex {
           id
           name
           currentValue
           unitType
       }
       provider {
           id
           name
       }
       regionId
    }

`
}

export const BorderCrossPointFragments = {
fields: gql`
  fragment borderCrossPointFields on BorderCrossPoint {
    id
    name
    countries
  }
`
}

export const RegionFragments = {
  fields: gql`
    fragment regionFields on Region {
      id
      name
      shortForm
      country
      location
    }
  `
}

export const RouteRateFragments = {
  fields: gql`
    fragment routeRateFields on RouteRate {
        id
        carrier {
          id
          name
          carrierType
        }
        fsc
        startDate
        endDate
        fscType
    }
  `
}

export const FreightRateFragments = {
  fields: gql`
    fragment freightRateFields on FreightRate {
      id
      rate
      unitType
      startDate
      endDate
      product {
        id
        name
      }
      route {
        id
        routeCode
      }
      carrier {
        id
        name
        carrierType
      }
    }
  `
}

export const ProductUnitFragments = {
  fields: gql`
    fragment productUnitFields on ProductUnit {
      id
      name
      description
    }
  `
}

export const CarrierFragments = {
  fields: gql`
    fragment carrierFields on Carrier {
      id
      name
      carrierType
      isRouteRateUpdated
    }
  `
}


export const RouteFragments = {
  fields: gql`
    fragment routeFields on Route {
      id
      routeCode
      miles
      product {
          id
          name
      }
      carrier {
        id
        name
        carrierType
      }
      originRegion {
        id
        name
        shortForm
        location
      }
      destinationRegion {
        id
        name
        shortForm
        location
      }
    }
  `
}


export const FileLinkFragments = {
  Fields: gql`
    fragment fileLinkFields on FileLink {
      link
      name
      attachmentId
    }
  `
};

export const ProductFragments = {
  fields: gql`
    fragment productFields on Product {
      id
      name
      STCC
    }
  `
};


export const UserFragments = {
  fields: gql`
    fragment userFields on User {
      id
      firstName
      lastName
      avatar
      email
      credentials
      createdAt
    }
  `
};

export const LotFragments = {
  fields: gql`
    fragment lotFields on Lot {
      id
      provider {
        id
        firstName
        lastName
      }
      requirement {
        id
        name
        units
      }
      pricePerUnit
      deliveryDate
      expirationDate
      contactEmail
      contactPhone
      createdAt
      comments
      priceDetail
      paymentScheme
      accepted
      certifications {
        ...fileLinkFields
      }
    }
    ${FileLinkFragments.Fields}
  `
};

export const MovementFragments = {
  fields: gql`
    fragment movementFields on Movement {
      id
      contractId
      containerId
      loadingDate
      origin {
        city
      }
      predictedArrival
      netVolume
      rollover
      bol {
        ...fileLinkFields
      }
    }
    
    ${FileLinkFragments.Fields}
  `,
  extendedFields: gql`
    fragment movementFields on Movement {
      id
      contractId
      contract {
        canonicName
      }
      containerId
      loadingDate
      origin {
        city
      }
      predictedArrival
      netVolume
      rollover
      bol {
        ...fileLinkFields
      }
    }
    
    ${FileLinkFragments.Fields}
  `,

}

export const RequirementFragments = {
  fields: gql`
    fragment requirementFields on Requirement {
      id
      name
      units
      comments
      createdAt
      unitType
      priceDetail
      deadline
      deliveryDate
      status
      product {
        ...productFields
      }
    }
    ${ProductFragments.fields}
  `
};

export const InquiryFragments = {
  fields: gql`
    fragment inquiryFields on Inquiry {
      id
      approved
      inquiryType
      destinationId
      totalUnits
      priceType
      receptionType
      paymentType
      termsOfPayment
      termsOfDelivery
      termsOfPaymentDays
      comments
      expirationDate
      spotMonth
      spotUnits
      startMonth
      endMonth
      startYear
      endYear
      priceIndexType
      priceIndexId
      incoterm
      offset
      price
      priceIndex {
        id
        name
      }
      termMonths {
        id
        month
        year
        value
      }
      product {
        id
        name
        productCategoryId
        productUnits {
          ...productUnitFields
        }
      }
    }
  ${ProductUnitFragments.fields}
  `
}

export const ProviderDetailFragments = {
  fields: gql`
    fragment providerDetailFields on ProviderDetail {
      id
      taxId
      fiscalAddress
      terminalAddress
      companyName
      articlesOfIncorporation {
        ...fileLinkFields
      }
    }
    ${FileLinkFragments.Fields}
  `
};

export const PriceIndexFragments = {
  fields: gql`
    fragment priceIndexFields on PriceIndex {
      id
      currentValue
      name
    }
  `
}

export const ContractFragments = {
  fields: gql`
    fragment contractFields on Contract {
      user {
        id
        fullName
        email
      }
      id
      approved
      userId
      productId
      inquiryId
      canonicName
      quotationName
      validUntil
      status
      contractType
      destinationId
      priceType
      paymentType
      termsOfPayment
      termsOfDelivery
      termsOfPaymentDays
      incoterm
      comments
      spotMonth
      spotUnits
      totalUnits
      startMonth
      endMonth
      startDate
      endDate
      priceIndexId
      offset
      price
      priceIndexType
      canonicPrice
      destination {
        id
        city
      }
      priceIndex {
        name
      }
      product {
        id
        productCategoryId
        name
      }
      termMonths {
        id
        month
        year
        value
      }
      movements {
        ...movementFields
      }
      productUnit {
        id
        name
      }
    }
  ${MovementFragments.fields}
  `
};

export const ProductCategoryFragments = {
  fields: gql`
    fragment productCategoryFields on ProductCategory {
      id
      name
      products: allProductsInTree {
        ...productFields
        productUnits {
          ...productUnitFields
        }
        productCategoryId
        priceIndices {
          ...priceIndexFields
        }
      }
    }
    ${ProductFragments.fields}
    ${PriceIndexFragments.fields}
    ${ProductUnitFragments.fields}
  `,
  simpleFields: gql`
    fragment productCategoryFields on ProductCategory {
      id
      name
    }
  `
};


export const DestinationFragments = {
  fields: gql`
    fragment destinationFields on Destination {
      id
      city
    }
  `
}
