import { UnitConverter } from "./Costs";
import { ValueClass } from 'business/ValueClass'
import { UNIT_TYPES } from './Costs/UnitConverter';
import moment from 'moment'


const DEFAULT_UNIT = UNIT_TYPES.GALLONS;

export class ProductPriceClass {
    constructor(params) {
        this.productId = params.productId;
        this.regionId = params.regionId;
        this.varIndexId = params.varIndexId;
        this.varIndexAdder = params.varIndexAdder;
        this.priceType = params.priceType;
        this.spotPrice = params.spotPrice;
        this.varPriceIndex = params.priceIndex;
        this.unitType = this.priceType == 'rvoi_spot' ? params.unitType : params.priceIndex.unitType;
        this.varIndexLatestValue = params.varIndexLatestValue;
        this.varAdder = params.varAdder;
        this.product = params.product
        this.provider = params.provider

        if(!this.priceType) {
            throw {type: 'ProductPriceClass', message: 'Must have PriceType'}
        }
        switch(this.priceType) {
            case 'rvoi_spot': {
                this.value = new ValueClass({value: this.spotPrice, unitType: this.unitType})
            }
            break;
            case 'rvoni_spot': {
                throw {type: 'ProductPriceClass', message: 'rvoni_spot not yet implemented'}
            }
            case 'rvoi_var': {
                this.isOutdated = !moment(new Date()).isSame(moment(this.varIndexLatestValue.date), 'day')
                this.value = new ValueClass({value: this.varIndexLatestValue.value + this.varAdder, unitType: this.unitType})
            }
            break;
            case 'rvoni_var': {
                this.isOutdated = !moment(new Date()).isSame(moment(this.varIndexLatestValue.date), 'day')
                this.value = new ValueClass({value: this.varIndexLatestValue.value + this.varAdder, unitType: this.unitType})
            break;
            }
            default: {
                throw {type: 'ProductPriceClass', message: 'Invalid price type for ProductPriceClass'}
            }
        }


        this.convertToDefaultUnitType = () => {
            this.value = UnitConverter.convertValueClass({
                value: this.value,
                to: DEFAULT_UNIT
            })
            this.unitType = DEFAULT_UNIT;
        }

        this.getFormulaText = () => {
            if(this.priceType == 'rvoi_spot')
                return 'spot'
            else if(this.priceType == 'rvoi_var')
                return `${this.varPriceIndex.name} + ${this.varAdder}`
        }
    }
}
