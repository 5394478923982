import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Chip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { getInitials } from 'helpers';
import Helpers from 'lib/helpers';
import { DELETE_REQUIREMENT, FETCH_REQUIREMENTS } from 'constants/queries';
import { StoreContext } from 'App';
import { Can } from 'lib/can';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const RequirementsTable = props => {
  const { className, requirements, ...rest } = props;
  const { t } = useTranslation();

  const classes = useStyles();
  const history = useHistory();
  const context = useContext(StoreContext);

  const handleDelSuccess = () =>
    context.state.showAlert({
      severity: 'warning',
      message: 'Requerimiento Eliminado'
    });
  const [deleteRequirement] = useMutation(DELETE_REQUIREMENT, {
    onCompleted: handleDelSuccess,
    update: (cache, { data }) => {
      const { requirements } = cache.readQuery({ query: FETCH_REQUIREMENTS });
      cache.writeQuery({
        query: FETCH_REQUIREMENTS,
        data: {
          requirements: requirements.filter(
            r => r.id != data.requirement.destroy
          )
        }
      });
    }
  });

  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { requirements } = props;

    let selectedRequirements;

    if (event.target.checked) {
      selectedRequirements = requirements.map(requirement => requirement.id);
    } else {
      selectedRequirements = [];
    }

    setSelectedRequirements(selectedRequirements);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRequirements.indexOf(id);
    let newSelectedRequirements = [];

    if (selectedIndex === -1) {
      newSelectedRequirements = newSelectedRequirements.concat(
        selectedRequirements,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedRequirements = newSelectedRequirements.concat(
        selectedRequirements.slice(1)
      );
    } else if (selectedIndex === selectedRequirements.length - 1) {
      newSelectedRequirements = newSelectedRequirements.concat(
        selectedRequirements.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedRequirements = newSelectedRequirements.concat(
        selectedRequirements.slice(0, selectedIndex),
        selectedRequirements.slice(selectedIndex + 1)
      );
    }

    setSelectedRequirements(newSelectedRequirements);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedRequirements.length === requirements.length
                      }
                      color="primary"
                      indeterminate={
                        selectedRequirements.length > 0 &&
                        selectedRequirements.length < requirements.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>{t("name")}</TableCell>
                  <TableCell>{t("units")}</TableCell>
                  <TableCell>{t("lot")}</TableCell>
                  <TableCell>{t("delivery_date")}</TableCell>
                  <TableCell>{t("user_actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requirements.slice(0, rowsPerPage).map(requirement => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={requirement.id}
                    selected={
                      selectedRequirements.indexOf(requirement.id) !== -1
                    }>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedRequirements.indexOf(requirement.id) !== -1
                        }
                        color="primary"
                        onChange={event =>
                          handleSelectOne(event, requirement.id)
                        }
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1" style={{marginRight: '1em'}}>
                          {requirement.name}
                        </Typography>
                        {requirement.status == 'inactive' && <Chip color='primary' label={ t('complete') }/> }
                      </div>
                    </TableCell>
                    <TableCell>{requirement.units}</TableCell>
                    <TableCell>{requirement.lots.length}</TableCell>
                    <TableCell>
                      {moment(requirement.deliveryDate).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() =>
                          history.push('/requirements/view/' + requirement.id)
                        }>
                        {t("action_see")}
                      </Button>
                      <Can I="delete" this="requirement">
                        {'  /  '}
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            deleteRequirement({
                              variables: { id: requirement.id }
                            })
                          }>
                          {t("action_delete")}
                        </Button>
                      </Can>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={requirements.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

RequirementsTable.propTypes = {
  className: PropTypes.string,
  requirements: PropTypes.array.isRequired
};

export default RequirementsTable;
