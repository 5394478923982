import React from 'react'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const TF = (props) => {
  const { t } = useTranslation()
  const { type = 'text',
         required = false,
         errors,
         register,
         label,
         name,
         defaultValue = null,
         subject } = props

  return (
    <TextField 
      fullWidth
      required={required}
      label={label}
      name={name}
      defaultValue={defaultValue ? defaultValue : (subject && subject[name])}
      margin='dense'
      variant='outlined'
      type={type}
      error={errors[name]}
      inputRef={register({required})}
      helperText={errors[name] && t(errors[name].type)}
      InputProps={props.InputProps}
      InputLabelProps={props.InputLabelProps}
    />
  )
}

export default TF
