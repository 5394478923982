import React, { useEffect, useContext } from 'react'
import { Grid, Card, CardContent, CardActions, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'

import { FETCH_PRODUCT_DASHBOARD_DATA } from 'constants/queries'
import { StoreContext } from 'App'
import { FormHandler } from 'lib/Business'
import { UNIT_TYPES } from 'business'
import { ProductPriceModel } from 'models'
import { Field,
 Select,
 TextField,
 ErrorBoundary,
 LoadingBar
} from 'components'

const ProductPriceFields = () => {
    const { t } = useTranslation()
    const formContext = useFormContext();
    const formHandler = formContext.formHandler;
    const currentStartDate = formContext.watch('productPrice.startDate')

    useEffect(() => {
        document.querySelector('#spotPrice-input').focus()
    }, [])

    useEffect(() => {
        const expDate = moment(currentStartDate).add(1, 'd').format('YYYY-MM-DD')
        formContext.setValue('productPrice.expirationDate', expDate)
    }, [currentStartDate])


  return (
      <>
        <Grid item sm={6} md={4}>
            <Field
                field={TextField}
                name='productPrice.spotPrice'
                label={t('spotPrice')}
                valueAsNumber={true}
                type='number'
                InputProps={{id: 'spotPrice-input'}}
            />
        </Grid>

        <Grid item sm={6} md={8}>
            <Field
                field={Select}
                name='productPrice.unitType'
                label={t('units')}
                options={ Object.keys(UNIT_TYPES).map(u => ({label: t(UNIT_TYPES[u]), value: UNIT_TYPES[u]}))}
            />
        </Grid>

        <Grid item sm={12} md={8}>
            <Field
                field={Select}
                name='productPrice.productId'
                label={t('product')}
                InputProps={{tabindex:2}}
                options={ formHandler.getOptionList(formContext.products, 'name')}
            />
        </Grid>

        <Grid item sm={12} md={8}>
            <Field
                field={Select}
                name='productPrice.providerId'
                label={t('provider')}
                options={ formHandler.getOptionList(formContext.providers, 'name')}
            />
        </Grid>

        <Grid item sm={12} md={8}>
            <Field
                field={Select}
                name='productPrice.regionId'
                label={t('region')}
                options={ formHandler.getOptionList(formContext.regions, 'name')}
            />
        </Grid>

        <Grid item sm={12} md={6}>
            <Field
                field={TextField}
                name='productPrice.startDate'
                label={t('startDate')}
                type='date'
                defaultValue={currentStartDate}
                fullWidth={true}
            />
        </Grid>

        <Grid item sm={12} md={6}>
            <Field
                field={TextField}
                name='productPrice.expirationDate'
                label={t('expirationDate')}
                defaultValue={moment(currentStartDate).add(1, 'd').format('YYYY-MM-DD')}
                type='date'
            />
        </Grid>
                        
      </>

  )
}

const ProductPriceInput = ({data}) => {
    const context = useContext(StoreContext)
    const methods = useForm({
        defaultValues: {
            ['productPrice.priceType']: 'rvoi_spot',
            ['productPrice.startDate']: moment(new Date()).format('YYYY-MM-DD'),
        }
    })

    const ProductPriceFormHandler = new FormHandler({
        model: ProductPriceModel,
        instance: null,
        context,
    })


  const [mutate, mutationStatus] = useMutation(ProductPriceFormHandler.getQuery());
  const onSubmit = ProductPriceFormHandler.getOnSubmit(mutate, {
      cacheQuery: FETCH_PRODUCT_DASHBOARD_DATA
  });

    useEffect(() => {
        methods.register('productPrice.priceType')
    }, [])

    return (
        <ErrorBoundary>
            <FormProvider {...{
                ...methods,
                formHandler: ProductPriceFormHandler,
                regions: data.regions,
                providers: data.providers,
                products: data.products,
                priceIndices: data.priceIndices,
            }}>
              <form autoComplete='off' noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <ProductPriceFields/>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button type='submit' variant='contained' color='primary'>
                            Save
                        </Button>
                    </CardActions>
                    {mutationStatus.loading && <LoadingBar/>}
                </Card>
                </form>
            </FormProvider>
        </ErrorBoundary>
    )
}

export default ProductPriceFields
