import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { StoreContext } from 'App'
import "react-big-calendar/lib/css/react-big-calendar.css";
import events from './data.js'
import { MovementForm } from 'views'



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  contractEvent: {
    background: '#fdc5d0',
    borderColor: '#fdc5d0',
    color: '#2b2b4e'
  }
}))

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

const movementToEvent = (mov) => ({
  id: mov.id,
  movementId: mov.id,
  title: `#${mov.containerId}`,
  allDay: true,
  start: new Date(mov.loadingDate + ' 06:00:00'),
  end: new Date(mov.loadingDate + ' 06:00:00'),
  eventType: 'movement'
})

const predictedArrivalToEvent = (mov) => ({
  id: mov.id + '_pred',
  movementId: mov.id,
  title: `PA- #${mov.containerId}`,
  allDay: true,
  start: new Date(mov.predictedArrival + ' 06:00:00'),
  end: new Date(mov.predictedArrival + ' 06:00:00'),
})

// const CustomEvent = (props) => {
//   const {event} = props
//   const targetId = event.eventType = 'movement' ? event.movementId : null
//   const title = event.title
//   const classes = useStyles()
//
//   
//   return (
//     <div className={clsx("rbc-event-content", event.eventType != 'movement' && classes.contractEvent)} data-target-id={targetId} title={event.title}>{event.title}</div>
//   )
// }
//
const CalendarView = props => {
  let { events, date } = props
  const [shownDate, setShownDate] = useState(date)
  const classes = useStyles();
  const context = useContext(StoreContext)
  const { t } = useTranslation()
  const movements = events.map(e => e.subEvents.map(e => e.movements).flat()).flat()
  const movementEvents = movements.map(movementToEvent)
  const predictedEvents = movements.map(predictedArrivalToEvent)
  events = [...events, ...movementEvents, ...predictedEvents]

  return (
    <div className={classes.root}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        date={shownDate}
        style={{height: '100vh'}}
        onNavigate={setShownDate}
        // components={{event: CustomEvent}}
      />
        <MovementForm
          onCompleted={(e) => {
            context.state.showAlert({
              severity: 'success',
              message: t('changesSaved')
            })
          }}
          onError={(e) => {
            context.state.showAlert({
              severity: 'error',
              message: e.message
            })
          }}
        />
    </div>
  )
}

export default CalendarView
