import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Chip,
  TableSortLabel
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { getInitials } from 'helpers';
import Helpers from 'lib/helpers';
import { DELETE_INQUIRY, FETCH_INQUIRIES } from 'constants/queries';
import { StoreContext } from 'App';
import { Can } from 'lib/can';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const InquiryStatusChip = inquiry => {
  const { t } = useTranslation()
  return <Chip label={t(inquiry.approved ? 'approved' : 'pending')} color={inquiry.approved ? 'primary' : ''}/>
}

const InquiriesTable = props => {
  const { className, inquiries, ...rest } = props;
  const { t } = useTranslation();

  const classes = useStyles();
  const history = useHistory();
  const context = useContext(StoreContext);

  const handleDelSuccess = () =>
    context.state.showAlert({
      severity: 'warning',
      message: 'Requerimiento Eliminado'
    });

  const headCells = [
    {
      id: 'product',
      numeric: false,
      label: t('product'),
      disablePadding: true
    }, {
      id: 'status',
      numeric: false,
      label: t('status'),
      disablePadding: true
    }, {
      id: 'inquiryType',
      numeric: false,
      label: t('inquiry_type'),
      disablePadding: true
    }, {
    id: 'termDate',
    numeric: false,
    label: t('term_date'),
    disablePadding: true
    }, {
      id: 'totalUnits',
      numeric: false,
      label: t('total_units'),
      disablePadding: true
    }, {
      id: 'contracts',
      numeric: false,
      label: t('contracts'),
      disablePadding: true,
      searchable: false
    }, {
      id: 'expirationDate',
      numeric: false,
      label: t('expiration_date'),
      disablePadding: true
    }, {
      id: 'user_action',
      numeric: false,
      label: t('user_actions'),
      disablePadding: true
    }
  ]

  const [deleteInquiry] = useMutation(DELETE_INQUIRY, {
    onCompleted: handleDelSuccess,
    update: (cache, { data }) => {
      const { inquiries } = cache.readQuery({ query: FETCH_INQUIRIES });
      cache.writeQuery({
        query: FETCH_INQUIRIES,
        data: {
          inquiries: inquiries.filter(
            r => r.id != data.inquiries.destroy
          )
        }
      });
    }
  });

  const [selectedInquiries, setSelectedInquiries] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(headCells[0].id)

  const handleSelectAll = event => {
    const { inquiries } = props;

    let selectedInquiries;

    if (event.target.checked) {
      selectedInquiries = inquiries.map(inquiries => inquiries.id);
    } else {
      selectedInquiries = [];
    }

    setSelectedInquiries(selectedInquiries);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedInquiries.indexOf(id);
    let newSelectedInquiries = [];

    if (selectedIndex === -1) {
      newSelectedInquiries = newSelectedInquiries.concat(
        selectedInquiries,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedInquiries = newSelectedInquiries.concat(
        selectedInquiries.slice(1)
      );
    } else if (selectedIndex === selectedInquiries.length - 1) {
      newSelectedInquiries = newSelectedInquiries.concat(
        selectedInquiries.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedInquiries = newSelectedInquiries.concat(
        selectedInquiries.slice(0, selectedIndex),
        selectedInquiries.slice(selectedIndex + 1)
      );
    }

    setSelectedInquiries(newSelectedInquiries);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy == property && order == 'asc';
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedInquiries.length === inquiries.length
                      }
                      color="primary"
                      indeterminate={
                        selectedInquiries.length > 0 &&
                        selectedInquiries.length < inquiries.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  {
                    headCells.map(headCell => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id)}>
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                  {stableSort(inquiries, getComparator(order, orderBy))
                   .slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage)
                   .map(inquiry => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={inquiry.id}
                      selected={selectedInquiries.indexOf(inquiry.id) !== -1}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedInquiries.indexOf(inquiry.id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, inquiry.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>{inquiry.product.name}</TableCell>
                      <TableCell><InquiryStatusChip inquiry={inquiry}/></TableCell>
                      <TableCell>{t(inquiry.inquiryType)}</TableCell>
                      <TableCell>
                          {inquiry.inquiryType == 'spot' ?
                            moment().month(inquiry.spotMonth).format('MMM "YY') :
                            `${moment(`${inquiry.startYear}/${inquiry.startMonth}`, 'YYYY-MM').format('MMM "YY')} - ${moment(`${inquiry.endYear}/${inquiry.endMonth}`, 'YYYY-MM').format('MMM "YY')}`
                            }
                      </TableCell>
                      <TableCell>
                          {inquiry.totalUnits}
                      </TableCell>
                      <TableCell>
                          {inquiry.contracts.length}
                      </TableCell>
                      <TableCell>
                          {moment(inquiry.expirationDate).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            history.push('/inquiries/view/' + inquiry.id)
                          }>
                          {t("action_see")}
                        </Button>
                        <Can I="delete" this="inquiry">
                          {'  /  '}
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              deleteInquiry({
                                variables: { id: inquiry.id }
                              })
                            }>
                            {t("action_delete")}
                          </Button>
                        </Can>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={inquiries.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

InquiriesTable.propTypes = {
  className: PropTypes.string,
  inquiries: PropTypes.array.isRequired
};

export default InquiriesTable;
