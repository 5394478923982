import React, { useState, useEffect } from 'react'
import clsx from 'clsx' 
import { Button, makeStyles, Grid, Typography, Tooltip, Chip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import {Validator} from 'business'
import { ErrorObject, ErrorBoundary } from 'components'
import { UnitConverter } from 'business'


const useStyles = makeStyles(theme => ({
    productText: {
        color: props => !props.valid ? theme.palette.error.main : '#777',
        fontWeight: '500'
    },
}))

const TooltipProductPrice = ({productPrice}) => {
    const { t } = useTranslation()
    const classes = useStyles();

    const validityErrors = Validator.checkProductPriceValidity(productPrice)


    return <div className={classes.tooltipCard}> 
            {validityErrors.validErrors.map(e => (
                <Typography variant='h5' style={{color: 'red'}}>
                    {t(e.attribute)} - {t(e.type)}
                </Typography>
            ))}
            
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography style={{margin: '0 1em'}}>
                    {productPrice.getFormulaText()}
                </Typography>

                <Chip label={productPrice.provider.name}/>
            </div>
        </div>
}

const OriginRegionTip = ({pricePathPair}) => {
    const { t } = useTranslation()
    const classes = useStyles({valid: !pricePathPair.productPrice.isOutdated});
    const startPrice = `${pricePathPair.productPrice.varPriceIndex.name} + ${pricePathPair.productPrice.varAdder} ${t(pricePathPair.productPrice.unitType)}`

    return (
        <div className={classes.regionTip}>
            <Typography style={{marginRight: '10px' }} className={classes.regionTipText}>{pricePathPair.path.originRegion.name}</Typography>
            <Typography className={classes.regionTipText}>{startPrice}</Typography>
        </div>
    )
}

const RegionTip = ({route, name}) => {
    const classes = useStyles({valid: !route.isOutdated});
    const {t} = useTranslation();
    if(route)
        return <div className={clsx(classes.regionTip)}>
            <Typography className={classes.regionTipText}>{route.destinationRegion.name}</Typography>
            <div style={{display: 'flex', alignItems: 'center', margin: '0 10px'}}>
                <Typography className={classes.regionTipText} style={{marginRight: '5px'}}>+{route.totalCost.value.toFixed(2)} {t(route.totalCost.unit)}</Typography>
                <Chip label={route.carrier.name}/>
            </div>
        </div>
    return 
}

const IndirectProductPrice = ({clientId, pricePathPair, revealTooltip, hideTooltip}) => {
    const classes = useStyles({valid: !pricePathPair.isOutdated});
    const { t } = useTranslation();

    const onMouseOver = () => {
        revealTooltip({clientId, child: <TooltipIndirectProductPrice pricePathPair={pricePathPair}/>})
    }

    const onMouseLeave = () => {
        hideTooltip()
    }

    return (
        <div classname={classes.productPrice} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            <Typography className={classes.productText}>
                {pricePathPair.product.name} - ${pricePathPair.finalCost.toFixed(2)} - {t(pricePathPair.unitType)} <Chip style={{margin: '0 1em'}} label={t('indirect')}/>
            </Typography>
        </div>
    )

}
const TooltipIndirectProductPrice = ({pricePathPair}) => {
    const { t } = useTranslation()
    const classes = useStyles();

    const priceInIndexUnits = UnitConverter.convert({value: pricePathPair.finalCost,
        from: pricePathPair.unitType,
        to: pricePathPair.productPrice.varPriceIndex.unitType}) 
    const finalPriceInIndexUnits = (priceInIndexUnits - pricePathPair.productPrice.varCurrentValue).toFixed(2);
    const finalPrice = `${pricePathPair.productPrice.varPriceIndex.name} + ${finalPriceInIndexUnits} ${t(pricePathPair.productPrice.varPriceIndex.unitType)}`

    return (
        <div className={classes.tooltipCard}> 
            <Typography>
                {finalPrice}
            </Typography>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <OriginRegionTip pricePathPair={pricePathPair}/>
                {pricePathPair.path.routes.map(r => (
                    <RegionTip route={r}/>
                ))}
            </div>
        </div>


    )
}

export const ProductPrice = ({clientId, productPrice, revealTooltip, hideTooltip}) => {
    const classes = useStyles({valid: !productPrice.isOutdated});
    const { t } = useTranslation();

    productPrice.convertToDefaultUnitType();
    const onMouseOver = () => {
        revealTooltip({clientId, child: <TooltipProductPrice productPrice={productPrice}/>})
    }

    const onMouseLeave = () => {
        hideTooltip()
    }


    switch(productPrice.priceType) {
        case 'rvoi_var':
            // let value = productPrice.varCurrentValue ? productPrice.varCurrentValue.toFixed(2) : 'N/A'
            return (
                <div className={clsx(classes.productPrice)} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
                    <Typography className={classes.productText}>
                        {productPrice.product.name} - ${productPrice.value.value.toFixed(2)} - {t(productPrice.value.unitType)}
                    </Typography>
                </div>
            )
        case 'rvoi_spot':
            return (
                <div className={clsx(classes.productPrice)} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
                <Typography className={classes.productText}>
                    {productPrice.product.name} - ${productPrice.value.value.toFixed(2)} - {t(productPrice.value.unitType)}
                </Typography>
                </div>
            )
        default:
            throw new ErrorObject({message: 'Invalid priceType given to ProductPrice'})

    }
}
