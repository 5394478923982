import { UnitConverter } from 'business'
export class ValueClass {
    constructor({value, unitType}) {
        if(value == null || value == undefined || !unitType) {
            throw 'Value and unitType are required to instantiate a ValueClass'
        }

        this.value = value;
        this.unitType = unitType

        if(value instanceof ValueClass) {
            if(value.unitType != unitType)
                this.value = UnitConverter.convertValueClass({
                    value: value,
                    to: this.unitType
                })
        }
    }

    minus(valueClass) {
        if(!(valueClass instanceof ValueClass))
            throw 'Expects instance of ValueClass'

        const valueClassOfSameUnit = UnitConverter.convertValueClass({value: valueClass, to: this.unitType});
        return new ValueClass({
            value: this.value - valueClassOfSameUnit.value,
            unitType: this.unitType
        })
    }


    plus(valueClass) {
        if(!(valueClass instanceof ValueClass))
            throw 'Expects instance of ValueClass'

        const valueClassOfSameUnit = UnitConverter.convertValueClass({value: valueClass, to: this.unitType});
        return new ValueClass({
            value: this.value + valueClassOfSameUnit.value,
            unitType: this.unitType
        })
    }

    lessThan(valueClass) {
        if(!(valueClass instanceof ValueClass))
            throw 'Expects instance of ValueClass'

        const valueClassOfSameUnit = UnitConverter.convertValueClass({value: valueClass, to: this.unitType});
        return this.value < valueClassOfSameUnit.value
    }


    greaterThan(valueClass) {
        if(!(valueClass instanceof ValueClass))
            throw 'Expects instance of ValueClass'

        const valueClassOfSameUnit = UnitConverter.convertValueClass({value: valueClass, to: this.unitType});
        return this.value > valueClassOfSameUnit.value
    }
}
