import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const { register, errors, handleSubmit, setValue } = useForm();

  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onSubmit = data => {
    console.log(data)
  }
  
  const { t } = useTranslation();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardHeader subheader={t("update_password")} title={t("password")} />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label={t("password")}
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            inputRef={register({ required: true })}
            error={errors.password}
            helperText={errors.password && t(errors.password.type)}
          />
          <TextField
            fullWidth
            label={t("confirmation")}
            name="confirm"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={values.confirm}
            variant="outlined"
            inputRef={register({ required: true })}
            error={errors.confirm}
            helperText={errors.confirm && t(errors.confirm.type)}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" type="submit">
            {t("update")}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
