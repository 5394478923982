
import gql from 'graphql-tag'
import * as Fragments from 'constants/fragments'

export const query = gql`
 query productPricesByClient {
    clients {
    id
      name
      products {
          id
          name
      }
      regions {
          id
        name
      }
      latestProductPrices {
       priceType 
       varAdder
       spotPrice
       varIndexLatestValue {
        value
        date
       }
       productId
       unitType
       product {
           id
           name
       }
       
       priceIndex {
           id
           name
           currentValue
           unitType
       }
       provider {
           id
           name
       }
       regionId
      }
    }
    routes {
        ...routeFields
        freightRate
        isFreightRateUpdated
        unitType
       productId
        carrier {
            id
            name
            fsc
            isRouteRateUpdated
        }
        latestFreightRate {
            id
            rate
            startDate
            endDate
        }
    }
}
  ${Fragments.RouteFragments.fields}
`
