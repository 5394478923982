import React, { useContext, Fragment, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import { Typography, Grid, TextField, Button } from '@material-ui/core'
import { useForm, FormProvider, useFormContext} from 'react-hook-form'


import { FormHandler } from 'lib/Business'
import { ManagerContext } from './CarrierManager'
import { RouteModel } from 'models'
import { Field, Select, ErrorBoundary } from 'components'

const makeDefaultValue = value => (option, _) => option.id == value.id

const RouteFields = ({formHandler}) => {
  const context = useFormContext()
  const { t } = useTranslation()
    const watchAll = context.watch()

    useEffect(() => {
        document.getElementById('route\.product').focus()
    }, [])


    useEffect(() => {
        const carrierName = watchAll.route.carrier.name
        const originName = watchAll.route?.originRegion?.shortForm
        const destinationName = watchAll.route?.destinationRegion?.shortForm
        context.setValue('route.routeCode',
            `${carrierName} ${originName || ''} ${destinationName || ''}`)

    }, [watchAll.carrier,
        watchAll.route.originRegion,
        watchAll.route.destinationRegion])

  return (
  <Fragment>
    <Grid item md={2}> </Grid>
    <Grid item md={4}>
      <Field
        field={Select}
        name='route.carrier'
        label={t('carrier')}
        options={ formHandler.getOptionList(context.carriers, 'name')}
        setDefaultValue={makeDefaultValue(context.selectedCarrier)}
      />
    </Grid>
    <Grid item md={4}>
      <Field
        field={Select}
        name='route.product'
        label={t('product')}
        options={ formHandler.getOptionList(context.products, 'name')}
      />
    </Grid>
    <Grid item md={3}>
      <Field
        field={Select}
        name='route.originRegion'
        label={t('originRegion')}
        options={formHandler.getOptionList(context.regions, 'name')}
      />
    </Grid>

    <Grid item md={3}>
      <Field
        field={Select}
        name='route.destinationRegion'
        label={t('destinationRegion')}
        options={formHandler.getOptionList(context.regions, 'name')}
        fullWidth
      />
    </Grid>

    <Grid item md={3}>
      <Field
        field={TextField}
        name='route.miles'
        label={t('miles')}
        fullWidth={true}
      />
    </Grid>

    <Grid item md={3}>
      <Field
        field={TextField}
        name='route.routeCode'
        label={t('routeCode')}
        fullWidth={true}
        InputProps={{id: 'route-code__input'}}
      />
    </Grid>

    
</Fragment>
  )
}



const RouteForm = (props) => {

  const RouteFormHandler = new FormHandler({model: RouteModel, instance: props.product})
  const context = useContext(ManagerContext)
  const methods = useForm({
    defaultValues: {
        'route.carrier': context.selectedCarrier,
    }
  })

  const [mutate, mutationStatus] = useMutation(RouteFormHandler.getQuery(), {
      onCompleted: props.onCompleted
  })
  const onSubmit = RouteFormHandler.getOnSubmit(mutate)
  // const onSubmit = RouteFormHandler.getOnSubmit(mutate, {refetchQueries: [
  //   FETCH_ROUTE_MANAGER_DATA_v2
  // ]})

  useEffect(() => {
    if(context.selectedCarrier)
      methods.setValue('route.carrier', context.selectedCarrier)
    
  }, [context.selectedCarrier])




  return (
    <ErrorBoundary>
        <FormProvider { ...{...context, ...methods}}>
          <form autoComplete='off' noValidate onSubmit={methods.handleSubmit(onSubmit)}>
            <div style={{padding: '1em', boxSizing: 'border-box'}}>
              <Grid container spacing={3}>
                <RouteFields formHandler={RouteFormHandler}/>
              </Grid>
            <Button type='submit' variant='contained' color='primary'>
              Save
            </Button>
            </div>
          </form>
        </FormProvider>
    </ErrorBoundary>
  )
}
export default RouteForm
