import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { ApolloProvider } from '@apollo/react-hooks';
import Client from './ServerClient';
import { LoadingBar, Loader } from 'components';

import './i18n'

ReactDOM.render(
  <ApolloProvider client={Client}>
    <Suspense fallback={<Loader/>}>
      <App />
    </Suspense>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
