import React, { useEffect, useState, useContext, useReducer } from 'react';
import { Router, useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import {Alert, Dialog, ErrorBoundary} from 'components';
import { StoreReducer, InitialStore } from 'lib/storeReducer';
import SessionHandler from './lib/SessionHandler';
import * as Actions from 'lib/actions';
import { AbilityContext } from 'lib/can';
import DefineAbilityFor from 'constants/abilities';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export const StoreContext = React.createContext();

const App = () => {
  const [state, dispatch] = useReducer(StoreReducer, InitialStore);
  const showAlert = ({ severity, message }) => {
    dispatch({ type: Actions.SHOW_ALERT, payload: { severity, message } });
  };

  const context = useContext(StoreReducer);
  const handleAlertClose = () => dispatch({ type: Actions.HIDE_ALERT });
  const closeDialog = () => {dispatch({type: Actions.HIDE_DIALOG})}
  const openDialog = (dialogProps) => dispatch({type: Actions.OPEN_DIALOG, payload: dialogProps})
  const hideDialog = () => dispatch({type: Actions.HIDE_DIALOG })

  useEffect(() => {
    SessionHandler.fetchCurrentUser().then(user => {
      if (user) dispatch({ type: Actions.SET_SESSION, payload: user });
    });
  }, []);

  const logOut = () => {
    dispatch({ type: Actions.LOG_OUT });
  };

  return (
    <StoreContext.Provider
      value={{ state: { ...state, showAlert, logOut}, dispatch, openDialog, hideDialog }}>
      <StoreContext.Consumer>
        {context => (
          <AbilityContext.Provider
            value={DefineAbilityFor(context.state.session)}>
            <ThemeProvider theme={theme}>
              <Router history={browserHistory}>
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
              </Router>
                {console.log(context)}
              <Alert
                isOpen={context.state.alertIsOpen}
                handleClose={handleAlertClose}
                severity={context.state.severity}
                message={context.state.message}
              />
              <Dialog 
                isOpen={context.state.dialog.isOpen}
                title={context.state.dialog.title}
                contentText={context.state.dialog.contentText}
                handleClose={closeDialog}
                Actions={context.state.dialog.Actions}
              />
            </ThemeProvider>
          </AbilityContext.Provider>
        )}
      </StoreContext.Consumer>
    </StoreContext.Provider>
  );
};
export default App;
