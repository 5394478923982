const now = new Date()
const events = [

 {
    id: 0,
    clientId: 0,
    title: 'ABD - 102',
    allDay: true,
    start: new Date(2020, 9, 1),
    end: new Date(2020, 11, 22),
    detail: {
      title: 'Client #1',
      subtitle: '60 Tanktruck'
    },
    subEvents: [
      {title: '20/0', p: [{h: '20', b: 'Loaded 20'}, {h: '0', b: 'Delivered 0'}]},
      {title: '30/15', p: [{h: '30', b: 'Loaded 30'}, {h: '15', b: 'Delivered 15'}]},
      {title: '40/40', p: [{h: '40', b: 'Loaded 40'}, {h: '40', b: 'Delivered 40'}]},
    ]

  },
  {
    id: 1,
    clientId: 0,
    title: 'CAL - 221',
    start: new Date(2020, 1, 2),
    end: new Date(2020, 1, 23),
    detail: {
      title: 'Client #1',
      subtitle: '60 Tanktruck'
    },
    subEvents: [
      {title: '20/0', p: [{h: '20', b: 'Loaded 20'}, {h: '20', b: 'Delivered 20'}]},
    ]
  },

  {
    id: 2,
    clientId: 1,
    title: 'CAL - 223',
    start: new Date(2020, 2, 2),
    end: new Date(2020, 3, 23),
    detail: {
      title: 'Client #1',
      subtitle: '60 Tanktruck'
    },
    subEvents: [
      {title: '2/0', p: [{h: '2/3', b: 'Loaded 2'}, {h: '0/3', b: 'Delivered 0'}]},
      {title: '', p: [{h: '4/3', b: 'Loaded 3 (RO: 1)'}, {h: '3/3', b: 'Delivered 3 (+3)'}]},
    ]
  },
]

export default events
