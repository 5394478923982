import { UnitConverter } from './UnitConverter'

const CostCalculator = {
  format(num) {return num},
    calculateFreightCost({fsc, freightRate, miles, fromUnit, toUnit}) {
      const value = freightRate + fsc * miles;
      return UnitConverter.convert({value, from: fromUnit, to: toUnit})
    },
  totalFreightCost({fsc, freightRate, miles}) {
    return this.format(freightRate + (fsc * miles))
  },
  totalFreightCostPerGallon({fsc, freightRate, miles, avgLoadVol}) {
    return this.format(this.totalFreightCost({fsc, freightRate, miles}))/avgLoadVol
  },
  railcarLeasePerGallon({lease, avgLoadVol}) {
    return this.format(lease / avgLoadVol)
  }
}

export default CostCalculator
