import PathFinder from './PathFinder'
import RackSolution from './RackSolution'
import ProductPricePathPair from './ProductPricePathPair'
import { ProductPriceClass }  from 'business'

class RackSolutionFinder {
    findSolutions({destination, routes, productId, productPrices}) {
        const paths = PathFinder.findAllPathsTo(destination, routes);
        //find best paths from each origin to destination
        let originTable = paths.reduce(
            (table, path) => {

                if(table[path.originRegion.id]) {
                    table[path.originRegion.id].push(path)
                    return table;
                }

                table[path.originRegion.id] = [path]
                return table
            }, {})

        Object.keys(originTable).forEach(regionId => {
            originTable[regionId] = originTable[regionId]?.sort((a, b) => a.totalCost.minus(b.totalCost).value)
        })

        //get all reachable prices
        const prices = productPrices.filter(p => p.productId == productId && !!originTable[p.regionId])

        //pair each price with the cheapest path
        const pricePathPairs = prices.map(p => new ProductPricePathPair({
            productPrice: new ProductPriceClass(p),
            paths: originTable[p.regionId],
            seasonality: 'year'
        }))

        const pairs =  pricePathPairs.reduce((acc, p) => {
            if(p.seasonality == 'year') acc.push(p)
            else if(p.seasonality == 'summer') {
                //not correctly implemented?
                let bestPair = null;
                for(const pair in pricePathPairs) {
                    if(pair.seasonality == 'winter'
                       && pair.finalCost.lessThan(bestPair?.finalCost)) {
                            bestPair = pair;
                    } 
                }
                acc.push(new RackSolution([p, bestPair]))
            }
            return acc;
        }, [])
        return pairs;
    }

    findOptimalSolutions({destination, routes, productId, productPrices}) {
        const optimalSolutions =  this.findSolutions({destination, routes, productId, productPrices})
        if(optimalSolutions.length > 0) {
            return [optimalSolutions.sort((a, b) => a.finalCost.minus(b.finalCost).value)[0]]
        }
        return optimalSolutions;
    }
}

export default RackSolutionFinder;
