import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  FormControl,
  TextField,
  Typography,
  InputLabel,
  FormHelperText,
  Select
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DropzoneArea } from 'material-ui-dropzone';
import DescriptionIcon from '@material-ui/icons/Description';
import ClearIcon from '@material-ui/icons/Clear';
import ErrorIcon from '@material-ui/icons/Error';

import { StoreContext } from 'App';
import { LoadingBar } from 'components';
import { UPDATE_PROVIDER_DETAIL, DELETE_FILE } from 'constants/queries';

const useStyles = makeStyles(theme => ({
  root: {},
  fileCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Roboto, sans-serif',
    padding: '1rem',
    transition: 'all 0.3s',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0,0,0,0.5)'
    }
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  deleteFileIcon: {
    color: 'red'
  },
  fileCardContent: {
    display: 'flex',
    alignItems: 'center'
  },
  fileCardIcon: {
    marginRight: '1rem'
  }
}));

const ProviderDetail = props => {
  const classes = useStyles();
  const context = useContext(StoreContext);

  const { className, user, ...rest } = props;
  const { t, i18n } = useTranslation();
  const { register, errors, handleSubmit, setValue } = useForm();
  const [updateProviderDetails, mutationStatus] = useMutation(
    UPDATE_PROVIDER_DETAIL,
    {
      onCompleted: () =>
        context.state.showAlert({
          severity: 'success',
          message: `${t('updated_user')}`
        })
    }
  );
  const [deleteFile, deletionStatus] = useMutation(DELETE_FILE, {
    onCompleted: ({ deleteFile }) => {
      if (deleteFile.result)
        context.state.showAlert({
          severity: 'success',
          message: `${t('file_deleted')}`
        });
      else
        context.state.showAlert({
          severity: 'error',
          message: `${t('file_deletion_failure')}`
        });
    }
  });

  useEffect(() => register('articlesOfIncorporation'), [register]);

  const onSubmit = data =>
    updateProviderDetails({
      variables: {
        id: user.providerDetail.id,
        input: { ...data }
      }
    });

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          subheader={t('information_editable')}
          title={t('supplier_details')}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                required
                fullWidth
                label={t('company_name')}
                margin="dense"
                name="companyName"
                variant="outlined"
                error={errors.companyName}
                inputRef={register({ required: true })}
                helperText={errors.companyName && t(errors.companyName.type)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                required
                fullWidth
                label={t('tax_id')}
                margin="dense"
                name="taxId"
                variant="outlined"
                error={errors.taxId}
                inputRef={register({ required: true })}
                helperText={errors.taxId && t(errors.taxId.type)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                required
                fullWidth
                label={t('fiscal_address')}
                margin="dense"
                name="fiscalAddress"
                variant="outlined"
                error={errors.fiscalAddress}
                inputRef={register({ required: true })}
                helperText={
                  errors.fiscalAddress && t(errors.fiscalAddress.type)
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('terminal_address')}
                margin="dense"
                name="terminalAddress"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <DropzoneArea
                dropzoneText={t('articles_of_incorporation')}
                onChange={files => setValue('articlesOfIncorporation', files)}
              />
            </Grid>
            {user.providerDetail.articlesOfIncorporation.map(file => {
              return (
                <Grid item xs={4} key={file.link}>
                  <a href={file.link}>
                    <Card className={classes.fileCard}>
                      <CardContent className={classes.fileCardContent}>
                        <div className={classes.fileCardIcon}>
                          <DescriptionIcon />
                        </div>
                        <Typography variant="subtitle1">{file.name}</Typography>
                      </CardContent>
                      <div
                        className={classes.deleteFileIcon}
                        onClick={e => {
                          e.preventDefault();
                          deleteFile({
                            variables: { id: file.attachmentId }
                          });
                        }}>
                        <ClearIcon />
                      </div>
                    </Card>
                  </a>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={mutationStatus.loading}>
            {t('save_changes')}
          </Button>
        </CardActions>
      </form>
      {mutationStatus.loading && <LoadingBar />}
    </Card>
  );
};

export default ProviderDetail;
