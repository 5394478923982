
import React, { useState, useEffect } from 'react'
import { QueryResult, ErrorObject, ErrorBoundary } from 'components'
import { Button, makeStyles, Grid, Typography, Tooltip, Chip } from '@material-ui/core'
import clsx from 'clsx'


import { ProductPriceClient } from './ProductPriceClient'

export const ProductPrices = ({data}) => {
    const [tooltipChild, setTooltipChild] = useState()

    const revealTooltip = (child) => {
        setTooltipChild(child);
    }

    const hideTooltip = () => {
        setTooltipChild(null);
    }



    return (
        <Grid container spacing={3} style={{padding: '1em'}}>
            {data.clients.map(c => 
                <>
                    <Grid item sm={6} md={4}>
                        <ErrorBoundary>
                            <ProductPriceClient revealTooltip={revealTooltip} hideTooltip={hideTooltip} client={c} routes={data.routes}/>
                        </ErrorBoundary>
                    </Grid>
                    <Grid item sm={6} md={8} classes={{root: clsx('tooltip-widget', tooltipChild && 'tooltip-widget__visible')}}>
                        <div>
                        <ErrorBoundary>
                            {tooltipChild?.clientId == c.id && tooltipChild.child}
                        </ErrorBoundary>
                        </div>
                    </Grid>
                </>
            )}
        </Grid>
    )
}
