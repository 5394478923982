import React, { useEffect, useContext } from 'react'
import { Grid, Card, CardContent, CardActions, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import { useMutation } from '@apollo/react-hooks'
import moment from 'moment'

import { FETCH_PRODUCT_DASHBOARD_DATA } from 'constants/queries'
import { StoreContext } from 'App'
import { FormHandler } from 'lib/Business'
import { ProductPriceModel } from 'models'
import { Field,
 Select,
 TextField,
 ErrorBoundary,
 LoadingBar
} from 'components'

import RvoiSpotFields from './ProductPriceInput'
import RvoiVarFields from './RvoiVarFields'


const ProductPriceForm = ({priceType, data}) => {
    const context = useContext(StoreContext)
    const methods = useForm({
        defaultValues: {
            ['productPrice.priceType']: priceType,
            ['productPrice.startDate']: moment(new Date()).format('YYYY-MM-DD'),
        }
    })

    const ProductPriceFormHandler = new FormHandler({
        model: ProductPriceModel,
        instance: null,
        context,
    })

    const [mutate, mutationStatus] = useMutation(ProductPriceFormHandler.getQuery());
    const onSubmit = ProductPriceFormHandler.getOnSubmit(mutate, {
        cacheQuery: FETCH_PRODUCT_DASHBOARD_DATA
    });

    useEffect(() => {
        methods.register('productPrice.priceType')
    }, [])

    let ProductPriceFields = <div>Invalid Fields</div>
    switch(priceType) {
        case 'rvoi_spot':
            ProductPriceFields = RvoiSpotFields
            break;
        case 'rvoi_var':
            ProductPriceFields = RvoiVarFields
            break;
    }

    return (
        <ErrorBoundary>
                    <FormProvider {...{
                        ...methods,
                        formHandler: ProductPriceFormHandler,
                        regions: data.regions,
                        providers: data.providers,
                        products: data.products,
                    }}>
                      <form autoComplete='off' noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <ProductPriceFields/>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button type='submit' variant='contained' color='primary'>
                                    Save
                                </Button>
                            </CardActions>
                            {mutationStatus.loading && <LoadingBar/>}
                        </Card>
                        </form>
                    </FormProvider>
                </ErrorBoundary>
    )


}

export default ProductPriceForm
