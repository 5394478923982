import { updateCache } from 'business'

class FormHandler {
  constructor({model, instance}) {
    this.model = model;
    this.instance = instance;
    this.isUpdate = this.instance ? true : false;
  }

  getQuery() {
    return this.model.queries[this.isUpdate ? 'update' : 'create']
  }

  getOptionList(list, labelKey) {
    return list.map(i => ({label: i[labelKey], value: i}))
  }

  getDefaultValues(instance) {
    this.model.getDefaultValues(instance)
  }

  getUpdateCacheFn(cacheQuery) {
      if(!cacheQuery) return undefined;
      const queryKey = this.model.keys.create;
      const fetchKey = this.model.keys.fetch
      return updateCache({
          query: cacheQuery,
          resultReducer: res => res.data[queryKey].create,
          dataReducer: (oldData, result) => ({...oldData, [fetchKey]: [...oldData[fetchKey], result]})
      })
  }

  getOnSubmit(mutation, mutationOptions = {}) {
    return data => {
        const fields = this.model.extractFields(data);

        if(this.isUpdate) {
            mutation({
                variables: { input: fields, id: this.instance.id }
            })
        } else {
            let options = {...mutationOptions}
            if(mutationOptions.cacheQuery) {
                options.update = this.getUpdateCacheFn(mutationOptions.cacheQuery);
            }

            debugger
            mutation({
                variables: { input: fields },
                mutationOptions: options,
            })
        }
    }
  }
}

export default FormHandler
