import React, { useState, useReducer, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'components';
import { FETCH_PRODUCT_CATEGORIES, UPDATE_USER } from 'constants/queries';
import { LoadingBar } from 'components';
import { StoreContext } from 'App';

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
});

const reducer = (checkedCategories, action) => {
  switch (action.type) {
    case 'checkChange':
      if (action.payload.check)
        checkedCategories.add(action.payload.categoryId);
      else checkedCategories.delete(action.payload.categoryId);
      return new Set(checkedCategories);
  }
};

const CategoryPanel = ({ category, checked, onCheck }) => {
  const [filter, setFilter] = useState();

  const { t, i18n } = useTranslation();

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls={`${category.name}-content`}
        id={`${category.name}-content`}>
        <FormControlLabel
          aria-label={`${category}-for-user`}
          onClick={event => {
            event.stopPropagation();
          }}
          onFocus={event => event.stopPropagation()}
          control={
            <Checkbox
              checked={checked}
              onChange={e =>
                onCheck({
                  type: 'checkChange',
                  payload: { categoryId: category.id, check: e.target.checked }
                })
              }
            />
          }
          label={t(category.name)}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <SearchInput
              placeholder="Buscar Producto"
              value={filter}
              onChange={e => setFilter(e.target.value)}
            />
          </Grid>
          <Grid item xs={8} />
          {category.products
            .filter(p => p.name.match(new RegExp(filter, 'i')))
            .map(product => (
              <Grid item lg={4} xs={12} key={product.id}>
                <Typography color="textSecondary">{product.name}</Typography>
              </Grid>
            ))}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default function UserProducts({ user }) {
  const classes = useStyles();
  const context = useContext(StoreContext);
  const { data, loading, error } = useQuery(FETCH_PRODUCT_CATEGORIES);
  const [updateCategories, responseState] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      context.state.showAlert({
        severity: 'success',
        message: 'Categorias Actualizadas'
      });
    }
  });
  const [checkedCategories, dispatch] = useReducer(
    reducer,
    user.productCategories.reduce((set, cat) => set.add(cat.id), new Set())
  );
  console.log(Array.from(checkedCategories));

  if (error) {
    return (
      <div>
        Error: <span>{error.messange}</span>
      </div>
    );
  } else if (data) {
    return (
      <Card>
        <CardHeader title="Productos de Proveedor" />
        <Divider />
        <CardContent>
          <div className={classes.root}>
            {data.productCategories.map(cat => (
              <CategoryPanel
                category={cat}
                checked={checkedCategories.has(cat.id)}
                onCheck={dispatch}
                key={cat.id}
              />
            ))}
          </div>
          {responseState.loading && <LoadingBar />}
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              updateCategories({
                variables: {
                  id: user.id,
                  input: {
                    productCategoryIds: Array.from(checkedCategories).map(e =>
                      parseInt(e)
                    )
                  }
                }
              });
            }}>
            Guardar Cambios
          </Button>
        </CardActions>
      </Card>
    );
  }
  return <div></div>;
}
