import {Validator} from 'business'

class Path {
  constructor(routeArray) {
    this._routes = routeArray
    this.steps = routeArray.map(r => ({route: r, ...this.calculateStepCost(r)}))
  }

  calculateStepCost(route) {
      const fsc = route.carrier.currentRouteRate?.fsc || 0
      const freightRate = route.currentFreightRate?.rate || 0
    return {
      fsc,
      freightRate,
      totalFreightCost: route.miles * fsc + freightRate,
      validity: this.checkValidity(route)
    }
  }

  checkValidity(route) {
    return Validator.checkRouteValidity(route)
  }
}

// class Path {
//
//   constructor(routeArray, routeData) {
//     this.routes = routeArray;
//     this.steps = routeArray.map(r => ({route: r, miles: r.miles, ...this.calculateStepCosts(r, routeData)}))
//     this.totalMiles = this.steps.reduce((acc, step) => acc += step.miles, 0).toFixed()
//     this.freightCost = this.steps.reduce((acc, step) => acc += step.cost, 0).toFixed(2)
//     this.costPerUnit = (units) => this.freightCost / units
//     // this.costPerUnit = (unitType) => originRegion.ponderedLoadVolume.toUnitType(unitType).value / this.totalCost
//   }
//
//   checkValidity(route, routeData) {
//     let valid = true
//     let validErrors = []
//     if(routeData.carrier.currentRouteRate === null) {
//       valid = false
//       validErrors.push({attribute: 'routeRate', type: 'missing'})
//     } 
//     if(routeData.currentFreightRate === null) {
//       valid = false
//       validErrors.push({attribute: 'freightRate', type: 'missing'})
//     }
//     if(routeData.carrier === null) {
//       valid = false
//       validErrors.push({attribute: 'carrier', type: 'missing'})
//     }
//     return {valid, validErrors}
//   }
//
//   calculateStepCosts(route, routesData) {
//     const routeData = routesData[route.id]
//     const validity = this.checkValidity(route, routeData)
//     const miles = route.miles
//     const fsc = routeData.carrier.currentRouteRate?.fsc || 0
//     const rate = routeData.currentFreightRate?.rate || 0
//     const carrier = routeData.carrier
//     const cost = miles * fsc + rate
//     return {
//       fsc, rate, carrier, cost, validity
//     }
//   }
// }
//
export default Path
