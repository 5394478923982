import React, {useContext, useState, useEffect} from 'react'
import {
  Card,
  Typography,
  makeStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import clsx from 'clsx'

import FreightRateForm from './FreightRateForm'
import RouteForm from './RouteForm'
import {ManagerContext} from './CarrierManager'
import { ScrollContexts } from './ManagerReducer'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '1em',
    maxHeight: '5em',
    transition: 'all 0.5s'
  },
  widgetActive: {
    maxHeight: 'unset',
    padding: '0.5em'
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    margin: '1em',
    borderRadius: '5px',
  }
}))

const FormWidget = ({onCompleted}) => {

  const { t } =  useTranslation()
  const classes = useStyles()
  const context = useContext(ManagerContext)
  const [isContentVisible, setContentVisible] = useState();

  useEffect(() => {
    setContentVisible(false)
  }, [ context.scrollContext])

  useEffect(() => {
    return document.addEventListener('keyup', e => {
      if(e.key == 'a') {
        setContentVisible(true)
      }
    })

  }, [])


  let Body = null
  const buttonMsg = context.scrollContext == ScrollContexts.ROUTES ?  'freightRate' : 'route'

  if(isContentVisible) {
    if(context.scrollContext == ScrollContexts.CARRIERS)
      Body = RouteForm
    else
      Body = FreightRateForm
  } else {
    Body =  () => (
    <div className={clsx(classes.flex)} style={{width: '100%'}}>
      <div className='stripes__block stripes--down'></div>
        <div className={clsx(classes.flex, classes.icon)}>
              <AddCircleOutlineIcon/>
              <Typography>{t('add_x', {x: t(buttonMsg)})}</Typography>
        </div>
      <div className='stripes__block stripes--down'></div>
    </div>
    )
  }

    return (
      <Card
        className={clsx(
          classes.flex,
          isContentVisible && classes.widgetActive
        )}
        onClick={() => setContentVisible(true)}>
          <div style={{width: '100%'}}>
            <Body onCompleted={() => onCompleted()}/>
          </div>
         </Card>
    )
}

export default FormWidget
