import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { StoreContext } from 'App';
import { QueryResult } from 'components'
import UNIT_TYPES from 'constants/unitTypes'
import Filters from './Filters'
// import Paths from 'PathList'

export const RoutesContext = React.createContext()

const RouteIntelligence = () => {
  const { t } = useTranslation();
  // const context = useContext(StoreContext);
  // const query = useQuery(FETCH_ROUTE_MANAGER_DATA_v2, {fetchPolicy: 'cache-and-network'})
  return (
      <div>test</div>
    // <QueryResult {...carrierQuery}>
    //     {data => (
    //       <RoutesContext.Provider
    //         value={{selectedUnit: UNIT_TYPES.GALLONS, carriers: data.carriers}}>
    //         <div className='ri__container'>
    //           <div className='filter__container'>
    //             <Filters />
    //           </div>
    //           <div className="routes__container">
    //             <Paths/>
    //           </div>
    //         </div>
    //       </RoutesContext.Provider>
    //     )}
    // </QueryResult>
  );
};


export default RouteIntelligence;
