import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  InputLabel
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { StoreContext } from 'App';
import { UPDATE_USER } from 'constants/queries';
import { Can } from 'lib/can';
import Helpers from 'lib/helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  }
}));

const UserDetails = props => {
  const classes = useStyles();
  const context = useContext(StoreContext);

  const { className, user, ...rest } = props;
  const { t, i18n } = useTranslation();
  const { register, errors, handleSubmit, setValue } = useForm();
  const [updateUser, mutationStatus] = useMutation(UPDATE_USER, {
    onCompleted: () =>
      context.state.showAlert({
        severity: 'success',
        message: `${t('updated_user')}`
      })
  });

  const credentials = [
    {
      value: 'admin',
      label: t('administrator')
    },
    {
      value: 'buyer',
      label: t('buyer')
    },
    {
      value: 'supplier',
      label: t('supplier')
    },
    {
      value: 'customs_broker',
      label: t('customs_broker')
    },
    {
      value: 'freight_company',
      label: t('freight_company')
    }
  ];

  const onSubmit = data =>
    updateUser({
      variables: {
        id: user.id,
        input: {
          ...data
        }
      }
    });

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardHeader
          subheader={t('information_editable')}
          title={t('profile')}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText={t('please_first_name')}
                label={t('firs_name')}
                margin="dense"
                name="firstName"
                variant="outlined"
                defaultValue={user.firstName}
                inputRef={register({ required: true })}
                error={errors.firstName}
                helperText={errors.firstName && t(errors.firstName.type)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t('last_name')}
                margin="dense"
                name="lastName"
                required
                defaultValue={user.lastName}
                variant="outlined"
                error={errors.lastName}
                inputRef={register({ required: true })}
                helperText={errors.lastName && t(errors.lastName.type)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                required
                fullWidth
                label={t('user_email')}
                margin="dense"
                name="email"
                variant="outlined"
                defaultValue={user.email}
                error={errors.email}
                inputRef={register({
                  required: true,
                  pattern: Helpers.emailRegex
                })}
                helperText={errors.email && t(errors.email.type)}
              />
            </Grid>
            <Can I="update" this="user" field="credentials">
              <Grid item md={6} xs={12}>
                <FormControl
                  error={errors.credentials}
                  className={classes.formControl}
                  variant="outlined">
                  <InputLabel id="user-credentials-label">
                    {t('user_credentials')}
                  </InputLabel>
                  <Select
                    native
                    defaultValue={user.credentials}
                    inputRef={register({ required: true })}
                    name="credentials"
                    label={t('user_credentials')}
                    inputProps={{
                      name: 'credentials',
                      id: 'user-credentials-select'
                    }}>
                    {credentials.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  <FormHelperText>
                    {errors.credentials && t(errors.credentials.type)}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Can>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="contained" type="submit">
            {t('save_changes')}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default UserDetails;
