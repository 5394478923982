import {CREATE_PRODUCT_PRICE, UPDATE_PRODUCT_PRICE} from 'constants/queries'
import moment from 'moment'
const ProductPriceModel = {
  keys: {
      create: 'productPrice',
      fetch: 'productPrices'
  },
  fields: [
    'productPrice.priceType',
    'productPrice.spotPrice',
    'productPrice.productId',
    'productPrice.providerId',
    'productPrice.regionId',
    'productPrice.startDate',
    'productPrice.expirationDate',
  ],
  extractFields: data => {
    switch(data.productPrice.priceType) {
        case 'rvoi_var': {
            return {
                priceType: data.productPrice.priceType,
                productId: data.productPrice.productId.id,
                regionId: data.productPrice.regionId.id,
                providerId: data.productPrice.providerId.id,
                startDate: data.productPrice.startDate,
                expirationDate: data.productPrice.expirationDate,
                varAdder: Number(data.productPrice.varAdder),
                priceIndexId : data.productPrice.priceIndexId.id,
                unitType: data.productPrice.unitType
            }
        }
        case 'rvoi_spot': {
            return {
                priceType: data.productPrice.priceType,
                spotPrice: Number(data.productPrice.spotPrice),
                productId: data.productPrice.productId.id,
                regionId: data.productPrice.regionId.id,
                providerId: data.productPrice.providerId.id,
                startDate: data.productPrice.startDate,
                expirationDate: data.productPrice.expirationDate,
                unitType: data.productPrice.unitType
            }
        }

    }
  },
  queries: {
    create: CREATE_PRODUCT_PRICE,
    update: UPDATE_PRODUCT_PRICE
  },
  getRegionOption: (r) => [{label: r.name, value: r}],
  getProviderOption: (r) => [{label: r.name, value: r}],
  getProductOption: (r) => [{label: r.name, value: r}],
  isOutdated: (productPrice) => {
      const currentDate = moment(new Date()).hours(0)
      let startDate = moment(productPrice.startDate).add(6, 'hours')
      let expirationDate = moment(productPrice.expirationDate).add(6, 'hours')
      return !(currentDate.diff(startDate, 'days') == 0 && currentDate.diff(expirationDate, 'days') <= 0)
  }
}

export default ProductPriceModel
