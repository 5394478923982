import { subject } from '@casl/ability';
import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from  'react-i18next'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core';


import { StoreContext } from 'App';
import { UPDATE_REQUIREMENT, FETCH_PRODUCTS } from 'constants/queries';
import { Can } from 'lib/can';
import DefineAbilityFor from 'constants/abilities';
import { LoadingBar } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%'
  }
}));

const RequirementDetails = props => {
  const { className, requirement, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();
  const context = useContext(StoreContext);
  const ability = DefineAbilityFor(context.state.session);
  const fieldsEnabled = ability.can(
    'update',
    subject('requirement', requirement)
  ) && requirement.status == 'active';

  const { t } = useTranslation();
  const { register, errors, handleSubmit, setValue } = useForm();
  const {data, loading, error} = useQuery(FETCH_PRODUCTS)
  const [updateRequirement, mutationStatus] = useMutation(UPDATE_REQUIREMENT, {
    onCompleted: () =>
      context.state.showAlert({
        severity: 'success',
        message: `${t('requirement_updated')}`
      })
  });

  let formattedDeliveryDate = moment(new Date(requirement.deliveryDate)).format(
    'YYYY-MM-DD'
  );

  useEffect(() => register('product'), [register])

  const [values, setValues] = useState({
    name: requirement.name,
    units: requirement.units,
    deliveryDate: formattedDeliveryDate,
    deadline: requirement.deadline.toString(),
    unitType: requirement.unitType,
    priceDetail: requirement.priceDetail,
    comments: requirement.comments,
    product: requirement.product
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onSubmit = data => 
    updateRequirement({
      variables: {
        id: requirement.id,
        input: {
          ...data,
          units: parseInt(data.units),
          deliveryDate: data.deliveryDate.toString(),
          deadline: data.deadline.toString(),
        }
      }
    });

  if(data) {
    return (
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardHeader
            subheader={ fieldsEnabled && t('this_information_is_editable') }
            title={`${t("requirement")}: ${requirement.name}`}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={6}>
                <TextField
                  fullWidth
                  label={t("name")}
                  margin="dense"
                  name="name"
                  onChange={handleChange}
                  required
                  disabled={!fieldsEnabled}
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <Autocomplete
                  id="product-id-select"
                  value={values.product}
                  onChange={(e) => register('product', setValue('product', data.products[e.target.daatset.optionIndex]))}
                  options={data.products}
                  disabled={!fieldsEnabled}
                  getOptionLabel={option => option.name}
                  renderInput={(params) => <TextField {...params} label={t("product")} variant='outlined'/>}
                >
                </Autocomplete>
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  disabled={!fieldsEnabled}
                  label={t("required_quantity")}
                  margin="dense"
                  name="units"
                  onChange={handleChange}
                  required
                  value={values.units}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t("unit")}
                  </InputLabel>
                  <Select
                    native
                    disabled={!fieldsEnabled}
                    value={values.unitType}
                    onChange={handleChange}
                    label="Unidad"
                    inputProps={{
                      name: 'unitType',
                      id: 'outlined-unit-type-native-simple'
                    }}>
                    <option aria-label="None" value="" />
                    <option value={'kg'}>Kg</option>
                    <option value={'costal'}>Costal</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t("price_detail")}
                  </InputLabel>
                  <Select
                    native
                    value={values.priceDetail}
                    disabled={!fieldsEnabled}
                    onChange={handleChange}
                    label="Detalle de Precio"
                    inputProps={{
                      name: 'priceDetail',
                      id: 'outlined-price-detail-native-simple'
                    }}>
                    <option aria-label="None" value="" />
                    <option value={'CIF'}>CIF</option>
                    <option value={'FOB'}>FOB</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  disabled={!fieldsEnabled}
                  margin="dense"
                  label={t("closing_contract")}
                  type="date"
                  name="deadline"
                  variant="outlined"
                  value={values.deadline}
                  onChange={handleChange}
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <TextField
                  id="datetime-local"
                  fullWidth
                  margin="dense"
                  disabled={!fieldsEnabled}
                  name="deliveryDate"
                  label={t("delivery_date")}
                  type="date"
                  defaultValue=""
                  variant="outlined"
                  value={values.deliveryDate}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t("comments")}
                  margin="dense"
                  name="comments"
                  onChange={handleChange}
                  disabled={!fieldsEnabled}
                  multiline
                  rows={5}
                  value={values.comments}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Can I="update" this="requirement">
              <Button
                color="primary"
                variant="contained"
                disabled={mutationStatus.loading || !fieldsEnabled}
                type="submit"
                onClick={() => {
                  updateRequirement({
                    variables: {
                      id: requirement.id,
                      input: {
                        name: values.name,
                        units: parseInt(values.units),
                        deliveryDate: values.deliveryDate.toString(),
                        deadline: values.deadline.toString(),
                        unitType: values.unitType,
                        priceDetail: values.priceDetail,
                        productId: values.product.id,
                        comments: values.comments
                      }
                    }
                  });
                }}>
                {t("save_changes")}
              </Button>
            </Can>
            <Can I="create" this="lot">
              <Button
                color="primary"
                variant="contained"
                disabled={!fieldsEnabled}
                onClick={() => {
                  history.push('/lots/create/' + requirement.id);
                }}>
                {t("create_lot")}
              </Button>
            </Can>
          </CardActions>
          {mutationStatus.loading && <LoadingBar />}
        </form>
      </Card>
    )
  }

  return <div></div>
};

export default RequirementDetails;
