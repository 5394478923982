import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import moment from 'moment'

import { Field,
 Select,
 TextField,
} from 'components'

const NVOI_VAR_FIELDS = () => {
    const { t } = useTranslation()
    const formContext = useFormContext();
    const formHandler = formContext.formHandler;
    const currentStartDate = formContext.watch('productPrice.startDate')
    const selectedProduct = formContext.watch('productPrice.productId')
    

    useEffect(() => {
        const expDate = moment(currentStartDate).add(1, 'd').format('YYYY-MM-DD')
        formContext.setValue('productPrice.expirationDate', expDate)
    }, [currentStartDate])

    useEffect(() => {
        
    }, [selectedProduct])


  return (
      <>
        <Grid item sm={12} md={4}>
            <Field
                field={Select}
                name='productPrice.providerId'
                label={t('provider')}
                options={ formHandler.getOptionList(formContext.providers, 'name')}
            />
        </Grid>

        <Grid item sm={12} md={4}>
            <Field
                field={Select}
                name='productPrice.productId'
                label={t('product')}
                InputProps={{tabindex:2}}
                options={ formHandler.getOptionList(formContext.products, 'name')}
            />
        </Grid>

        <Grid item sm={12} md={12}>
            <Field
                field={Select}
                name='productPrice.priceIndexId'
                label={t('Index')}
                options={ formHandler.getOptionList(selectedProduct?.priceIndices || [], 'name')}
            />
        </Grid>

        <Grid item sm={12} md={12}>
            <Field
                field={TextField}
                name='productPrice.varAdder'
                label={t('adder')}
                valueAsNumber={true}
                type='number'
                InputProps={{id: 'varPrice-adder'}}
            />
        </Grid>

        <Grid item sm={12} md={4}>
            <Field
                field={Select}
                name='productPrice.regionId'
                label={t('region')}
                options={ formHandler.getOptionList(formContext.regions, 'name')}
            />
        </Grid>

        <Grid item sm={12} md={4}>
            <Field
                field={TextField}
                name='productPrice.startDate'
                label={t('startDate')}
                type='date'
                defaultValue={currentStartDate}
                fullWidth={true}
            />
        </Grid>

        <Grid item sm={12} md={4}>
            <Field
                field={TextField}
                name='productPrice.expirationDate'
                label={t('expirationDate')}
                defaultValue={moment(currentStartDate).add(1, 'd').format('YYYY-MM-DD')}
                type='date'
            />
        </Grid>
                        
      </>

  )
}
export default NVOI_VAR_FIELDS
