import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';

import { ContractsTable } from './components';
import InquiryForm from './InquiryForm.js'
import { useQuery } from '@apollo/react-hooks';
import { FETCH_INQUIRIES } from 'constants/queries';
import { StoreContext } from 'App'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Inquiry = props => {
  const { inquiryId } = useParams();
  const { className, ...rest } = props;
  const { t } = useTranslation();
  const { data } = useQuery(FETCH_INQUIRIES, {
    variables: { query: { id: inquiryId } },
    fetchPolicy: 'network-only'
  });
  const context = useContext(StoreContext)
  const classes = useStyles();
  const history = useHistory()

  if (data)
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <InquiryForm 
              inquiry={data.inquiries[0]} 
              fieldsEnabled={true}
              onCompleted={ () => {
                context.state.showAlert({
                  severity: 'success', 
                  message: `${t('inquiry')} ${t('created')}`
                });
                setTimeout(() => history.push('/inquiries'), 1000)
              }}
              onError={ (e) => {
                context.state.showAlert({
                  severity: 'failure', 
                  message: e.message
                });
              }}
            />
          </Grid>
          <Grid item lg={4} md={6} xl={4} xs={12} />
          <Grid item xs={12}>
            {data.inquiries[0].contracts.length > 0 && (
              <ContractsTable inquiry={data.inquiries[0]} contracts={data.inquiries[0].contracts} />
            )}
          </Grid>
        </Grid>
      </div>
    );
  return <div></div>;
};

export default Inquiry;
