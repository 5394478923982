import React, { useContext } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { StoreContext } from 'App';

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  UserView,
  UserCreation,
  RequirementView,
  RequirementCreation,
  RequirementList as RequirementListView,
  LotView,
  LotCreation,
  LotList as LotListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  InquiryView,
  InquiryCreation,
  InquiryList,
  ContractCreation,
  ContractView,
  Calendar as CalendarView,
  YearCalendar as YearCalendarView,
  MovementList,
  RouteManager,
  ProductDashboard,
  CarrierManager,
  ProductPricesView,
  Map,
} from './views';


import { Can } from 'lib/can';

const Routes = () => {
  const context = useContext(StoreContext);

  return (
    <Switch>
      <Redirect
          exact
        from="/"
        to="/users"
      />
      <RouteWithLayout
          component={DashboardView}
          exact
          layout={MainLayout}
          path="/dashboard"
      />


      <RouteWithLayout 
          component={ProductPricesView}
          exact
          layout={MinimalLayout}
          path="/productPrices"
      />

      <RouteWithLayout 
          component={ProductDashboard}
          exact
          layout={MainLayout}
          path="/products/dashboard"
      />

      <RouteWithLayout 
          component={MovementList}
          exact
          layout={MainLayout}
          path="/contracts/view/:contractId/movements"
      />
      <RouteWithLayout
          component={CalendarView}
          exact
          layout={MainLayout}
          path="/calendar"
      />


      <RouteWithLayout
          component={RouteManager}
          exact
          layout={MainLayout}
          path="/routes/old/manage"
      />


      <RouteWithLayout
          component={CarrierManager}
          exact
          layout={MainLayout}
          path="/routes/dashboard"
      />



      {/* <RouteWithLayout */}
      {/*   component={RouteIntelligence} */}
      {/*   exact */}
      {/*   layout={MainLayout} */}
      {/*   path="/routes/Intelligence" */}
      {/* /> */}



      {/* <Route  */}
      {/*   component={YearCalendarView} */}
      {/*   exact */}
      {/*   // layout={MainLayout} */}
      {/*   path="/year-calendar" */}
      {/* /> */}

      <RouteWithLayout
          component={YearCalendarView}
          exact
          layout={MainLayout}
          path="/year-calendar"
      />


      <RouteWithLayout
          component={UserCreation}
          exact
          layout={MainLayout}
          path="/users/create"
      />
      <RouteWithLayout
          component={UserListView}
          exact
          layout={MainLayout}
          path="/users"
      />
      <RouteWithLayout
          component={UserListView}
          exact
          layout={MainLayout}
          path="/users"
      />
      <RouteWithLayout
          component={UserView}
          exact
          layout={MainLayout}
          path="/users/view/:userId"
      />
      <RouteWithLayout
          component={ContractCreation}
          exact
          layout={MainLayout}
          path="/contracts/create/:inquiryId"
      />

      <RouteWithLayout
          component={ContractView}
          exact
          layout={MainLayout}
          path="/contracts/view/:contractId"
      />

      <RouteWithLayout
          component={InquiryList}
          exact
          layout={MainLayout}
          path="/inquiries"
      />
      <RouteWithLayout
          component={InquiryView}
          exact
          layout={MainLayout}
          path="/inquiries/view/:inquiryId"
      />
      <RouteWithLayout
          component={InquiryCreation}
          exact
          layout={MainLayout}
          path="/inquiries/create"
      />
      <RouteWithLayout
          component={RequirementListView}
          exact
          layout={MainLayout}
          path="/requirements"
      />
      <RouteWithLayout
          component={RequirementView}
          exact
          layout={MainLayout}
          path="/requirements/view/:reqId"
      />
      <RouteWithLayout
          component={RequirementCreation}
          exact
          layout={MainLayout}
          path="/requirements/create"
      />
      <RouteWithLayout
          component={LotListView}
          exact
          layout={MainLayout}
          path="/lots"
      />
      <RouteWithLayout
          component={LotView}
          exact
          layout={MainLayout}
          path="/lots/view/:lotId"
      />
      <RouteWithLayout
          component={LotCreation}
          exact
          layout={MainLayout}
          path="/lots/create/:reqId"
      />
      <RouteWithLayout
          component={ProductListView}
          exact
          layout={MainLayout}
          path="/products"
      />
      <RouteWithLayout
          component={TypographyView}
          exact
          layout={MainLayout}
          path="/typography"
      />
      <RouteWithLayout
          component={IconsView}
          exact
          layout={MainLayout}
          path="/icons"
      />
      <RouteWithLayout
          component={AccountView}
          exact
          layout={MainLayout}
          path="/account"
      />
      <RouteWithLayout
          component={SettingsView}
          exact
          layout={MainLayout}
          path="/settings"
      />
      <RouteWithLayout
          component={SignUpView}
          exact
          layout={MinimalLayout}
          path="/sign-up"
      />
      <RouteWithLayout
          component={SignInView}
          exact
          layout={MinimalLayout}
          path="/sign-in"
      />
      <RouteWithLayout
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path="/not-found"
      />
      <RouteWithLayout
        component={Map}
        layout={MinimalLayout}
        path="/map"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
