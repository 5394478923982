import React, { useContext, useState } from 'react';
import gql from 'graphql-tag';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import {
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  makeStyles,
  Checkbox
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import clsx from 'clsx'

import {
CarrierForm,
RouteForm,
// FreightRateForm,
RouteRateForm,
CarrierCard,
RouteCard,
FreightRateCard,
RouteRateCard,
} from 'views';
import { Loader, Component, CustomModal, QueryResult } from 'components';
import { updateCache } from 'business'
import { StoreContext } from 'App';
import * as Fragments from 'constants/fragments';
import { FETCH_ROUTE_MANAGER_DATA, DELETE_ROUTE, DELETE_CARRIER, DELETE_ROUTE_RATE, DELETE_FREIGHT_RATE } from 'constants/queries'
import './styles.scss'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'scroll',
    '& .rm__item': {
      flex: '1 1 25%'
    },

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  errorChip: {
    backgroundColor: `${theme.palette.error.light} !important`,
    borderColor: `${theme.palette.error.dark} !important`,
    color: `white !important`,
  },

  formRoot: {
    flex: '1 0 25%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRight: '1px solid #aaa9',
    padding: '0 2rem',
    'button[type="submit"]': {
      color: 'red !important'
    },
  },
  column: {
    flex: '1 1 25%',
    transition: 'all 0.15s',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // '&:hover': {
    //   flexBasis: '33%'
    // }
  },
  columnBody: {
    height: '70vh',
    overflowY: 'scroll',
  },
  ColumnTitle: {
    fontWeight: 'bold',
    letterSpacing: '0.1em',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    marginLeft: '5px'
  },
  FormControl: {
    // height: '100%'
  },
  Input: {
    // height: '100%'
    // border: '1px solid transparent',
    // minWidth: '300px',
    // height: '3rem',
    // margin: '0',
    // color: 'green',
    // '& fieldset': {
    //   // border: '1px solid transparent'
    // }
  },
  Label: {
    // color: 'red',
    // border: '1px solid transparent'
  },
  dangerButton: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    border: `1px solid  ${theme.palette.error.dark}`, 
    margin: '0 0.5em 0 0',
  }
}));

export const RoutesContext = React.createContext()

const InitialSelectedState = {
  carrier: null,
  routee: null,
  freightRate: null,
  routeRate: null,
}


const RMCard = ({children, id, type, item, classes, handleDelete, onDeletionSuccess, deleteQuery}) => {
  const { t } = useTranslation()
  const context = useContext(RoutesContext)
  const appContext = useContext(StoreContext)
  const [destroy, destroyStatus] = useMutation(deleteQuery, {onCompleted: onDeletionSuccess })

return (
<QueryResult error={destroyStatus.error}>
    <div key={`${type} ${id}`} className={'rm__item'}>
      <div className="rm__item-body">
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Checkbox 
            checked={context.state.selected[type]?.id == id}
            onChange={() => context.state.selectFilter(item, type)}
            color='primary'
          />
            <div>
                {children}
            </div>
        </div>
      </div>
      <div className="rm__item-actions">
        <button className={clsx([ 'rm__button', classes.dangerButton ])} onClick={handleDelete(id, destroy, destroyStatus, type)}>{t('delete')}</button>
        <button className='rm__button' onClick={() => context.state.displayModal({type, data: item})}>Edit</button>
      </div>
    </div>
</QueryResult>
)

}

const RouteManager = () => {
  const { t } = useTranslation();
  const context = useContext(StoreContext);
  const classes = useStyles();
  const [selected, setSelected] = useState(InitialSelectedState)
  const { data } = useQuery(FETCH_ROUTE_MANAGER_DATA);
  const [state, setState] = useState({modalState: {shown: false}})
  const onCompleted = () => {
    context.state.showAlert({
      severity: 'success',
      message: t('changes_saved')
    });
  };

    const onDeletionSuccess = () => {
        context.state.showAlert({
          severity: 'warning',
          message: t('t_deleted', {x: t('carrier')})
        })
      }

  const selectFilter = (obj, type) => {
    if(selected[type]?.id == obj.id) setSelected({...selected, [type]: null})
    else setSelected({...selected, [type]: obj})
  }

  const onError = e => {
    context.state.showAlert({
      severity: 'error',
      message: t('changes_saved_fail')
    });
  };

  const hideModal = () => setState({...state, modalState: {shown: false}})
  const displayModal = (c) => setState({...state, modalState: {shown: true, ...c}})

  const handleUpdate = (type, reducer) => updateCache({
    query: FETCH_ROUTE_MANAGER_DATA,
    resultReducer: r => reducer(r),
    dataReducer: (oldData, result) => ({...oldData, [type]: [...oldData[type], result]})
  })


  const handleDelete = (id, mutation, mutationState, type) => () => {


    const handleDeleteUpdate = updateCache({
      query: FETCH_ROUTE_MANAGER_DATA,
      resultReducer: r => null,
      dataReducer: (oldData, _) => {; return ({...oldData, [type]: oldData[type].filter(e => e.id != id)})}
    })

    const confirmDeletion = () => {
      context.hideDialog()
      mutation({variables: {id}, update: handleDeleteUpdate})
    }
    
    context.openDialog({
      title: t('confirmDeletion'),
      contentText: t('areYouSureDelete'),
      Actions: () => {
        return (
          <React.Fragment>
            <Button className={classes.dangerButton} disabled={mutationState.loading} color='primary' variant='contained' onClick={confirmDeletion}>{t('im_sure')} </Button>
            <Button  color='secondary' variant='contained' onClick={context.hideDialog}>{t('cancel')} </Button>
          </React.Fragment>
        )
      }

    })
  }
  

  const PreparedCarrierForm = () => <CarrierForm
                  classes={classes}
                  onCompleted={onCompleted}
                  onError={onError}
                  carriers={data.carriers}
                  carrier={state.modalState.data}
                />

  const PreparedRouteForm = () => <RouteForm
                  classes={classes}
                  onCompleted={onCompleted}
                  onError={onError}
                  carriers={data.carriers}
                  regions={data.regions}
                  route={state.modalState.data}
                />

  // const PreparedFreightRateForm = () => <FreightRateForm
  //                 classes={classes}
  //                 onCompleted={onCompleted}
  //                 onError={onError}
  //                 carriers={data.carriers}
  //                 routes={data.routes}
  //                 products={data.products}
  //                 freightRate={state.modalState.data}
  //               />

  const PreparedRouteRateForm = () => <RouteRateForm
                  classes={classes}
                  onCompleted={onCompleted}
                  onError={onError}
                  carriers={data.carriers}
                  routeRate={state.modalState.data}
                />


  let ModalForm = () => <div></div>

  switch(state.modalState.type) {
    case 'carriers':
     ModalForm = () => <PreparedCarrierForm/>
     break;
    case 'routes':
     ModalForm = () => <PreparedRouteForm/>
     break;
    // case 'freightRates':
    //  ModalForm = () => <PreparedFreightRateForm/>
     // break;
    case 'routeRates':
     ModalForm = () => <PreparedRouteRateForm/>
     break;
  }




  if (data) {

      const filterRoutes = () => {
        let routes = data.routes
        routes = selected.carriers ? data.routes.filter(r => r.carrier.id == selected.carriers.id) : data.routes
        return routes
      }


      const filterFreightRates = () => {
        let freightRates = data.freightRates
        freightRates = selected.carriers ? freightRates.filter(r => r.carrier.id == selected.carriers.id) : freightRates
        freightRates = selected.routes ? freightRates.filter(r => r.route.id == selected.routes.id) : freightRates
        return freightRates
      }

      const filterRouteRates = () => {
        let routeRates = data.routeRates
        routeRates = selected.carriers ? routeRates.filter(r => r.carrier.id == selected.carriers.id) : routeRates
        return routeRates
      }

      

      const filtered = {
        carriers: selected.carriers ? [selected.carriers] : data.carriers,
        routes: filterRoutes(),
        freightRates: filterFreightRates(),
        routeRates: filterRouteRates(),
      }


    return (
      <RoutesContext.Provider
        value={{state: { displayModal, selected, selectFilter}, handleUpdate}}
      >
        <React.Fragment>
          <Card>
            <CardContent>

              <div className={classes.root}>
                <Typography className={clsx(classes.column, classes.ColumnTitle)} variant='body1'>Carriers</Typography>
                <Typography className={clsx(classes.column, classes.ColumnTitle)} variant='body1'>Routes</Typography>
                <Typography className={clsx(classes.column, classes.ColumnTitle)} variant='body1'>Freight Rates</Typography>
                <Typography className={clsx(classes.column, classes.ColumnTitle)} variant='body1'>Route Rates</Typography>
              </div>

              <div className={classes.root}>
                <div className="rm__item">
                  <div className="rm__form-button" onClick={() => displayModal({type: 'carriers'})}>+</div>
                </div>
                
                <div className="rm__item">
                  <div className="rm__form-button" onClick={() => displayModal({type: 'routes'})}>+</div>
                </div>

                <div className="rm__item">
                  <div className="rm__form-button" onClick={() => displayModal({type: 'freightRates'})}>+</div>
                </div>

                <div className="rm__item">
                  <div className="rm__form-button" onClick={() => displayModal({type: 'routeRates'})}>+</div>
                </div>
              </div>


              

              <div className={classes.root}>
                <div className={clsx(classes.column, classes.columnBody)}>
                    {filtered.carriers.map(c => (
                    <RMCard
                        id={c.id}
                        type={'carriers'}
                        item={c}
                        classes={classes}
                        handleDelete={handleDelete}
                        onDeletionSuccess={onDeletionSuccess}
                        deleteQuery={DELETE_CARRIER}
                    >
                      <CarrierCard carrier={c} classes={classes}/>
                    </RMCard>
                    ))}
                </div>

                <div className={clsx(classes.column, classes.columnBody)}>
                    {filtered.routes.map(c => 
                      <RMCard
                        id={c.id}
                        type={'routes'}
                        item={c}
                        classes={classes}
                        handleDelete={handleDelete}
                        onDeletionSuccess={onDeletionSuccess}
                        deleteQuery={DELETE_ROUTE}
                      >
                        <RouteCard route={c} classes={classes}/>
                      </RMCard>
                      )}
                </div>

                <div className={clsx(classes.column, classes.columnBody)}>
                    {filtered.freightRates.sort((a, b) => new Date(b.startDate) - new Date(a.startDate)).map(c => 
                    <RMCard
                        id={c.id}
                        type={'freightRates'}
                        item={c}
                        classes={classes}
                        handleDelete={handleDelete}
                        onDeletionSuccess={onDeletionSuccess}
                        deleteQuery={DELETE_FREIGHT_RATE}
                    >
                      <FreightRateCard freightRate={c} classes={classes}/>
                    </RMCard>
                    )}
                </div>

                <div className={clsx(classes.column, classes.columnBody)}>
                    {filtered.routeRates.sort((a, b) => new Date(b.startDate) - new Date(a.startDate)).map(c => (
                    <RMCard
                        id={c.id}
                        type={'routeRates'}
                        item={c}
                        classes={classes}
                        handleDelete={handleDelete}
                        onDeletionSuccess={onDeletionSuccess}
                        deleteQuery={DELETE_ROUTE_RATE}
                    >
                      <RouteRateCard routeRate={c} classes={classes}/>
                    </RMCard>
                    ))}
                </div>
              </div>

            </CardContent>
          </Card>
            <CustomModal
              open={state.modalState.shown}
              onClose={hideModal}
              Actions={ () =>
              <React.Fragment>
                <Button onClick={hideModal}>
                    Close
                </Button>
              </React.Fragment>
              }
            >
                <ModalForm/>
            </CustomModal>
        </React.Fragment>
      </RoutesContext.Provider>
    );
  }
  return <Loader />;
};

export default RouteManager;
