import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersToolbar, UsersTable } from './components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_USERS } from 'constants/queries';
import mockData from './data';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = () => {
  const classes = useStyles();
  const { data } = useQuery(FETCH_USERS, { fetchPolicy: 'network-only' });
  const filter = useState('');
  if (data) {
    const users = data.users.filter(user =>
      (user.firstName + ' ' + user.lastName).match(new RegExp(filter[0], 'i'))
    );

    return (
      <div className={classes.root}>
        <UsersToolbar filter={filter} />
        <div className={classes.content}>
          <UsersTable users={users} />
        </div>
      </div>
    );
  }
  return <div></div>;
};

export default UserList;
