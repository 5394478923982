import React, {useContext} from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { TextField, Card, Grid, CardActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx'
import { Select } from 'components';
import { RoutesContext } from '../RouteManager'
import { UPDATE_ROUTE, CREATE_ROUTE, FETCH_ROUTE_MANAGER_DATA } from 'constants/queries';

const customStyles = {
  input: {
    border: '1px solid transparent'
  },
  label: {
    color: 'blue'
  }
};

export const routeDirtyFields = [
  'route.carrier',
  'route.routeCode',
  'route.destinationRoute',
  'route.originRoute',
  'route.miles'
];

export const getRouteValues = data => ({
  carrierId: data.route.carrier.id,
  routeCode: data.route.routeCode,
  originRegionId: data.route.originRegion.id,
  destinationRegionId: data.route.destinationRegion.id,
  miles: Number(data.route.miles)
});

export const RouteFields = () => {
  const { t } = useTranslation();

  const methods = useFormContext();
  const {
    errors,
    register,
    route,
    regionOptions,
    carrierOptions,
    classes
  } = methods;
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Select
          name="route.carrier"
          TextFieldProps={{className: clsx('route__form-control', 'route__form--hidden')}}
          subject={route}
          required={true}
          control={methods.control}
          label={t('carrier')}
          options={carrierOptions}
          sv={methods.setValue}
          errors={errors}
          getDefaultMatcher={(option, subject) => option.id == subject.carrier.id}
          // TextFieldProps={{
          //   InputProps: {className: classes.Input}
          // }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          className={clsx('route__form-control')}
          InputProps={{ style: customStyles.Input }}
          InputLabelProps={{ style: customStyles.Label }}
          defaultValue={route?.routeCode}
          label={t('routeCode')}
          margin="dense"
          name="route.routeCode"
          className={"rm__text"} variant="outlined"
          inputRef={register({ required: true })}
          error={errors.route?.routeCode}
          helperText={errors.route?.routeCode && t(errors.route?.routeCode)}
          InputProps={{className: classes.Input}}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          className={clsx('route__form-control')}
          InputProps={{ style: customStyles.Input }}
          InputLabelProps={{ style: customStyles.Label }}
          defaultValue={route?.miles}
          label={t('miles')}
          margin="dense"
          name="route.miles"
          className={"rm__text"} variant="outlined"
          inputRef={register({ required: true })}
          error={errors.route?.miles}
          helperText={errors.route?.miles && t(errors.route?.miles)}
          InputProps={{className: classes.Input}}
        />
      </Grid>


      <Grid item xs={6}>
      </Grid>

      <Grid item xs={6}>
        <Select
          name="route.originRegion"
          TextFieldProps={{className: clsx('route__form-control', 'route__form--hidden')}}
          subject={route}
          required={true}
          control={methods.control}
          label={t('originRegion')}
          options={regionOptions}
          sv={methods.setValue}
          errors={errors}
          getDefaultMatcher={(option, subject) =>
            option.id == subject.originRegion.id
          }
        />
      </Grid>

      <Grid item xs={6}>
        <Select
          name="route.destinationRegion"
          TextFieldProps={{className: clsx('route__form-control', 'route__form--hidden')}}
          subject={route}
          required={true}
          control={methods.control}
          label={t('destinationRegion')}
          options={regionOptions}
          sv={methods.setValue}
          errors={errors}
          getDefaultMatcher={(option, subject) =>
            option.id == subject.destinationRegion.id
          }
        />
      </Grid>
    </React.Fragment>
  );
};

const RouteForm = props => {
  const { route, regions, carriers, onCompleted, onError, classes } = props;
  const methods = useForm();
  const isUpdate = !!route
  const context = useContext(RoutesContext)

  const query = isUpdate ? UPDATE_ROUTE : CREATE_ROUTE
  const [mutate, mutationStatus] = useMutation(query, {
    onCompleted,
    onError,
    update: isUpdate ? undefined : context.handleUpdate('routes', r => r.data.route.create)
  })

  const onSubmit = data => {
    let payload = { variables: {} };
    if (route) payload.variables.id = route.id;

    const routeAttributes = getRouteValues(data);
    payload.variables.input = {
      ...routeAttributes
    };

    mutate(payload);
  };

  return (
    <FormProvider
      {...methods}
      fieldsEnabled={true}
      route={route}
      classes={classes}
      regionOptions={regions?.map(r => ({ value: r, label: r.name }))}
      carrierOptions={carriers?.map(r => ({ value: r, label: r.name }))}>
      <form
        noValidate
        autoComplete="off"
        className={clsx(classes.formRoot, 'route__form')}
        onSubmit={methods.handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <RouteFields />
        <Grid xs={4}>
          <Button type="submit" className={"rm__text"} variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
      </form>
    </FormProvider>
  );
};

export default RouteForm;
