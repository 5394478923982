import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  content: {
    padding: 0
  },
  inner: {}
}));
const UserLots = props => {
  const { lots, ...rest } = props;
  const classes = useStyles();
  const history = useHistory();

  const { t, i18n } = useTranslation();

  return (
    <Card>
      <CardHeader title={t('recent_lots')} />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('requirement_name')}</TableCell>
                  <TableCell>{t('creation_date')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lots.map(lot => (
                  <TableRow
                    hover
                    key={lot.id}
                    onClick={() => history.push('/lots/view/' + lot.id)}>
                    <TableCell>{lot.requirement.name}</TableCell>
                    <TableCell>
                      {moment(lot.creationDate).format('DD-MM-YYYY')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};
export default UserLots;
